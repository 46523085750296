<template>
  <div
    v-if="fixedWidth"
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <span class="countdown-digital">
      {{ displayRemainingTime }}
    </span>
  </div>
  <span v-else class="countdown-digital">
    {{ displayRemainingTime }}
  </span>
</template>

<script>
export default {
  name: "CountdownDigital",
  props: {
    time: {
      type: Number,
      required: true,
    },
    prefix: {
      type: String,
      default: "",
    },
    daysToMinutes: {
      type: Boolean,
      default: false,
    },
    daysToHours: {
      type: Boolean,
      default: false,
    },
    // adds a full-width wrapper element and centers countdown <span> as to avoid "jiggling"
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    withTimeDiff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      now: Date.now(),
      intervalId: null,
    };
  },
  computed: {
    timeDiff() {
      return this.$store.getters["user/getTimeDifference"];
    },

    displayRemainingTime() {
      if (this.time === 0) {
        clearInterval(this.intervalId);
        return;
      }

      let t = this.time - this.now;
      if (this.withTimeDiff) {
        t -= this.timeDiff;
      }

      let days = Math.floor(t / (1000 * 60 * 60 * 24));
      let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((t % (1000 * 60)) / 1000);
      if (t < 0) {
        this.$emit("over");
        // window.clearInterval(this.intervalId);
        return this.$t("countdown.now");
      } else if (this.daysToHours) {
        // e.g. for offer badge countdown
        if (hours < 1) {
          if (days <= 1) {
            return days + " " + this.$t("time.day.one");
          }
          return days + " " + this.$t("time.day.other");
        }
        return (
          days +
          this.$t("time.day.abbr") +
          " " +
          hours +
          " " +
          this.$t("time.hour.abbr_alt")
        );
      } else if (this.daysToMinutes) {
        return (
          // e.g. for event countdown

          ("00" + days).slice(-2) +
          ":" +
          ("00" + hours).slice(-2) +
          ":" +
          ("00" + minutes).slice(-2)
        );
        // countdown in hours to seconds up to 48 hours
      } else if (t > 86400000 && t < 172800000) {
        hours += days * 24;
        return (
          // Formatted so that leading zeros aren't dropped
          this.prefix +
          ("00" + hours).slice(-2) +
          ":" +
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + seconds).slice(-2)
        );
        // countdown days to seconds for everything over 48 hours
      } else if (t > 172800000) {
        return (
          // Formatted so that leading zeros aren't dropped
          this.prefix +
          ("00" + days).slice(-2) +
          "T, " +
          ("00" + hours).slice(-2) +
          ":" +
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + seconds).slice(-2)
        );
      } else if (t < 600000 || ("00" + hours).slice(-2) === "00") {
        return (
          this.prefix +
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + seconds).slice(-2)
        );
      } else
        return (
          this.prefix +
          ("00" + hours).slice(-2) +
          ":" +
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + seconds).slice(-2)
        );
    },
  },
  created() {
    let self = this;
    this.intervalId = setInterval(() => {
      self.now = Date.now();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="scss">
.countdown-digital {
  font-family: Ubuntu-Bold;
}
</style>
