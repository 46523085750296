<template>
  <div>
    <component
      :is="eventWinnerComponent"
      :event-data="eventWinnerData"
    ></component>
  </div>
</template>

<script>
import offerModal from "@/mixins/offerModal";
import eventPopups from "@/mixins/eventPopups";

export default {
  name: "PipeEvent",
  components: {
    ModalLevelupEventWinner: () =>
      import("@/components/events/modal-levelup-event-winner"),

    ModalSpinwinEventWinner: () =>
      import("@/components/events/modal-spinwin-event-winner"),
  },
  mixins: [offerModal, eventPopups],
  data() {
    return {
      eventWinnerComponent: null,
      eventWinnerData: null,
    };
  },

  computed: {
    //calculate the interval of the event winnerm odal
    isShowEventWinnerModal() {
      //winner modal only in lobby
      if (this.$route.path !== "/") {
        return false;
      }

      if (!this.popupsUnlocked) {
        return false;
      }

      //every x times
      const x = 3;
      if (
        !this.lastShownDate ||
        this.eventWinnerCounter % x === 1 ||
        this.eventWinnerCounter === 0
      ) {
        return true;
      } else {
        //24 hours ago
        const timeDiff = 1000 * 60 * 60 * 24; // 24 hours
        const hoursAgo = Date.now() - timeDiff;
        return new Date(this.lastShownDate).getTime() < hoursAgo;
      }
    },
    finishedEvents() {
      return this.$store.getters["events/getInactiveRegularEvents"];
    },
    lastShownDate() {
      return this.$store.state.events.eventWinnerLastShown;
    },
    eventWinnerCounter() {
      return this.$store.getters["events/getShowEventWinnerCounter"];
    },
  },

  mounted() {
    console.log("--------- Pipeline Step Events ----------");

    this.$store.dispatch("events/getEvents").then((res) => {
      let allEvents = res;
      if (!allEvents) return;
      this.updateUserEvents(allEvents);
      this.updateSpecialEventRaffles(this.$store.state.events.specialEvents);

      if (this.finishedEvents[0] && this.finishedEvents[0].raffles.length > 0) {
        let eventIndex;
        if (this.finishedEvents.length === 1) {
          eventIndex = 0;
        } else {
          eventIndex = Math.floor(Math.random() * this.finishedEvents.length);
        }
        let winnerEvent = this.finishedEvents[eventIndex];
        this.$store
          .dispatch("events/displayRaffleWinner", winnerEvent.raffles[0].id)
          .then(() => {
            if (this.isShowEventWinnerModal) {
              this.eventWinnerData = winnerEvent;
              let eventString = winnerEvent.type;
              let eventName = this.getEventTypeName(eventString);
              this.eventWinnerComponent =
                "Modal" +
                eventName.charAt(0).toUpperCase() +
                eventName.slice(1) +
                "EventWinner";
            } else {
              this.$store.commit("popups/setNextPipelineQueueStep");
            }
            this.$store.commit("events/increaseEventWinnerCounter");
          });
      } else {
        this.$store.commit("events/setShowEventWinner", null);
        this.$store.commit("popups/setNextPipelineQueueStep");
      }
    });
  },
  methods: {
    updateUserEvents(events) {
      events.forEach((event) => {
        this.$store.dispatch("events/updateUserEvent", event.id).then((res) => {
          if (!res) return;
          this.$store.commit("events/addUserEvent", res);
        });
      });
    },
    updateSpecialEventRaffles(events) {
      events.forEach((event) => {
        this.$store.dispatch("events/getFootballEventRaffle", event);
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
