<template>
  <div>
    <ModalNewsletterConfirm
      v-if="isEmailVerified && !isNewsletterAbo && !hadNewsletterOnce"
    />
  </div>
</template>

<script>
import ModalNewsletterConfirm from "@/components/notification/modal-newsletter-confirm.vue";

export default {
  name: "PipeEmailConfirm",
  components: {
    ModalNewsletterConfirm,
  },

  computed: {
    isEmailVerified: function () {
      return this.$store.getters["user/currentUser"].email_verified_at;
    },
    hadNewsletterOnce: function () {
      return this.$store.getters["user/currentUser"].newsletter_at;
    },
    isNewsletterAbo: function () {
      return this.$store.getters["user/currentUser"].newsletter;
    },
  },
  created() {
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId.startsWith("modal-newsletter-confirm")) {
        this.$store.commit("popups/setNextPipelineQueueStep");
      }
    });
  },
  mounted() {
    console.log("--------- Pipeline Step NewsletterConfirm ----------");
    this.$store.dispatch("user/increaseNewsletterReminderCounter");

    if (
      !this.isEmailVerified ||
      this.isNewsletterAbo ||
      this.hadNewsletterOnce ||
      !this.$store.getters["user/showNewsletterReminderModal"]
    ) {
      this.$store.commit("popups/setNextPipelineQueueStep");
      return;
    }

    this.$bvModal.show("modal-newsletter-confirm");
  },
};
</script>

<style scoped lang="scss"></style>
