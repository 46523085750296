<template>
  <form class="landing-form" @submit.prevent="submitLogin">
    <div class="form-group mb-2">
      <input
        v-model="email"
        type="email"
        name="login-email"
        required
        :placeholder="$t('login.web.email_placeholder')"
        class="form-control"
        :autofocus="true"
        autocomplete="email"
      />
    </div>
    <div class="form-group mb-0">
      <input
        v-model="password"
        type="password"
        name="password"
        required
        placeholder="*******"
        class="form-control"
        autocomplete="current-password"
      />
    </div>
    <p class="text-end small mb-1">
      <a href="#" class="form-link" @click.prevent="handlePasswordForget">{{
        $t("login.password_forgotten")
      }}</a>
    </p>
    <p class="blue medium text-center toggle-text mb-0">
      <i18next
        :translation="$t('login.web.register.register_message')"
        @click="$emit('toggle')"
      >
        <template #link>
          <a
            href="#"
            @click.prevent="
              () => {
                $emit('toggle');
              }
            "
            >{{ $t("login.web.register.here_link") }}</a
          ></template
        >
      </i18next>
    </p>

    <div class="w-100 d-inline-flex align-items-center justify-content-center">
      <v-facebook-login
        v-if="$store.getters.facebookEnabled"
        :app-id="facebookAppId"
        :class="[
          !displayFbButton || !$store.getters.facebookEnabled
            ? 'd-none'
            : 'd-flex',
        ]"
        class="button-facebook-lg w-50 me-1"
        type="button"
        version="v12.0"
        @login="handleFbLogin"
        @logout="$emit('facebookLogout')"
        @sdk-init="displayFbButton = true"
        ><template slot="login">
          {{ $t("login.web.facebook_button.login") }}
        </template>
        ><template slot="logout">
          <div class="text-center">
            {{ $t("login.web.facebook_button.logout") }}
          </div></template
        >
        <template slot="working">
          <div class="text-center">
            <p>{{ $t("login.web.facebook_button.waiting") }}</p>
          </div></template
        >
      </v-facebook-login>
      <button
        type="submit"
        name="login-submit"
        class="ok-button-green w-50 w-sm-50"
      >
        {{ $t("login.web.login") }}
      </button>
    </div>
    <div class="w-100 d-inline-flex justify-content-center mt-3">
      <div class="form-group mb-0">
        <label
          tabindex="0"
          role="checkbox"
          class="modern-check-label"
          for="stay-logged-in-check"
          ><div class="pseudo-label text-uppercase">
            {{ $t("login.web.stay_logged_in") }}
          </div>
          <input
            id="stay-logged-in-check"
            v-model="stayLoggedInCheck"
            type="checkbox"
            tabindex="-1"
            @change="$emit('stay', stayLoggedInCheck)" /><span
            class="blue-checkmark"
          ></span
        ></label>
      </div>
    </div>
    <modal-password-forget
      :reset-email="resetEmail"
      @confirm-email="handleConfirm"
    ></modal-password-forget>
  </form>
</template>

<script>
import { VFBLogin as VFacebookLogin } from "vue-facebook-login-component";
import ModalPasswordForget from "@/components/landing/modal-password-forget.vue";

export default {
  name: "Login",
  components: { VFacebookLogin, ModalPasswordForget },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      resetEmail: "",
      password: "",
      stayLoggedInCheck: true,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      displayFbButton: false,
    };
  },
  watch: {
    error() {
      if (this.error) {
        this.password = "";
      }
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "password-forget") {
        this.resetEmail = this.email;
      }
    });
    this.$store.commit("setIsPageLoading", false);
  },

  methods: {
    submitLogin: function () {
      let email = this.email;
      let password = this.password;
      this.$emit("submitLogin", email, password);
    },
    toggleStayLoggedIn() {
      this.stayLoggedInCheck = !this.stayLoggedInCheck;
    },
    handlePasswordForget() {
      this.$bvModal.show("password-forget");
    },
    handleFbLogin(e) {
      if (e.status === "connected") {
        this.$emit("facebookConnected", e.authResponse.accessToken);
      } else {
        console.log(e);
      }
    },
    handleConfirm(data) {
      this.resetEmail = data;

      if (this.resetEmail.length > 8) {
        this.$emit("handleResetRequest", this.resetEmail);
      }
      this.$bvModal.hide("password-forget");
    },
  },
};
</script>
<style lang="scss">
.input-window {
  .modal-header {
    border: none !important;
    flex-wrap: wrap;
    justify-content: center;
    .close-button {
      right: -16px;
      top: -16px;
      width: 32px;
      height: 32px;
    }
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    width: 100%;
    background-image: linear-gradient(#205f72, #050d1c),
      linear-gradient(#050d1c, #205f72);
    border: double 1px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
    min-height: 128px;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    border-top: 0;
    justify-content: center;
  }

  .modal-buttons {
    justify-content: space-around;
    width: 80%;
  }
}
</style>
