<script>
import AnimationRewardSpin from "./animation-reward-spin";
import AnimationShining from "./animation-shining";
import AnimationEffects from "./animation-effects";
import AnimationNumberIncrease from "./animation-number-increase";
import * as CardsAPI from "@/API/cards.js";
import AnimationBaseFunctions from "./animation-base-functions";

export default {
  name: "AnimationFullRewardEarned",
  mixins: [
    AnimationRewardSpin,
    AnimationShining,
    AnimationEffects,
    AnimationNumberIncrease,
    AnimationBaseFunctions,
  ],
  data() {
    return {
      isPipeline: null,
      destroyGsapFullReward: false,
    };
  },
  computed: {
    probabilities: function () {
      return this.$store.getters["getGeneralConfig"].card;
    },
  },

  beforeDestroy() {
    this.destroyGsapFullReward = true;
  },
  methods: {
    //arr:          contains reward objects.
    //    example:  [{reward:"gold", amount:12345}]
    //    example:  [{reward:"card", cardId:63}]
    //showSimult:   if true, all rewards will be shown at the same time TODO
    AnimationFullRewardEarnedAnimate: function (
      arr,
      showSimult = false,
      bonusActive = false,
      isInPipeline = false
    ) {
      if (!this.runningAllowed()) {
        return;
      }

      this.destroyGsapFullReward = false;

      //when true, it commits the next pipeline queue Step at the end of the animation
      this.isPipeline = isInPipeline;

      let hasCards = false;

      //check if reward contains cards
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].reward === "card") {
          hasCards = true;
          break;
        }
      }

      if (hasCards) {
        let promises = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].reward === "card") {
            let promiseFetchCard = CardsAPI.fetchUserCardInfos(
              this.$store.getters["user/currentUser"].id,
              arr[i].cardId
            )
              .then((res) => {
                arr[i].cardObj = res.data.data.cards;
              })
              .catch((e) => console.log(e));
            promises.push(promiseFetchCard);
          }
        }

        Promise.all(promises)
          .then(() => {
            this.createTimeline(arr, showSimult, bonusActive);
          })
          .catch((e) => console.log(e));
      } else {
        //no cards. just animate (faster)
        this.createTimeline(arr, showSimult, bonusActive);
      }
    },

    createTimeline: function (arr, showSimult, bonusActive) {
      try {
        const queueName =
          "animation-full-reward-earned-" + Date.now() + Math.random();

        let parentTimeline = this.gsap.timeline({ paused: true });
        parentTimeline.to(document.body, 0.01, {
          onStart: () => {
            this.createBackgroundElement();
          },
        });

        let part_timeline;

        arr.forEach((rewardObj, index) => {
          if (rewardObj.reward === "card") {
            const current_card = rewardObj.cardObj;

            let current_card_amount = current_card.amount - rewardObj.amount;
            if (current_card.is_full) {
              //has max cards. just show max number
              current_card_amount = current_card.amount;
            }

            rewardObj.card = current_card.card;

            part_timeline = this.doAnimationCard(
              rewardObj,
              index,
              arr.length,
              showSimult,
              current_card_amount,
              parentTimeline
            );
          } else {
            part_timeline = this.doAnimationOthers(
              rewardObj,
              index,
              arr.length,
              showSimult,
              parentTimeline,
              bonusActive
            );
          }

          parentTimeline.add(part_timeline);
          parentTimeline.addLabel("end" + index, "-=0.2");
        });

        parentTimeline.to(document.body, 0.01, {
          onStart: () => {
            this.removeBackgroundElement();
          },
          onComplete: () => {
            this.removeFromQueue(queueName);
            this.destroyGsapFullReward = true;
            if (this.isPipeline) {
              this.$store.commit("popups/setNextPipelineQueueStep");
            }
            parentTimeline.kill();
          },
        });

        //add animation to queue
        this.addToQueue(parentTimeline, queueName);
      } catch (e) {
        console.error(
          "AnimationFullRewardEarnedAnimate konnte nicht geladen werden",
          e
        );
        this.skipAnimationFull(this.isPipeline);
      }
    },

    doAnimationCard: function (
      rewardObj,
      index,
      reward_count,
      showSimult,
      current_card_amount,
      parentTimeline
    ) {
      const timeline = this.gsap.timeline();
      let timelineCardSpin, timelineNumber;

      const animationContainer = this.createAnimationContainer();

      let skipbutton = document.createElement("button");
      skipbutton.classList.add("skip-btn");
      skipbutton.classList.add("btn");
      skipbutton.classList.add("to-center-abs");
      if (reward_count > 1) {
        animationContainer.appendChild(skipbutton);
      }

      animationContainer.html =
        '<button type="button" class="btn btn-outline-dark">Dark</button>';

      let app = document.getElementById("app");
      let element = document.createElement("gsap-full-reward-earned-card");
      animationContainer.appendChild(element);

      let calcObj;
      if (showSimult) {
        calcObj = this.calcGridPosition(reward_count, index, element);
      } else {
        calcObj = this.calcGridPosition(1, 0, element);
      }

      let x = calcObj.x;
      let y = calcObj.y;
      let scale_val = calcObj.scale_val;

      let light = document.createElement("img");
      light.classList.add("light");
      light.classList.add("to-center-abs");
      light.src = require("@/assets/img/backgrounds/lobby-glow.png");
      element.appendChild(light);

      let flare = document.createElement("img");
      flare.classList.add("flare");
      flare.classList.add("to-center-abs");
      flare.src = require("@/assets/img/animation/full-reward-earned/reward-red-reflex-web.png");
      element.appendChild(flare);

      let sparkle = document.createElement("img");
      sparkle.classList.add("sparkle-card");
      sparkle.classList.add("to-center-abs");
      sparkle.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle);

      let sparkle2 = document.createElement("img");
      sparkle2.classList.add("sparkle-card2");
      sparkle2.classList.add("to-center-abs");
      sparkle2.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle2);

      let sparkle3 = document.createElement("img");
      sparkle3.classList.add("sparkle-card3");
      sparkle3.classList.add("to-center-abs");
      sparkle3.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle3);

      let text1 = document.createElement("div");
      text1.classList.add("text1");
      text1.classList.add("to-center-abs");
      text1.innerHTML = this.getSetTitle(rewardObj.card.set);
      element.appendChild(text1);

      let text2 = document.createElement("div");
      text2.classList.add("text2");
      text2.classList.add("to-center-abs");
      text2.innerHTML = this.getCardTitle(
        rewardObj.card.set,
        rewardObj.card.number
      );
      element.appendChild(text2);

      let progress_bar_container = document.createElement("div");
      progress_bar_container.classList.add("progress-bar-container");
      progress_bar_container.classList.add("to-center-abs");
      element.appendChild(progress_bar_container);

      let current_amount_container = document.createElement("img");
      current_amount_container.classList.add("current-amount-container");
      current_amount_container.classList.add("to-center-abs");
      current_amount_container.src = require("@/assets/img/animation/full-reward-earned/cardshow-badge-counter@4x.png");
      progress_bar_container.appendChild(current_amount_container);

      let current_amounttext = document.createElement("div");
      current_amounttext.classList.add("current-amount");
      current_amounttext.classList.add("to-center-abs");
      current_amounttext.innerHTML = current_card_amount + " x";
      progress_bar_container.appendChild(current_amounttext);

      let progress_bar = document.createElement("img");
      progress_bar.classList.add("card-progress-bar");
      progress_bar.classList.add("to-center-abs");
      progress_bar.src = require("@/assets/img/animation/full-reward-earned/cardshow-progressbar@4x.png");
      progress_bar_container.appendChild(progress_bar);

      let progress_bar_fill = document.createElement("div");
      progress_bar_fill.classList.add("progress-bar-fill");
      progress_bar_container.appendChild(progress_bar_fill);

      let amount_container = document.createElement("img");
      amount_container.classList.add("amount-container");
      amount_container.classList.add("to-center-abs");
      amount_container.src = require("@/assets/img/animation/full-reward-earned/rewardshow-cards-bage@4x.png");
      element.appendChild(amount_container);

      let amounttext = document.createElement("div");
      amounttext.classList.add("amount");
      amounttext.classList.add("to-center-abs");
      amounttext.innerHTML = "+" + "0";
      element.appendChild(amounttext);

      let icon = document.createElement("img");
      icon.classList.add("bg-card");
      icon.classList.add("to-center-abs");
      icon.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-shield-2-x@3x.png");
      element.appendChild(icon);

      let sound_small = require("@/assets/sound/animation/full-reward-earned/small.wav");
      let sound_achievement = require("@/assets/sound/animation/full-reward-earned/achievement.wav");
      let sound_counter = require("@/assets/sound/animation/full-reward-earned/counter.mp3");
      let sound_magic = require("@/assets/sound/animation/full-reward-earned/magic.mp3");
      let sound_morph = require("@/assets/sound/animation/full-reward-earned/morph.mp3");
      let sound_super_win = require("@/assets/sound/animation/full-reward-earned/kleinekrone-reward-epiccard-additionalfanfare.mp3");

      this.gsap.set(element, {
        x: x,
        y: y,
        opacity: 1,
      });

      let flipLength = this.getTimeFromRarity(
        rewardObj.card.probability.probability,
        this.probabilities
      );

      let maxHoldCards = this.getMaxCardsFromRarity(
        rewardObj.card.probability.probability
      );

      skipbutton.onclick = () => {
        this.skipAnimationFull(this.isPipeline);
        parentTimeline.pause();

        if (timelineCardSpin) {
          timelineCardSpin.pause();
        }
        if (timelineNumber) {
          timelineNumber.pause();
        }
      };
      animationContainer.onclick = () => {
        if (this.skipCurrentAnimation(parentTimeline, index)) {
          animationContainer.onclick = null;
        }
      };

      //show container and dark background
      timeline.to(
        animationContainer,
        0.01,
        {
          opacity: 1,
          pointerEvents: "auto",
          onStart: () => {
            //reward fly animation
            this.$store.commit("animations/setDoRewardFlyHeader", "card");
          },
        },
        0
      );

      //show second reward fly
      timeline.to(
        animationContainer,
        0.01,
        {
          onStart: () => {
            //reward fly animation
            this.$store.commit("animations/setDoRewardFlyHeader", "card");
          },
        },
        1
      );

      //play begin sound
      timeline.to(
        app,
        0.01,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_small, false, 0.3);
            }
          },
        },
        0
      );

      //play magic sound
      timeline.to(
        app,
        0.01,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_magic, false, 0.5);
              this.fadeOutSound(sound_magic, 5000);
            }
          },
        },
        0
      );

      //play morph sound
      timeline.to(
        app,
        0.01,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_morph, false, 0.6);
            }
          },
        },
        flipLength - 1.5
      );

      if (rewardObj.card.probability.probability === 1) {
        timeline.to(
          app,
          0.01,
          {
            onStart: () => {
              if (!showSimult) {
                this.playSoundEffect(sound_super_win, false, 0.7);
              }
            },
          },
          flipLength - 2.5
        );
      }

      //show background light
      const timeline_light = this.gsap.timeline();
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline_light.kill();
      });
      timeline_light.to(light, 30, {
        rotation: 360,
        ease: "linear.inOut",
      });

      //show spinning reward icon
      timeline.to(
        element,
        0.01,
        {
          onStart: () => {
            this.AnimationRewardSpinAnimate(
              0,
              0,
              rewardObj.reward,
              element,
              rewardObj.card,
              this.probabilities
            );
          },
        },
        0
      );

      //pop in container
      timeline.fromTo(
        element,
        1,
        {
          scale: 0,
        },
        {
          ease: "elastic",
          scale: scale_val,
          onComplete: () => {
            this.AnimationShiningAnimate(element, null, "right", 0);
          },
        },
        0
      );

      let duration = rewardObj.amount / 3;
      if (duration > 1.5) {
        duration = 1.5;
      }

      //show amount
      timeline.fromTo(
        [amount_container, amounttext],
        1,
        {
          scale: 0,
        },
        {
          ease: "elastic",
          scale: 1,
          onStart: () => {
            timelineNumber = this.AnimationNumberIncreaseAnimate(
              amounttext,
              0,
              rewardObj.amount,
              duration,
              "+",
              sound_counter
            );
          },
        },
        flipLength * 0.2
      );

      //play sound magic
      timeline.to(
        element,
        0.5,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_achievement, false, 0.3);
            }
          },
        },
        flipLength - 0.5
      );

      //show progress bar
      timeline.fromTo(
        progress_bar_container,
        1,
        {
          scale: 0,
        },
        {
          ease: "elastic",
          scale: 1,
        },
        flipLength + 0.1
      );

      //raise current amount
      timeline.to(
        current_amounttext,
        0.5,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_morph);
            }
            this.AnimationEffectsAnimateMorph(250, 60, element);
          },

          onComplete: () => {
            if (!rewardObj.cardObj.is_full) {
              current_amounttext.innerHTML =
                current_card_amount + rewardObj.amount + " x";
            }
          },
        },
        flipLength * 0.95 + 1
      );

      //raise progress bar
      timeline.fromTo(
        progress_bar_fill,
        0.5,
        {
          width: (100 / maxHoldCards) * current_card_amount + "%",
        },
        {
          width:
            (100 / maxHoldCards) * (current_card_amount + rewardObj.amount) +
            "%",
        },
        flipLength * 0.95 + 1
      );

      //pop in text
      timeline.fromTo(
        [text1, text2],
        1,
        {
          scale: 0,
        },
        {
          ease: "elastic",
          scale: 1,
        },
        flipLength - 0.2
      );

      // pop out container.
      timeline.to(
        element,
        0.2,
        {
          ease: "linear.inOut",
          scale: 0,
        },
        flipLength + 2
      );

      timeline.to(
        app,
        0.2,
        {
          onComplete: () => {
            if (animationContainer.parentNode) {
              animationContainer.parentNode.removeChild(animationContainer);
            }
          },
        },
        flipLength + 2
      );

      //sparkle timeline
      const timeline_spark1 = this.gsap.timeline({
        onComplete: function () {
          this.restart();
        },
      });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline_spark1.kill();
      });
      timeline_spark1
        .fromTo(
          sparkle,
          0.2,
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          {
            opacity: 1,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle,
          0.6,
          {
            x: 0,
            y: 0,
            ease: "power0.inOut",
          },
          {
            x: -40,
            y: -200,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle,
          0.2,
          {
            opacity: 1,
            ease: "power0.inOut",
          },
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          0.3
        );

      //sparkle timeline
      const timeline_spark2 = this.gsap.timeline({
        onComplete: function () {
          this.restart();
        },
        delay: 0.3,
      });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline_spark2.kill();
      });
      timeline_spark2
        .fromTo(
          sparkle2,
          0.2,
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          {
            opacity: 1,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle2,
          0.6,
          {
            y: 0,
            ease: "power0.inOut",
          },
          {
            y: -240,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle2,
          0.2,
          {
            opacity: 1,
            ease: "power0.inOut",
          },
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          0.3
        );

      //sparkle timeline
      const timeline_spark3 = this.gsap.timeline({
        onComplete: function () {
          this.restart();
        },
        delay: 0.6,
      });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline_spark3.kill();
      });
      timeline_spark3
        .fromTo(
          sparkle3,
          0.2,
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          {
            opacity: 1,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle3,
          1.2,
          {
            x: 0,
            y: 0,
            ease: "power0.inOut",
          },
          {
            x: +100,
            y: -300,
            ease: "power0.inOut",
          },
          0
        )
        .fromTo(
          sparkle3,
          0.2,
          {
            ease: "power0.inOut",
          },
          {
            opacity: 0,
            ease: "power0.inOut",
          },
          1
        );

      // if (showSimult) {
      //   return 0.5;
      // }

      return timeline;
    },

    doAnimationOthers: function (
      rewardObj,
      index,
      reward_count,
      showSimult,
      parentTimeline,
      bonusActive
    ) {
      const timeline = this.gsap.timeline();

      const loyaltyObj = this.$store.getters["user/getUserLoyalty"];

      let amount = rewardObj.amount;
      let bonus_percent = 0;
      let showLoyalty = false;

      if (rewardObj.reward === "gold" && loyaltyObj.level > 0 && bonusActive) {
        showLoyalty = true;
        bonus_percent = loyaltyObj.loyality.bonus_shop_gold;

        //pre val
        amount = rewardObj.amount;

        //post val
        rewardObj.amount = parseInt(
          rewardObj.amount.toString().split(".").join("")
        );
        rewardObj.amount = this.formatNumber(
          Math.round(
            rewardObj.amount + (rewardObj.amount * bonus_percent) / 100
          )
        );
      }

      if (rewardObj.reward === "crown" && loyaltyObj.level > 0 && bonusActive) {
        showLoyalty = true;
        bonus_percent = loyaltyObj.loyality.bonus_shop_crowns;

        //pre val
        amount = rewardObj.amount;

        //post val
        rewardObj.amount = parseInt(
          rewardObj.amount.toString().split(".").join("")
        );
        rewardObj.amount = this.formatNumber(
          Math.round(
            rewardObj.amount + (rewardObj.amount * bonus_percent) / 100
          )
        );
      }

      if (rewardObj.reward === "expbooster") {
        amount =
          "+" +
          this.formatNumber(Math.round(amount / 60)) +
          " " +
          this.$t("time.minute.other");
      }

      const animationContainer = this.createAnimationContainer();

      let skipbutton = document.createElement("button");
      skipbutton.classList.add("skip-btn");
      skipbutton.classList.add("btn");
      skipbutton.classList.add("to-center-abs");
      if (reward_count > 1) {
        animationContainer.appendChild(skipbutton);
      }

      let app = document.getElementById("app");
      let element = document.createElement("gsap-full-reward-earned");
      animationContainer.appendChild(element);

      let calcObj;
      if (showSimult) {
        calcObj = this.calcGridPosition(reward_count, index, element);
      } else {
        calcObj = this.calcGridPosition(1, 0, element);
      }
      let x = calcObj.x;
      let y = calcObj.y;
      let scale_val = calcObj.scale_val;

      let light = document.createElement("img");
      light.classList.add("light");
      light.classList.add("to-center-abs");
      light.src = require("@/assets/img/backgrounds/lobby-glow.png");
      element.appendChild(light);

      let flare = document.createElement("img");
      flare.classList.add("flare");
      flare.classList.add("to-center-abs");
      flare.src = require("@/assets/img/animation/full-reward-earned/reward-red-reflex-web.png");
      element.appendChild(flare);

      let sparkle = document.createElement("img");
      sparkle.classList.add("sparkle");
      sparkle.classList.add("to-center-abs");
      sparkle.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle);

      let sparkle2 = document.createElement("img");
      sparkle2.classList.add("sparkle2");
      sparkle2.classList.add("to-center-abs");
      sparkle2.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle2);

      let sparkle3 = document.createElement("img");
      sparkle3.classList.add("sparkle3");
      sparkle3.classList.add("to-center-abs");
      sparkle3.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
      element.appendChild(sparkle3);

      let icon = document.createElement("img");
      icon.classList.add("bg");
      icon.classList.add("to-center-abs");
      icon.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-shield-2-x@3x.png");
      element.appendChild(icon);

      let icon2Container = document.createElement("div");
      icon2Container.classList.add("banner-container");
      icon2Container.classList.add("to-center-abs");
      element.appendChild(icon2Container);

      let icon2 = document.createElement("img");
      icon2.classList.add("banner");
      icon2.classList.add("animation-shine-img");
      icon2.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-banner-5-4-x@3x.png");
      icon2Container.appendChild(icon2);

      let text = document.createElement("div");
      text.classList.add("text1");
      text.classList.add("to-center-abs");

      let bonus_text = document.createElement("div");
      bonus_text.classList.add("bonus-text");
      bonus_text.classList.add("to-center-abs");
      bonus_text.innerHTML = "+" + bonus_percent + "%";

      let bonus_text_border = document.createElement("div");
      bonus_text_border.classList.add("bonus-text-border");
      bonus_text_border.classList.add("to-center-abs");
      bonus_text_border.innerHTML = bonus_text.innerHTML;

      let amounttext = document.createElement("div");
      amounttext.classList.add("amount");
      amounttext.classList.add("to-center-abs");
      amounttext.classList.add("yellow-text");
      amounttext.innerHTML = amount;
      element.appendChild(amounttext);

      let sound_small = require("@/assets/sound/animation/full-reward-earned/small.wav");
      let sound_gold = require("@/assets/sound/animation/full-reward-earned/gold.wav");
      let sound_achievement = require("@/assets/sound/animation/full-reward-earned/achievement.wav");
      let sound_counter = require("@/assets/sound/animation/full-reward-earned/counter.mp3");

      let context_icon = document.createElement("img");
      context_icon.classList.add("context-icon", "to-center-abs");
      context_icon.src = require("@/assets/img/animation/full-reward-earned/kk-lobby-navi-icon-loyality-inactive@2x.png");

      if (showLoyalty) {
        text.innerHTML = this.$t(`loyalty.title.level_${loyaltyObj.level}`);
        element.appendChild(text);
        element.appendChild(bonus_text);
        element.appendChild(bonus_text_border);
        element.appendChild(context_icon);
      }

      this.gsap.set(element, {
        x: x,
        y: y,
        opacity: 1,
      });

      skipbutton.onclick = () => {
        this.skipAnimationFull(this.isPipeline);
        parentTimeline.pause();
      };
      animationContainer.onclick = () => {
        if (this.skipCurrentAnimation(parentTimeline, index)) {
          animationContainer.onclick = null;
        }
      };

      //show container and dark background
      timeline.to(
        animationContainer,
        0.01,
        {
          opacity: 1,
          pointerEvents: "auto",

          onStart: () => {
            //reward fly animation
            this.$store.commit(
              "animations/setDoRewardFlyHeader",
              rewardObj.reward
            );
          },
        },
        0
      );

      //show second reward fly
      timeline.to(
        animationContainer,
        0.01,
        {
          onStart: () => {
            //reward fly animation
            this.$store.commit(
              "animations/setDoRewardFlyHeader",
              rewardObj.reward
            );
          },
        },
        1
      );

      //play begin sound
      timeline.to(
        app,
        0.01,
        {
          onStart: () => {
            if (!showSimult) {
              this.playSoundEffect(sound_small, false, 0.3);
            }
          },
        },
        0
      );

      //show background light
      const timeline3 = this.gsap.timeline();
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline3.kill();
      });
      timeline3.fromTo(
        light,
        30,
        {
          rotation: 0,
          ease: "linear.inOut",
        },
        {
          rotation: 360,
          ease: "linear.inOut",
        },
        0
      );

      //show spinning reward icon
      timeline.to(
        element,
        0,
        {
          onComplete: () => {
            this.AnimationRewardSpinAnimate(0, 0, rewardObj.reward, element);
          },
        },
        0
      );

      //pop in container
      timeline.fromTo(
        element,
        0.8,
        {
          scale: 0,
        },
        {
          ease: "elastic",
          scale: scale_val,
          onStart: () => {
            if (showLoyalty) {
              this.AnimationNumberIncreaseAnimate(
                amounttext,
                amount,
                rewardObj.amount,
                1,
                "+",
                sound_counter
              );
            }
          },
          onComplete: () => {
            this.AnimationShiningAnimate(element, null, "right", 0);
          },
        },
        0
      );

      if (showLoyalty) {
        //play sound coin
        timeline.to(
          element,
          0.5,
          {
            onStart: () => {
              if (!showSimult) {
                this.playSoundEffect(sound_gold, false, 0.5);
              }
            },
          },
          1.7
        );

        //pop in league icon
        timeline.fromTo(
          [context_icon],
          0.5,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: 1,
          },
          1.9
        );

        timeline.fromTo(
          text,
          0.5,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: 1,
            onStart: () => {
              if (!showSimult) {
                this.playSoundEffect(sound_achievement, false, 0.3);
              }
            },
          },
          2.1
        );

        //pop in BONUS text
        timeline.fromTo(
          [bonus_text, bonus_text_border],
          0.5,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: 1,
          },
          2.4
        );
      }

      let early_end = 0;
      if (showSimult) {
        early_end = 0;
      }

      // pop out container.
      timeline.to(
        element,
        0.2,
        {
          ease: "linear.inOut",
          scale: 0,
          onComplete: () => {
            if (animationContainer.parentNode) {
              animationContainer.parentNode.removeChild(animationContainer);
            }
          },
        },
        "+=" + (2 - early_end)
      );

      //sparkle timeline
      const timeline_spark = this.gsap.timeline();
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline_spark.kill();
      });
      timeline_spark
        .fromTo(
          sparkle,
          3,
          {
            y: 0,
            x: 0,
          },
          {
            y: -50,
            x: 10,
            ease: "linear",
          },
          0
        )
        .fromTo(
          sparkle2,
          3,
          {
            y: 0,
            x: 0,
          },
          {
            y: -60,
            x: -40,
            ease: "linear",
          },
          0
        )
        .fromTo(
          sparkle3,
          3,
          {
            y: 0,
            x: 0,
          },
          {
            y: -10,
            x: 30,
            ease: "linear",
          },
          0
        );

      timeline.add(timeline_spark, 0);

      // if (showSimult) {
      //   return timeline;
      // }

      return timeline;
    },

    getCardTitle: function (set, number) {
      return this.$t(`trading_cards.cards.${set}_${number}`);
    },
    getSetTitle(set) {
      return this.$t(`trading_cards.sets.${set}`) + ":";
    },
  },
};
</script>

<style lang="scss">
gsap-full-reward-earned {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  margin-left: -250px;
  margin-top: -250px;
  width: 500px;
  height: 500px;
  pointer-events: none;
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  gsap-reward-spin {
    top: 210px;
    left: 250px;
    z-index: 10;
  }

  .bg {
    position: absolute;
    top: 27%;
    left: -55px;
    width: 60%;
    z-index: 5;
  }

  .banner-container {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 20;

    .banner {
      position: inherit;
      width: 500px;
    }
  }

  .context-icon {
    position: absolute;
    top: 4%;
    left: 0;
    width: 25%;
    z-index: 20;
  }

  .text1 {
    font-family: cinzel-decorative-black;
    color: white;
    /*text-shadow: 0 0 3px #1AE5F4;*/
    text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4;
    font-size: 30px;
    text-align: center;
    top: 26%;
    z-index: 25;
  }

  .bonus-text {
    position: absolute;
    top: 32%;
    width: 350px;
    z-index: 25;
    font-size: 80px;
    text-align: center;
    font-family: cinzel-decorative-black;

    background-image: linear-gradient(
      to bottom,
      rgb(59, 175, 199) 20%,
      rgb(6, 16, 31)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ */
      & {
        background: transparent;
        color: rgb(253, 242, 141);
      }
    }
  }

  .bonus-text-border {
    position: absolute;
    top: 32.2%;
    left: 4px;
    width: 350px;
    z-index: 24;
    font-size: 85px;
    text-align: center;
    font-family: cinzel-decorative-black;
    color: white;
  }

  .light {
    position: absolute;
    top: 10%;
    width: 100%;
    z-index: 2;
    opacity: 0.1;
  }

  .flare {
    position: absolute;
    top: -120%;
    width: 300%;
    z-index: 3;
    opacity: 0;
    margin-left: -116.5%;
  }

  .sparkle {
    position: absolute;
    top: 30%;
    width: 40%;
    z-index: 10;
  }

  .sparkle2 {
    position: absolute;
    top: 35%;
    width: 40%;
    z-index: 10;
  }

  .sparkle3 {
    position: absolute;
    top: 35%;
    width: 40%;
    z-index: 10;
  }

  .amount {
    font-family: Ubuntu-Bold;
    top: 57%;
    z-index: 30;
    font-size: 60px;
    text-align: center;
  }
}

gsap-full-reward-earned-card {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: -10%;
  left: 0;
  margin-left: -250px;
  margin-top: -250px;
  width: 500px;
  height: 500px;
  opacity: 0;

  gsap-reward-spin {
    top: 210px;
    left: 250px;
    z-index: 10;
  }

  .bg-card {
    position: absolute;
    top: 27%;
    left: -55px;
    width: 85%;
    z-index: 5;
  }

  .amount-container {
    position: absolute;
    top: 82%;
    width: 36%;
    z-index: 30;
    left: 20px;
  }

  .current-amount-container {
    position: absolute;
    top: -58px;
    width: 70%;
    z-index: 20;
    left: 20px;
  }

  .banner-container {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 20;

    .banner {
      position: inherit;
      width: 500px;
    }
  }

  .text1 {
    font-family: cinzel-decorative-black;
    color: black;
    text-shadow: 1px 1px 2px white, 0 0 25px white, 0 0 5px white;
    text-align: center;
    font-size: 20px;
    top: 67%;
    z-index: 30;
    margin-left: 15px;
    transform: scale(0);
    text-transform: capitalize;
  }

  .text2 {
    font-family: cinzel-decorative-black;
    color: black;
    text-shadow: 1px 1px 2px white, 0 0 25px white, 0 0 5px white;
    text-align: center;
    font-size: 26px;
    top: 72%;
    z-index: 30;
    margin-left: 15px;
    transform: scale(0);
  }

  .light {
    position: absolute;
    top: 10%;
    width: 100%;
    z-index: 2;
    opacity: 0.1;
  }

  .flare {
    position: absolute;
    top: -120%;
    width: 300%;
    z-index: 3;
    opacity: 0;
    margin-left: -116.5%;
  }

  .sparkle-card {
    position: absolute;
    top: 40%;
    width: 80%;
    z-index: 10;
    opacity: 0;
  }

  .sparkle-card2 {
    position: absolute;
    top: 35%;
    width: 40%;
    z-index: 10;
    opacity: 0;
  }

  .sparkle-card3 {
    position: absolute;
    top: 65%;
    width: 40%;
    z-index: 10;
    opacity: 0;
  }

  .amount {
    font-family: Ubuntu-Bold;
    top: 84%;
    left: 12px;
    color: white;
    z-index: 30;
    font-size: 70px;
    text-align: center;
  }

  .current-amount {
    font-family: Ubuntu-Bold;
    top: -53px;
    left: 12px;
    color: white;
    z-index: 30;
    font-size: 46px;
    text-align: center;
  }

  .progress-bar-container {
    position: absolute;
    top: 17.5%;
    width: 42%;
    z-index: 30;
    transform: scale(0);
  }

  .card-progress-bar {
    height: 24px;
    z-index: 30;
    left: 8.5px;
    top: -5px;
  }

  .morph {
    z-index: 31;
  }

  .progress-bar-fill {
    z-index: 30;
    position: absolute;
    left: 12px;
    top: -3px;
    height: 20px;
    background: linear-gradient(
      to bottom,
      #888c91,
      #898d93,
      #93979d,
      #ecf3fb,
      #ecf3fb,
      #afb5bb,
      #a2a8ad,
      #6c6f73,
      #6c6f73,
      #7b7f82,
      #858a8d
    );
    border-radius: 15px;
    max-width: 202px;
  }
}

@media (max-height: 768px) {
  gsap-full-reward-earned {
    top: 5%;
  }
}
</style>
