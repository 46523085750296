import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchCards = () =>
  axios
    .get("/api/cards", optionsFunction())
    .then((res) => res.data.data.cards)
    .catch((e) => console.log(e));

export const fetchUserCards = () =>
  axios
    .get("/api/cards/user", optionsFunction())
    .then((res) => res.data.data.cards)
    .catch((e) => console.log(e));

export const fetchUserCardRequest = (userId) =>
  axios.get(`/api/cards/requests/user/${userId}`, optionsFunction());

export const fetchUserCardInfos = (userId, cardId) =>
  axios.get(`/api/cards/user/${userId}/${cardId}`, optionsFunction());

export const fetchSets = () =>
  axios
    .get("/api/cards/exchange", optionsFunction())
    .then((res) => res.data.data.reward)
    .catch((e) => console.log(e));

export const fetchProbabilities = () =>
  axios
    .get("/api/cards/probabilities", optionsFunction())
    .then((res) => res.data.data.probabilities)
    .catch((e) => console.log(e));

export const getNextRaffle = () =>
  axios
    .get("/api/cards/raffle", optionsFunction())
    .then((res) => res.data.data.next.date);

export const getNextWishTime = () =>
  axios
    .get("/api/cards/requests/next", optionsFunction())
    .then((res) => res.data.data.time)
    .catch((e) => console.log(e));

export const exchangeSet = (set) =>
  axios.post(`/api/cards/exchange/${set}`, {}, optionsFunction());
export const wishForCard = (card) =>
  axios.post(`/api/cards/requests/${card}`, {}, optionsFunction());

export const giftCard = (userId) =>
  axios.post(`/api/cards/requests/gift/${userId}`, {}, optionsFunction());

// export const fetchRaffleRewards = () =>
//   axios
//     .get(`/api/cards/raffle/reward`, optionsFunction())
//     .then(res => res.data.data.reward)
//     .catch(e => console.log(e));

// export const fetchAllRaffleRewards = () =>
//   axios
//     .get(`/api/cards/raffle/reward`, optionsFunction())
//     .then(res => res.data.data.reward);

// export const fetchRaffleWinners = () =>
//   axios
//     .get(`/api/cards/raffle/last/0/5`, optionsFunction())
//     .then(res => res.data.data.raffles);

export const fetchUserRaffleLogs = () =>
  axios.get(`/api/cards/raffle/user/0/5`, optionsFunction());

export const fetchUserTickets = () =>
  axios
    .get("api/cards/raffle/tickets", optionsFunction())
    .then((res) => res.data.data.tickets)
    .catch((e) => console.log(e));

export const craftCard = (cardId) =>
  axios.post(`/api/cards/craft/${cardId}`, {}, optionsFunction());
