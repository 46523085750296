import { Number } from "core-js/web";

const FormatNumber = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        formatNumber(value, shortNumber = false) {
          if (shortNumber) {
            // Format millions, e.g., 10,000,000 => 10 M or 17,500,000 => 17.5 M
            if (value >= 1000000 && value <= 999999999) {
              let millions = value / 1000000;
              let number;
              if (value % 1000000 === 0) {
                number = millions;
              } else {
                // Limit to 1 decimal place for millions
                number = millions.toFixed(1);
              }
              return number + "M";
            }
            // Format billions, e.g., 10,000,000,000 => 10 B or 1,750,000,000 => 1.75 B
            if (value >= 1000000000) {
              let billions = value / 1000000000;
              let number;
              if (value % 1000000000 === 0) {
                number = billions;
              } else {
                // Limit to 2 decimal places for billions
                number = billions.toFixed(2);
              }
              return (
                number +
                " " +
                (this.$store.state.locale === "de" ? "Mrd." : "Bn.")
              );
            }
          }

          // Adds decimal points every three digits

          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatPrice(value) {
          if (this.$store.state.locale === "de") {
            return value.toString().replace(".", ",");
          }
          return value.toString().replace(",", ".");
        },
      },
    });
  },
};
export default FormatNumber;
