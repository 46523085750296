<template>
  <div class="deco-corners">
    <div
      :class="[
        colorVariant,
        { 'extra-space': borderOffset },
        { 'move-to-top': modalOffset },
      ]"
      class="deco-corner top-left"
    ></div>
    <div
      :class="[
        colorVariant,
        { 'extra-space': borderOffset },
        { 'move-to-top': modalOffset },
      ]"
      class="deco-corner top-right"
    ></div>
    <div
      :class="[colorVariant, { 'extra-space': borderOffset }]"
      class="deco-corner bottom-right"
    ></div>
    <div
      :class="[colorVariant, { 'extra-space': borderOffset }]"
      class="deco-corner bottom-left"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DecoCorners",
  props: {
    // variants so far: "gold", "blue", "small-gold"
    colorVariant: {
      type: String,
      default: "gold",
    },
    borderOffset: {
      type: Boolean,
      default: false,
    },
    modalOffset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.deco-corner {
  position: absolute;
  z-index: 6;
  width: 26px;
  height: 26px;
  top: -2px;
  left: -2px;
  right: auto;
  &.move-to-top {
    top: -32px;
  }
  &.gold {
    background: url(~@/assets/img/common/golden-corner.png) center/100% 100%
      no-repeat;
  }
  &.small-gold {
    background: url(~@/assets/img/common/golden-corner-small.svg) center/100%
      100% no-repeat;
    width: 16px;
    height: 16px;
    -webkit-transform: translate3d(0, 0, 0);
    &.medium {
      height: 24px;
      width: 24px;
    }
  }

  &.blue {
    background: url(~@/assets/img/common/blue-corner.svg) center/100% 100%
      no-repeat;
    -webkit-transform: translate3d(0, 0, 0);
    &.small {
      width: 16px;
      height: 16px;
    }
  }

  &.extra-space {
    top: -5px;
    left: -5px;
  }

  &.top-right {
    transform: scaleX(-1);
    right: -2px;
    left: auto;
    &.extra-space {
      right: -5px;
      top: -5px;
    }
  }

  &.bottom-right {
    transform: rotate(180deg);
    right: -2px;
    left: auto;
    bottom: -2px;
    top: auto;
    &.extra-space {
      right: -5px;
      bottom: -5px;
    }
  }
  &.bottom-left {
    transform: scaleY(-1);
    bottom: -2px;
    top: auto;
    &.extra-space {
      bottom: -5px;
      left: -5px;
    }
  }
}
</style>
