import Vue from "vue";

const getDefaultState = () => {
  return {
    tournamentCurrentUpdateData: null, //changed current tournament data
    tournamentUpdateWin: {},
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setTournamentCurrentUpdateData(state, payload) {
    state.tournamentCurrentUpdateData = payload;
  },
  setTournamentUpdateWin(state, payload) {
    Vue.set(state.tournamentUpdateWin, payload.id, payload);
  },
  removeTournamentUpdateWin(state, id) {
    delete state.tournamentUpdateWin[id];
  },
};

const getters = {
  getTournamentCurrentUpdateData: (state) => state.tournamentCurrentUpdateData,
  getTournamentUpdateWin: (state) => state.tournamentUpdateWin,
};

const actions = {
  resetTournamentsState({ commit }) {
    commit("resetState");
  },
  updateTournamentWin({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setTournamentUpdateWin", payload);
      resolve();
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
