<template>
  <div>
    <ModalTosConfirm />
  </div>
</template>

<script>
import ModalTosConfirm from "../notification/new-tos/modal-tos-confirm.vue";

export default {
  name: "PipeToSConfirm",
  components: {
    ModalTosConfirm,
  },
  created() {
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId.startsWith("modal-tos-confirm")) {
        window.location.reload();
      }
    });
  },
  mounted() {
    console.log("--------- Pipeline Step ToSConfirm ----------");

    if (this.$store.state.termsIdToAccept) {
      this.$bvModal.show("modal-tos-confirm");
    } else {
      this.$store.commit("popups/setNextPipelineQueueStep");
    }
  },
};
</script>

<style scoped lang="scss"></style>
