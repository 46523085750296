import * as authAPI from "@/API/auth.js";

const getDefaultState = () => {
  return {
    status: "",
    auth_token: "",
    referrerToken: "",
    stay_logged_in: true,
    acpMode: false,
    lpBonusName: null,
  };
};

// initial state
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state) {
    state.status = "success";
  },
  auth_pending(state, token) {
    state.status = "pending";
    state.auth_token = "Bearer " + token;
  },
  auth_error(state) {
    state.status = "error";
    state.auth_token = "";
  },

  setReferrerToken(state, string) {
    state.referrerToken = string;
  },
  setStayLoggedIn(state, boolean) {
    state.stay_logged_in = boolean;
  },
  setAcpMode: (state, boolean) => (state.acpMode = boolean),
  setLpBonusName: (state, payload) => (state.lpBonusName = payload),
};

const getters = {
  isLoggedIn: (state) => !!state.auth_token && state.status === "success",
  authStatus: (state) => state.status,
  userToken: (state) => state.auth_token,
};

const actions = {
  resetAuthState({ commit }) {
    commit("resetState");
  },
  logUserIn({ commit, dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      const localeQuery =
        rootGetters.getLocale + "_" + rootGetters.getLocale.toUpperCase();
      authAPI
        .fetchLoggedInUser(payload, localeQuery)
        .then((res) => {
          dispatch("setUserData", res);
          dispatch("setUserTimers", res);

          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          commit("auth_error");
          commit("user/updateUserObject", null, { root: true });

          reject(err);
        });
    });
  },
  acpLogin({ commit, dispatch }, payload) {
    dispatch("setUserTimers", payload);
    const user = payload.data.data.user;
    commit("auth_success");
    commit("setAcpMode", true);
    commit("user/updateUserObject", user, { root: true });
  },

  setUserData({ commit }, payload) {
    console.log(payload);
    const token = payload.data.data.personal_access_token;
    // as of now, the entire user object is sent to the store
    // so it can be accessed anywhere within the app after successful login
    const user = payload.data.data.user;
    commit("auth_pending", token);
    commit("auth_success");
    commit("user/updateUserObject", user, { root: true });
  },

  setUserTimers({ commit }, payload) {
    //set user timers
    commit(
      "bonusslot/setBonusSlotEndDate",
      payload.data.data.bonus_slot_remaining_secs,
      { root: true }
    );
    commit("user/setExpboostEndDate", new Date().toISOString(), {
      root: true,
    });
    const timers = payload.data.data.user.usertimers;
    for (let i = 0; i < timers.length; i++) {
      if (timers[i].type === "EXPBOOSTER") {
        let expTimerEnd = timers[i].end_at;
        if (expTimerEnd) {
          commit("user/setExpboostEndDate", expTimerEnd, {
            root: true,
          });
        }
      }
    }
  },
  logUserInFromLandingPage({ commit, dispatch }, payload) {
    commit("auth_request");
    return new Promise((resolve) => {
      dispatch("setUserData", payload);
      dispatch("setUserTimers", payload);
      resolve();
    });
  },
  registerUser({ commit, dispatch, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      const localeQuery =
        rootGetters.getLocale + "_" + rootGetters.getLocale.toUpperCase();
      authAPI
        .registerNewUser(payload, localeQuery)
        .then((res) => {
          dispatch("setUserData", res);
          resolve(res);
        })
        .catch((err) => {
          commit("auth_error");
          commit("user/updateUserObject", null, { root: true });
          reject(err);
        });
    });
  },
  facebookLogin({ commit, dispatch, rootGetters }, oAuthToken) {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      const localeQuery =
        rootGetters.getLocale + "_" + rootGetters.getLocale.toUpperCase();
      authAPI
        .loginWithFacebook(oAuthToken, localeQuery)
        .then((res) => {
          dispatch("setUserData", res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          commit("auth_error");
          commit("user/updateUserObject", null, { root: true });
          reject(err);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
