import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchRewards = () =>
  axios
    .get("api/battles/rewards", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchMatchday = () =>
  axios
    .get("api/battles/matchday", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const getConfig = () =>
  axios
    .get("api/battles/config", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const getRanking = () =>
  axios
    .get("api/battles/ranking", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const getRankingHistory = () =>
  axios
    .get("api/battles/ranking/history", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchBattle = () =>
  axios
    .get("api/battles/user/battle", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchAllBattles = () =>
  axios
    .get("api/battles/user/battles", optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const createBattle = () =>
  axios
    .post("api/battles/create", {}, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => e.data);

export const getBattleById = (battleId) =>
  axios
    .get(`api/battles/battle/${battleId}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
