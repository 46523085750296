<template>
  <div ref="spinner-container" class="spinner-container pt-5">
    <div ref="custom-spinner" class="custom-spinner to-center-abs"></div>
  </div>
</template>
<script>
import spinner_json1 from "./json/kleinekrone-animation-spinner-newspritesheet";
import AnimationBaseFunctions from "./animation-base-functions";

export default {
  name: "AnimationSpinner", //size="small, medium, big" default=small
  mixins: [AnimationBaseFunctions],
  props: {
    context: { type: String, default: "loading..." },
    classes: { type: String, default: "" },
    size: { type: String, default: "" },
    styles: { type: String, default: "" },
  },
  data() {
    return {
      destroyApp: false,
    };
  },
  mounted() {
    this.doAnimation();
  },

  beforeDestroy() {
    this.destroyApp = true;
  },

  methods: {
    doAnimation: function (preload) {
      //merge sprite sheets
      let json_arr = this.createMergeJsonArr([spinner_json1]);
      let steps = json_arr.length - 1;

      let container = this.$refs["spinner-container"];
      let element = this.$refs["custom-spinner"];
      element.frame = 0;

      //add custom classes
      if (this.classes) {
        let class_arr = this.classes.split(" ");
        for (let i = 0; i < class_arr.length; i++) {
          container.classList.add(class_arr[i]);
        }
      }
      //add custom styles
      if (this.styles) {
        container.style.cssText = this.styles;
      }

      if (preload) {
        this.gsap.set(element, {
          opacity: 0,
        });
      }

      let duration = steps / 24;
      if (preload) {
        duration = 0.5;
      }
      let animation = this.gsap.to(element, duration, {
        frame: steps,
        onComplete: () => {
          if (this.destroyApp) {
            return;
          }
          animation.restart();
        },
        ease: `steps(${steps})`,
        modifiers: {
          frame: (i, target) => {
            let sprite = json_arr[Math.round(i)].frame;
            //set background images
            this.gsap.set(target, {
              xPercent: -50,
              yPercent: -50,
              background:
                'url("' +
                require("@/assets/img/animation/spinner/" +
                  json_arr[Math.round(i)].image) +
                '")',
            });

            //set posiion and with of sprite
            target.style.backgroundPosition =
              -sprite.x + "px " + -sprite.y + "px";
            target.style.width = sprite.w + "px";
            target.style.height = sprite.h + "px";

            return i;
          },
        },
      });

      return element;
    },
  },
};
</script>

<style lang="scss">
.spinner-container {
  height: 3em;
  width: 3em;
  display: inline-block;
  vertical-align: text-bottom;
  z-index: 100;
}
.custom-spinner {
}
.bluetext {
  font-size: 50px;
  top: 120%;
  color: #2de4f4;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
}
</style>
