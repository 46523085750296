import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchAllTeams = () =>
  axios.get("api/profile/teams", optionsFunction());

export const fetchAllOpenTeams = () =>
  axios.get("api/team/open", optionsFunction());

export const fetchUserTeam = (id) =>
  axios.get(`api/profile/team/${id}`, optionsFunction());

export const joinTeam = (team) =>
  axios.post(`api/profile/teams/${team}`, {}, optionsFunction());

export const leaveTeam = () =>
  axios.delete("api/profile/teams", optionsFunction());
