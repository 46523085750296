<template>
  <div
    v-if="$store.getters.infoPreference"
    :id="id"
    class="info-badge-orange bg-img"
    :class="[{ hidden: tooltipOnly }, position, size]"
  >
    <b-popover
      ref="popover"
      :target="id"
      triggers="click"
      container="body"
      :placement="placement"
      custom-class="info-badge-popover"
    >
      <div v-if="headlineText" class="headline">
        {{ headlineText }}
      </div>
      <div class="main-text">
        {{ infoText }}
      </div>

      <router-link
        v-if="faqLink && $store.state.locale === 'de'"
        :to="{
          name: 'faq',
          params: { linkedSection: sectionId, linkedArticle: faqArticle },
        }"
        >{{ $t("info_badge.more_info") }}</router-link
      >
      <div class="popup-close-button" @click="onClose"></div>
    </b-popover>
  </div>
</template>
<script>
export default {
  name: "InfoBadge",

  props: {
    headlineText: {
      type: String,
      default: "",
    },
    infoText: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: null,
    },
    tooltipOnly: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "right",
    },
    placement: {
      type: String,
      default: "top",
    },
    size: {
      type: String,
      default: "",
    },
    faqLink: {
      type: String,
      default: null,
    },
    faqArticle: {
      type: String,
      default: null,
    },
  },
  computed: {
    sectionId() {
      switch (this.faqLink) {
        case "cards":
          return process.env.VUE_APP_ZENDESK_SECTION_CARDS;
          break;
        case "kingspath":
          return process.env.VUE_APP_ZENDESK_SECTION_KINGSPATH;
          break;
        case "bonus_slot":
          return process.env.VUE_APP_ZENDESK_SECTION_BONUS_SLOT;
          break;
        case "jackpots":
          return process.env.VUE_APP_ZENDESK_SECTION_JACKPOTS;
          break;
        case "friends":
          return process.env.VUE_APP_ZENDESK_SECTION_FRIENDS;
          break;
        case "team":
          return process.env.VUE_APP_ZENDESK_SECTION_TEAMS;
          break;
        case "achievements":
          return process.env.VUE_APP_ZENDESK_SECTION_ACHIEVEMENTS;
          break;

        case "loyalty":
          return process.env.VUE_APP_ZENDESK_SECTION_LOYALTY;
          break;
        case "tournament":
          return process.env.VUE_APP_ZENDESK_SECTION_TOURNAMENTS;
          break;
        default:
          return "";
      }
    },
  },

  beforeDestroy() {
    this.removeBackgroundElement();
  },

  methods: {
    onClose() {
      this.$refs.popover.$emit("close");
    },

    //remove dark background for tooltip popover
    removeBackgroundElement: function () {
      const background_dark =
        document.body.getElementsByClassName("background-popups")[0];
      if (background_dark) {
        document.body.removeChild(background_dark);
      }
    },
  },
};
</script>

<style lang="scss">
.info-badge-orange {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background-image: url(~@/assets/img/common/btn-info-default.png);
  cursor: pointer;

  &:hover {
    filter: drop-shadow(1px 0 3px #ffa900) drop-shadow(0 0 3px #ffa900);
  }

  &.sm {
    height: 20px;
    width: 20px;
    min-width: auto;
    min-height: auto;
  }
  &.lg {
    height: 60px;
    width: 60px;
  }
  &.hidden {
    opacity: 0;
  }
  &.left {
    left: 0;
    right: auto;
  }
  &.right {
    left: auto;
    right: 0;
  }
  &.center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.info-badge-popover {
  border-radius: 16px !important;
  min-width: 331px;
  animation-name: showPopover;
  animation-duration: 1s;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  @keyframes showPopover {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .popover-body {
    background-color: #0d2535 !important;
    border: 2px solid white !important;
    border-radius: 16px !important;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 26px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8),
      0 0 6px rgba(0, 94, 110, 0.8);
    padding: 20px 25px 20px 25px;
    text-align: center;

    a {
      color: rgb(77, 229, 255);
    }

    .headline {
      font-family: Ubuntu-Medium;
      font-size: 18px;
      color: #fff8b7;
      text-shadow: 0 0 3px black;
      padding-bottom: 10px;
    }
    .main-text {
      font-family: Ubuntu-Medium;
      font-size: 14px;
      color: white;
    }
  }

  .popup-close-button {
    position: absolute;
    top: -40px;
    right: -40px;
    width: 84px;
    height: 84px;
    z-index: 10;
    background: url(~@/assets/img/common/btn-close-new.png) center/100% 100%
      no-repeat;
    cursor: pointer;
  }
}
</style>
