import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import modules from "@/store/modules";
import {
  getSettings,
  getDailyLoginScheme,
  getLoyalty,
  getLeague,
  getCardProbabilities,
  getUser,
  getCardRaffle,
  getCardRaffleRewards,
  getChestRewards,
} from "@/API/static-config.js";
import { fetchPublicConfig } from "@/API/auth.js";

const vuexLocal = new VuexPersistence({
  key: "kleinekrone",
  storage: window.localStorage,
  reducer: (state) => {
    if (state.auth.stay_logged_in) {
      return state;
    } else return null;
  },
});

const vuexSession = new VuexPersistence({
  key: "kleinekrone",
  storage: window.sessionStorage,
  reducer: (state) => {
    if (!state.auth.stay_logged_in) {
      return state;
    } else return null;
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin, vuexSession.plugin],
  state: {
    locale: null,
    isPageLoading: true,
    generalConfig: null,
    publicConfig: {},
    displayInfo: true,
    autoRefresh: false,
    dontShowPopupAgain: [],
    hideAllNetentPopups: false,
    hideMaxBetPopups: false,
    showRandomSlotPopup: true,
    activeSortMethod: "standard",
    firebaseClientToken: "",
    windowWidth: window.innerWidth,
    termsIdToAccept: null,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },

    setIsPageLoading(state, payload) {
      state.isPageLoading = payload;
    },
    setGeneralConfig(state, payload) {
      state.generalConfig = payload;
    },
    setPublicConfig(state, payload) {
      state.publicConfig = payload;
    },

    setInfoPreference(state, boolean) {
      state.displayInfo = boolean;
    },
    enableMaintenanceMode: (state) => (state.maintenanceModeEnabled = true),
    disableMaintenanceMode: (state) => (state.maintenanceModeEnabled = false),

    resetAutoRefresh(state) {
      state.autoRefresh = new Date();
    },
    hideNetentPopups: (state, boolean) => (state.hideNetentPopups = boolean),
    setHideMaxBetPopups: (state, boolean) => (state.hideMaxBetPopups = boolean),
    addNoPopupGame(state, slug) {
      state.dontShowPopupAgain.push(slug);
    },
    removeNoPopupGame(state, slug) {
      let gameIndex = state.dontShowPopupAgain.findIndex(
        (title) => title === slug
      );
      state.dontShowPopupAgain.splice(gameIndex, 1);
    },
    setSortMethod: (state, method) => (state.activeSortMethod = method),
    // clearNetentList(state) {
    //   state.dontShowNetentPopupAgain = [];
    // }
    disableRandomSlotPopup: (state) => (state.showRandomSlotPopup = false),
    setFirebaseClientToken: (state, string) =>
      (state.firebaseClientToken = string),
    updateWindowWidth: (state, num) => (state.windowWidth = num),
    setTermsIdToAccept: (state, id) => (state.termsIdToAccept = id),
  },
  getters: {
    getLocale: (state) => state.locale,
    getTermsId: (state) => state.termsIdToAccept,
    getIsPageLoading: (state) => state.isPageLoading,
    getGeneralConfig: (state) => state.generalConfig,
    infoPreference: (state) => state.displayInfo,
    getAutoRefresh: (state) => state.autoRefresh,
    facebookEnabled: (state) => state.publicConfig.facebook_connect_enabled,
    teamsEnabled: (state) => state.publicConfig.teams_enabled,
    showNetentPopup: (state) => (title) =>
      !state.hideNetentPopups && !state.dontShowPopupAgain.includes(title),

    getAppVersion: () => process.env.VUE_APP_VERSION,
    getHideMaxBetPopups: (state) => state.hideMaxBetPopups,
    isMaintenanceModeEnabled: (state) => state.maintenanceModeEnabled,
  },
  actions: {
    setLocale({ commit, dispatch }, locale) {
      commit("setLocale", locale);
      dispatch("cookieManager/setCookie", { name: "kk-locale", data: locale });
    },
    getGeneralConfig({ commit }) {
      return Promise.all([
        getSettings(),
        getDailyLoginScheme(),
        getLoyalty(),
        getLeague(),
        getCardProbabilities(),
        getUser(),
        getCardRaffle(),
        getCardRaffleRewards(),
        getChestRewards(),
      ])
        .then((res) => {
          let configData = {};
          configData.settings = res[0].data;
          configData.dailylogin = res[1].data;
          configData.loyality = res[2].data;
          configData.leagueRewards = res[3].data;
          configData.card = res[4].data;
          configData.user = res[5].data;
          configData.cardRaffle = res[6].data;
          configData.cardRaffleRewards = res[7].data;
          configData.chestRewards = res[8].data;

          commit("setGeneralConfig", configData);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPublicConfig({ commit }) {
      fetchPublicConfig()
        .then((res) => commit("setPublicConfig", res.data.data.settings))
        .catch((e) => console.log(e));
    },

    setIsPageLoading({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setIsPageLoading", payload);
        resolve();
      });
    },
    setToSAccepted({ commit }) {
      commit("setTermsIdToAccept", null);
    },
    handleTermsNotAccepted({ commit, dispatch }, payload) {
      commit("setTermsIdToAccept", payload.data.data.terms.id);
      dispatch("user/prepareTermsToAccept", payload.data.data.terms.id);
    },
    destroyStore({ dispatch }) {
      return new Promise((resolve) => {
        dispatch("auth/resetAuthState");
        dispatch("bonusslot/resetBonusSlotState");
        dispatch("chat/resetChatState");
        dispatch("champions/resetChampionsState");
        dispatch("gameplay/resetGameplayState");
        dispatch("kingspath/resetKingspathState");
        dispatch("missions/resetMissionsState");
        dispatch("popups/resetPopupsState");
        dispatch("shop/resetShopState");
        dispatch("teams/resetTeamsState");
        dispatch("tournaments/resetTournamentsState");
        dispatch("treasury/resetTreasuryState");
        dispatch("user/resetUserState");
        dispatch("events/resetEventsState");
        dispatch("achievements/resetAchievementsState");
        dispatch("news/resetNewsState");
        resolve();
      });
    },

    resetStore({ commit }) {
      commit("missions/setMissionComplete", false);
      commit("missions/setUpdateMission", false);
      commit("user/setDelayedUpdate", false);
      commit("user/setDelayedBoosterUpdate", false);
      commit("kingspath/setKingspathMissionUpdate", false);
      commit("kingspath/setKingwheelNeedUpdate", false);
      commit("kingspath/setKingspathNeedUpdate", false);
      commit("sound/resetCurrentPlayingSounds");
      commit("popups/resetAnimationQueue");
      commit("popups/setQueueAnimationIsRunning", false);
      commit("popups/activateWinnerPopup", false);
      commit("resetAutoRefresh");
      commit("externalScripts/resetState", false);
      commit("animations/resetState");
    },
  },
});
