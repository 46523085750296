<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-starter-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/headline-starterpaket-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div
          class="w-100"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-gold yellow-text">
            {{ formatNumber(rewards[0].gold) }} x
            <div class="icon-gold">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
          </div>
        </div>

        <div
          class="w-100"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-crown yellow-text">
            {{ formatNumber(rewards[0].crown) }} x
            <div class="icon-crown">
              <img
                src="@/assets/img/common/crown6.png"
                alt="Krone"
                class="w-100"
              />
            </div>
          </div>
        </div>

        <div class="w-100 bonus-col">
          <div class="package-bonus-icons">
            <div
              v-if="rewards[0].card > 0"
              class="package-icon package-bonus-icon-card"
            >
              <div class="icon">
                <img src="@/assets/img/common/cardstack-3.png" class="h-100" />
              </div>
              <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
            </div>

            <div
              v-if="rewards[0].loyalty > 0"
              class="package-icon package-bonus-icon-loyalty"
            >
              <div class="icon">
                <img
                  src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                  class="h-100"
                />
              </div>
              <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
            </div>

            <div
              v-if="rewards[0].elixir > 0"
              class="package-icon package-bonus-icon-elixir"
            >
              <div class="icon">
                <img src="@/assets/img/common/elixir.png" class="h-100" />
              </div>
              <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
            </div>

            <div
              v-if="rewards[0].expbooster > 0"
              class="package-icon package-bonus-icon-expbooster"
            >
              <div class="icon">
                <img
                  src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                  class="h-100"
                />
              </div>
              <div class="text">
                {{ Math.round(rewards[0].expbooster / 60) }} Min.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="submit-button to-center-abs"
        @click="buyItem(0, 'Offer', 'Starter')"
      >
        <div>
          {{ $t("shop.offers.buy_button", { 0: "" }) }}
          <br />
          <span class="big position-relative"
            >{{ formatPrice(price[0]) }} €

            <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
              {{ formatPrice(oldPrices[0]) }} €
              <div class="offer-red-line to-center-abs"></div>
            </div>
            <div
              v-if="moreContent && moreContent[0]"
              class="big-badge-more-percent smaller"
            >
              <span class="big">{{ moreContent[0] }}</span>
              {{ $t("shop.offers.more_contents2") }}
            </div>
          </span>
        </div>
      </div>

      <div class="badge-big">
        <div>
          {{ $t("shop.offers.badge1", { 0: "" }) }} <span class="big">15</span>
        </div>
      </div>
      <div class="badge-small">
        <div>
          <span class="big">1 X</span>
          {{ $t("shop.offers.badge2", { 0: "" }) }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";

export default {
  name: "ModalOfferStarter1",
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-starter-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-starter-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-starterpaket.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: 50px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-starter-1 {
  .headline {
    position: absolute;
    top: -13px;
    left: 145px;
  }

  .offer-reward-container {
    top: 180px;
    right: 300px;
    text-align: right;
    line-height: 1.3;
  }

  .amount-gold {
    font-size: 65.7px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;

    .icon-gold {
      position: absolute;
      width: 48px;
      right: -70px;
      top: 0;
    }
  }

  .amount-crown {
    font-size: 65.7px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;

    .icon-crown {
      position: absolute;
      width: 83px;
      right: -90px;
      top: -5px;
    }
  }

  .bonus-col {
    margin-left: 100px;
    margin-top: 20px;
  }

  .package-bonus-icons {
    max-width: 450px;
    float: right;
    margin-right: 1px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    .package-icon {
      width: 95px;
      text-align: center;
    }

    .package-bonus-icon-card {
      .text {
        color: #4de5ff;
        font-family: Ubuntu;
        font-size: 26px;
        margin-top: 10px;
      }

      .icon {
        height: 51px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .package-bonus-icon-loyalty {
      .text {
        color: #4de5ff;
        font-family: Ubuntu;
        font-size: 26px;
        margin-top: 10px;
      }

      .icon {
        height: 52px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .package-bonus-icon-elixir {
      .text {
        color: #4de5ff;
        font-family: Ubuntu;
        font-size: 26px;
        margin-top: 10px;
      }

      .icon {
        height: 51px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .package-bonus-icon-expbooster {
      .text {
        color: #4de5ff;
        font-family: Ubuntu;
        font-size: 26px;
        margin-top: 10px;
      }

      .icon {
        height: 51px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .submit-button {
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 85px;
    padding-right: 70px;
    display: inline-flex;
    line-height: 1;
    bottom: 10px;
    left: 220px;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }

    .offer-old-price {
      top: -55px;
      right: -130px;
    }
    .big-badge-more-percent {
      top: -450px;
      right: -220px;
    }
  }

  .badge-big {
    width: 181px;
    height: 180px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #4de5ff;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px rgba(77, 229, 255, 0.59));
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    line-height: 1.1;
    position: absolute;
    right: -30px;
    bottom: 130px;

    .big {
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-small {
    width: 143px;
    height: 143px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4de5ff;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px rgba(77, 229, 255, 0.59));
    padding-left: 35px;
    padding-right: 35px;
    line-height: 1.3;
    position: absolute;
    right: 30px;
    bottom: 35px;
    padding-bottom: 10px;

    .big {
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 38px;
    }
  }
}
</style>
