export default {
  methods: {
    sortWithMethod(method) {
      switch (method) {
        case "standard":
          this.sortStandardNew();
          break;
        case "up":
          this.sortUpDown("up");
          break;
        case "down":
          this.sortUpDown("down");
          break;
        case "jackpotHigh":
          this.sortJackpots("high");
          break;
        case "jackpotHot":
          this.sortJackpots("hot");
          break;
        case "levelDown":
          this.sortLevelDown();
          break;
        case "levelUp":
          this.sortLevelUp();
          break;
        case "lastPlayed":
          this.sortLastPlayed();
          break;
        case "popular":
          this.sortPopular();
          break;
        case "newest":
          this.sortNewest();

          break;
        default:
          this.sortStandard();
      }
    },
    sortAlpha(array) {
      return array.sort((a, b) =>
        a.title > b.title ? 1 : b.title > a.title ? -1 : 0
      );
    },
    sortStandardNew() {
      let gamesWithPrio = this.shownGames
        .filter((game) => game.sort_order && game.unlocked)
        .sort((a, b) => b.sort_order - a.sort_order);
      let oldShownGames = this.shownGames.slice();

      gamesWithPrio.forEach((game) =>
        this.extractGamesFromArray(oldShownGames, game.id)
      );
      if (
        this.gameStats &&
        this.gameStats.rank_by_spins &&
        this.gameStats.rank_by_spins.length > 0
      ) {
        let popularGames = this.gameStats.rank_by_spins.map((numberString) =>
          parseInt(numberString)
        );
        let newShownGames = [];
        // extract popular games
        popularGames.forEach((id) => {
          oldShownGames.forEach((game) => {
            if (game.id === id) {
              newShownGames.push(game);
            }
          });
        });
        newShownGames.forEach((game) =>
          this.extractGamesFromArray(oldShownGames, game.id)
        );
        // show prio games first, then popular, then rest of games
        this.shownGames = gamesWithPrio.concat(
          newShownGames.concat(this.sortAlpha(oldShownGames))
        );
        this.sortLockedByUnlockLevel();
      } else {
        this.sortStandard();
      }
    },
    sortStandard() {
      // oh boy this sure is a heck of a method. Product requirements are as follows:
      // 1st row: hottest jackpots, both slots and jackpots must be unlocked for user
      // 2nd row: last played slot plus popular slots (up to 4 in total). Popular slots might be locked for user
      // 3rd row: slots with highest jackpots, both slots and jackpots must be unlocked for user
      let fourHottestJackpots = [];
      let secondRow = [];
      let fourHighestJackpots = [];
      let oldShownGames = this.shownGames.slice();

      // create array with ids of games with hot jackpots
      let hottestJackpots = new Set(
        this.slotJackpots
          .filter(
            (jackpot) =>
              jackpot.user_settings.min_level <=
              this.$store.getters["user/currentUser"].level
          )
          .sort((a, b) => b.hotness - a.hotness)
          .map((jackpot) => jackpot.game_id)
      );
      // push up to four games with hottest jackpots into new array
      hottestJackpots.forEach((number) => {
        this.shownGames.forEach((game) => {
          if (
            game.id === number &&
            game.settings &&
            game.settings.unlock_level <=
              this.$store.getters["user/currentUser"].level &&
            fourHottestJackpots.length < 4
          ) {
            fourHottestJackpots.push(game);
            // delete games in hot jackpots array from original array
            this.extractGamesFromArray(oldShownGames, number);
          }
        });
      });

      if (this.gameStats && this.gameStats.last_played_by_user) {
        let lastPlayedGame = parseInt(this.gameStats.last_played_by_user);

        // extract last played game
        let lastPlayedGameObject = oldShownGames.find(
          (game) => game.id === lastPlayedGame
        );
        if (lastPlayedGameObject) {
          secondRow.push(lastPlayedGameObject);
          this.extractGamesFromArray(oldShownGames, lastPlayedGame);
        }
      }

      if (
        this.gameStats &&
        this.gameStats.rank_by_spins &&
        this.gameStats.rank_by_spins.length > 0
      ) {
        // grab popular games from stats
        let popularGames = this.gameStats.rank_by_spins;

        // extract popular games
        popularGames.forEach((gameId) => {
          let num = parseInt(gameId);
          oldShownGames.forEach((game) => {
            // push up to four popular games into new array
            if (game.id === num && secondRow.length < 4) {
              secondRow.push(game);
              // delete four popular games from original array
              this.extractGamesFromArray(oldShownGames, num);
            }
          });
        });
      }

      // create array with ids of games with high jackpots
      let highestJackpots = new Set(
        this.slotJackpots
          .filter(
            (jackpot) =>
              jackpot.user_settings.min_level <=
              this.$store.getters["user/currentUser"].level
          )
          .sort((a, b) => b.amount_current - a.amount_current)
          .map((jackpot) => jackpot.game_id)
      );

      // push up to four games with highest jackpots into new array
      highestJackpots.forEach((number) => {
        oldShownGames.forEach((game) => {
          if (
            game.id === number &&
            game.settings.unlock_level <=
              this.$store.getters["user/currentUser"].level &&
            fourHighestJackpots.length < 4
          ) {
            fourHighestJackpots.push(game);
            // delete four games with high jackpots from original array
            this.extractGamesFromArray(oldShownGames, number);
          }
        });
      });
      // sort remanining games by unlock level
      oldShownGames.sort((a, b) => {
        if (a.settings && b.settings) {
          return a.settings.unlock_level - b.settings.unlock_level;
        } else {
          return 0;
        }
      });
      // combine all arrays for final sorting
      this.shownGames = fourHottestJackpots
        .concat(secondRow)
        .concat(fourHighestJackpots)
        .concat(oldShownGames);
    },
    sortLevelDown() {
      this.sortAlpha(this.shownGames);
      this.shownGames.sort(
        (a, b) => b.settings.unlock_level - a.settings.unlock_level
      );
    },
    sortLevelUp() {
      this.sortAlpha(this.shownGames);
      this.shownGames.sort(
        (a, b) => a.settings.unlock_level - b.settings.unlock_level
      );
    },
    sortJackpots(method) {
      let sortedJackpots;
      let playableJackpots = this.slotJackpots.filter(
        (jackpot) =>
          jackpot.user_settings.min_level <=
          this.$store.getters["user/currentUser"].level
      );
      if (method === "high") {
        // create set with sorted game ids to avoid duplication
        sortedJackpots = new Set(
          playableJackpots
            .sort((a, b) => b.amount_current - a.amount_current)
            .map((jackpot) => jackpot.game_id)
        );
      } else if (method === "hot") {
        sortedJackpots = new Set(
          playableJackpots
            .sort((a, b) => b.hotness - a.hotness)
            .map((jackpot) => jackpot.game_id)
        );
      }
      let newShownGames = [];
      let oldShownGames = this.shownGames.slice();
      // extract games with jackpots
      sortedJackpots.forEach((number) => {
        this.shownGames.forEach((game) => {
          if (game.id === number) {
            newShownGames.push(game);
          }
        });
        // delete games with jackpots
        this.extractGamesFromArray(oldShownGames, number);
      });
      // show games with jackpots first, then rest of games
      this.shownGames = newShownGames.concat(this.sortAlpha(oldShownGames));

      this.sortLockedByUnlockLevel();
    },
    sortLastPlayed() {
      if (this.gameStats && this.gameStats.last_played_by_user) {
        let num = parseInt(this.gameStats.last_played_by_user);
        let newShownGames = [];
        let oldShownGames = this.shownGames.slice();
        // extract game last played by user
        this.shownGames.forEach((game) => {
          if (game.id === num) {
            newShownGames.push(game);
          }
        });
        // delete last played game from rest of games
        this.extractGamesFromArray(oldShownGames, num);
        // show game last played first, then rest of games
        this.shownGames = newShownGames.concat(this.sortAlpha(oldShownGames));
      } else {
        this.sortStandard();
      }
    },
    sortPopular() {
      if (
        this.gameStats &&
        this.gameStats.rank_by_spins &&
        this.gameStats.rank_by_spins.length > 0
      ) {
        let popularGames = this.gameStats.rank_by_spins;
        let newShownGames = [];
        let oldShownGames = this.shownGames.slice();
        // extract popular games
        popularGames.forEach((gameId) => {
          let num = parseInt(gameId);
          this.shownGames.forEach((game) => {
            if (game.id === num) {
              newShownGames.push(game);
            }
          });
          // delete popular games from rest of games
          this.extractGamesFromArray(oldShownGames, num);
        });
        // show popular games first, then rest of games
        this.shownGames = newShownGames.concat(this.sortAlpha(oldShownGames));

        this.sortLockedByUnlockLevel();
      } else {
        this.sortStandard();
      }
    },
    sortNewest() {
      this.sortAlpha(this.shownGames);
      this.shownGames.sort((a, b) => {
        if (a.created_at && b.created_at) {
          let timeAddedA = new Date(a.created_at).getTime();
          let timeAddedB = new Date(b.created_at).getTime();
          return timeAddedB - timeAddedA;
        } else {
          return b.id - a.id;
        }
      });
    },
    sortUpDown(direction) {
      this.shownGames.sort((a, b) => {
        if (direction === "down") {
          return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
        } else {
          return a.title < b.title ? 1 : b.title < a.title ? -1 : 0;
        }
      });
    },
    filterSlotsBySearchTerm(term) {
      let filteredSlots = this.allGames.filter((game) =>
        game.title.toLowerCase().includes(term.toLowerCase())
      );
      this.shownGames = filteredSlots;
    },
    extractGamesFromArray(gameArray, number) {
      gameArray.forEach((game, index) => {
        if (game.id === number) {
          gameArray.splice(index, 1);
        }
      });
    },
    sortLockedByUnlockLevel() {
      this.shownGames = this.shownGames
        .sort((a, b) => {
          return a.unlocked === b.unlocked ? 0 : a.unlocked ? -1 : 1;
        })
        .sort((a, b) =>
          a.unlocked === false && b.unlocked === false
            ? a.settings.unlock_level - b.settings.unlock_level
            : 0
        );
    },
  },
};
