<template>
  <div id="seo-row" class="container">
    <div class="row">
      <div v-for="route in routes" :key="route.name" class="col">
        <a :href="route.path">{{ route.meta.seoTitle }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { seoRoutes } from "@/seo-routes";

export default {
  name: "SeoRow",
  computed: {
    routes() {
      return seoRoutes;
    },
  },

  mounted() {},
};
</script>
<style lang="scss">
#seo-row {
}
</style>
