<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-bailout-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline to-center-abs">
        <img
          :src="
            require(`@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutgold-1-panel-head-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div class="w-100">
          <div class="icon-gold-container">
            <div class="icon-gold icon-gold1 to-center-abs">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
            <div class="icon-gold icon-gold2 to-center-abs">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
            <div class="icon-gold icon-gold3 to-center-abs">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
          </div>
        </div>

        <div
          class="w-100"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-gold yellow-text">
            {{ formatNumber(rewards[0].gold) }}
          </div>
        </div>
        <div
          class="w-100"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-crown yellow-text">
            {{ formatNumber(rewards[0].crown) }} x
            <div class="icon-crown">
              <img
                src="@/assets/img/common/crown6.png"
                alt="Krone"
                class="w-100"
              />
            </div>
          </div>
        </div>

        <div class="bonus-col">
          <div
            class="w-100 package-bonus-icons d-flex align-items-center justify-content-around"
          >
            <div
              v-if="rewards[0].card > 0"
              class="package-icon package-bonus-icon-card"
            >
              <div class="icon">
                <img src="@/assets/img/common/cardstack-3.png" class="h-100" />
              </div>
              <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
            </div>

            <div
              v-if="rewards[0].loyalty > 0"
              class="package-icon package-bonus-icon-loyalty"
            >
              <div class="icon">
                <img
                  src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                  class="h-100"
                />
              </div>
              <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
            </div>

            <div
              v-if="rewards[0].elixir > 0"
              class="package-icon package-bonus-icon-elixir"
            >
              <div class="icon">
                <img src="@/assets/img/common/elixir.png" class="h-100" />
              </div>
              <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
            </div>

            <div
              v-if="rewards[0].expbooster > 0"
              class="package-icon package-bonus-icon-expbooster"
            >
              <div class="icon">
                <img
                  src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                  class="h-100"
                />
              </div>
              <div class="text">
                {{ Math.round(rewards[0].expbooster / 60) }} Min.
              </div>
            </div>
          </div>
        </div>
        <div
          class="submit-button to-center-abs"
          @click="buyItem(0, 'Offer', 'Bailout')"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big">{{ formatPrice(price) }} €</span>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
export default {
  name: "ModalOfferBailout1",
  mixins: [offerModalContent],
  data() {
    return {
      isPipelineOffer: false,
    };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-bailout-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-bailout-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutgold-1-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-bailout-1 {
  .headline {
    width: 663px;
    top: 0;
  }

  .offer-reward-container {
    top: 195px;
    line-height: 1.2;
    text-align: center;
    width: 500px;
    position: absolute;
    left: 7px;
  }

  .icon-gold-container {
    height: 100px;

    .icon-gold {
      &.icon-gold1 {
        z-index: 1;
        left: -120px;
        width: 88px;
      }
      &.icon-gold2 {
        z-index: 2;
        width: 112px;
        top: -25px;
      }

      &.icon-gold3 {
        z-index: 1;
        right: -120px;
        width: 88px;
      }
    }
  }

  .amount-gold {
    font-size: 72px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;
    line-height: 1;
  }

  .amount-crown {
    font-size: 65.7px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;
    margin-left: -80px;

    .icon-crown {
      position: absolute;
      width: 80px;
      right: -90px;
      top: -6px;
    }
  }

  .bonus-col {
    margin-top: 10px;
    .package-bonus-icons {
      padding: 0 10px;
      white-space: nowrap;

      .package-icon {
        width: 93px;
        text-align: center;
      }

      .package-bonus-icon-card {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 46px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .package-bonus-icon-loyalty {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .package-bonus-icon-elixir {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .package-bonus-icon-expbooster {
        .text {
          color: #4de5ff;
          font-family: Ubuntu;
          font-size: 22px;
          margin-top: 5px;
        }

        .icon {
          height: 47px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .submit-button {
    width: 404px;
    height: 181px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 22px;
    display: inline-flex;
    line-height: 1;
    position: absolute;
    bottom: -155px;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }
}
</style>
