<template>
  <div
    id="canvas-container-animation-spine-winterbonusjagd-unlock-level"
    ref="canvascontainer"
    class="canvas-container-animation-spine-winterbonusjagd-unlock-level"
  ></div>
</template>

<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";
import sound_super_win from "@/assets/sound/animation/adelsliga-aufstieg-fanfare.mp3";

export default {
  name: "AnimationWinterbonusjagdUnlockLevel",
  mixins: [AnimationSpineBaseFunctions],
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    run: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      unsubscribeEvents: [],
      completed: false,
      destroyApp: false,
    };
  },

  watch: {
    run: function () {
      this.play();
    },
    completed() {
      if (this.completed) {
        this.$emit("kingspass-ani-completed");
      }
    },
  },
  mounted() {
    this.play();
  },
  beforeDestroy() {
    //destroy event listeners
    for (let i = 0; i < this.unsubscribeEvents.length; i++) {
      this.unsubscribeEvents[i]();
    }

    this.destroyApp = true;
  },

  methods: {
    play: function () {
      const canvasEl = document.getElementById(
        "canvas-animation-spine-winterbonusjagd-unlock-level"
      );

      const assets = [
        [
          "winterbonusjagd-kingspass",
          "/assets/spines/events/winterbonusjagd/winterbonusjagd-unlocklevel.json",
        ],
      ];
      const options = {
        width: 1121,
        height: 715,
        antialias: true,
        backgroundAlpha: 0.9,
        autoResize: true,
      };

      const app = this.createCanvas(
        options,
        assets,
        this.$refs.canvascontainer.id
      );
      app.view.id = "canvas-animation-spine-winterbonusjagd-unlock-level";

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      //text
      let text1 = document.createElement("div");
      text1.classList.add("text1");
      text1.classList.add("to-center-abs");

      let levelText = this.level + " - " + (this.level + 9);
      if (this.level >= 20) {
        levelText = this.level + " - " + "31";
      }

      text1.innerHTML = this.$t("events.winter.unlocked_level", {
        0: levelText,
      });
      this.$refs["canvascontainer"].appendChild(text1);

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);
        const completedFunction = () => this.$emit("kingspass-ani-completed");

        spineData.forEach((data) => {
          const spine = new Spine(data);

          //skeleton and animation configs
          let animationName = "main";

          // set the position
          spine.x = app.screen.width / 2;
          spine.y = app.screen.height - 350;

          //add spine to stage
          app.stage.addChild(spine);

          let animation = this.getAnimationByName(data, animationName);
          //run animation
          spine.state.setAnimation(0, animationName);

          //show first frame when not running
          if (!this.run) {
            spine.state.getCurrent(0).animationEnd = 0;
            return;
          }

          //set animation end
          spine.state.getCurrent(0).animationEnd = animation.duration;

          // Detach event listeners
          this.unsubscribeEvents.push(() => {
            spine.state.getCurrent(0).onEvent = null;
          });

          //add loop
          if (this.loop) {
            spine.state.getCurrent(0).loop = true;
          }
          spine.state.addListener({
            complete: function () {
              completedFunction();
            },
          });
        });
      };

      app.loader.load(setup);

      //remove old chest when new loaded
      app.loader.onComplete.add(() => {
        this.showText(text1);
        if (canvasEl) {
          this.removePrevCanvas(this.$refs.canvascontainer.id);
        }
      });
    },

    showText(element) {
      const timeline = this.gsap.timeline();
      timeline
        .to(
          [element],
          0,
          {
            onComplete: () => {
              this.playSoundEffect(sound_super_win, false, 0.7);
            },
          },
          0
        )
        .fromTo(
          [element],
          0.8,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: 1,
          },
          1
        );
    },
  },
};
</script>

<style lang="scss">
.canvas-container-animation-spine-winterbonusjagd-unlock-level {
  pointer-events: none;

  canvas {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }

  .text1 {
    font-family: Ubuntu-Medium;
    color: white;
    background: none;
    text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4 !important;
    font-size: 60px;
    top: 85%;
    -webkit-text-fill-color: white;
    transform: scale(0);
    z-index: 1001;
    text-transform: uppercase;
  }
}
</style>
