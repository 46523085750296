const getDefaultState = () => {
  return {
    bonus_slot_end_date: 0,
    activatePulseIcon: false,
    forceShowElixirPage: null,
  };
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBonusSlotEndDate(state, seconds) {
    const endDate = new Date();
    endDate.setSeconds(endDate.getSeconds() + seconds);
    state.bonus_slot_end_date = endDate;
  },
  setActivatePulseIcon(state, boolean) {
    state.activatePulseIcon = boolean;
  },
  setForceShowElixirPage(state, boolean) {
    state.forceShowElixirPage = boolean;
  },
};

const getters = {
  getBonusSlotEndDate: (state) => state.bonus_slot_end_date,
  getActivatePulseIcon: (state) => state.activatePulseIcon,
  getForceShowElixirPage: (state) => state.forceShowElixirPage,
};

const actions = {
  resetBonusSlotState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
