import { optionsFunction } from "./_config";
import axios from "axios";

export const getAllEvents = () =>
  axios.get(`/api/event/all`, optionsFunction());

export const getActiveEvents = () =>
  axios.get("api/event/active", optionsFunction());

export const getEventById = (id) =>
  axios.get(`/api/event/${id}`, optionsFunction());

export const getEventForCurrentUser = (eventId) =>
  axios.get(`/api/event/user/${eventId}`, optionsFunction());

export const getEventForUser = (eventId, userId) =>
  axios.get(`/api/event/user/${eventId}/${userId}`, optionsFunction());

export const getRaffles = () => axios.get(`/api/raffle/all`, optionsFunction());

export const getActiveRaffles = () =>
  axios
    .get("api/raffle/active", optionsFunction())
    .then((res) => res.data.data);

export const getRaffleById = (id) =>
  axios.get(`/api/raffle/${id}`, optionsFunction());

export const getLoggedRaffleById = (id) =>
  axios.get(`/api/raffle/log/${id}`, optionsFunction());

export const getGoForGoldRaffle = () =>
  axios.get(`/api/raffle/goforgold`, optionsFunction());

export const getGoForGoldRaffleByIdAndUser = (raffleId, userId) =>
  axios.get(`/api/raffle/goforgold/${raffleId}/${userId}`, optionsFunction());

export const collectGoForGoldRaffle = (id, userId) =>
  axios.post(`/api/raffle/goforgold/${id}/${userId}`, {}, optionsFunction());

export const collectTipKingRaffle = (id, userId) =>
  axios.post(`/api/raffle/sport/${id}/${userId}`, {}, optionsFunction());

export const placeSportsBet = (eventId, home, away) =>
  axios.post(
    `/api/event/sport/${eventId}`,
    { home: home, away: away },
    optionsFunction()
  );

export const getTipKingEventRaffle = () =>
  axios.get(`/api/raffle/sport`, optionsFunction());

export const getTipKingEventRaffleByIdAndUser = (raffleId, userId) =>
  axios.get(`/api/raffle/sport/${raffleId}/${userId}`, optionsFunction());

/* KRONENPASS */

export const getKronenpassUserById = (kronenPassId) =>
  axios
    .get(`/api/kronenpass/user/${kronenPassId}`, optionsFunction())
    .then((res) => res.data.data);

export const getKronenpassUserHighestById = (kronenPassId) =>
  axios
    .get(`/api/kronenpass/user/${kronenPassId}/highest`, optionsFunction())
    .then((res) => res.data.data);

export const kronenpassPurchaseTier = (id, tier) =>
  axios
    .post(
      `/api/kronenpass/tier/${id}/${tier}`,
      { id: id, tier: tier },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const kronenpassPurchaseLevel = (id, level) =>
  axios
    .post(
      `/api/kronenpass/level/${id}/${level}`,
      { id: id, level: level },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const getKronenpassActive = () =>
  axios
    .get(`/api/kronenpass/active`, optionsFunction())
    .then((res) => res.data.data);

export const getKronenpassAll = () =>
  axios
    .get(`/api/kronenpass/all`, optionsFunction())
    .then((res) => res.data.data);

export const getKronenpassRewardsById = (id) =>
  axios
    .get(`/api/kronenpass/rewards/${id}`, optionsFunction())
    .then((res) => res.data.data);

export const getKronenpassByIdTierLevel = (id, tier, level) =>
  axios
    .get(`/api/kronenpass/${id}/${tier}/${level}`, optionsFunction())
    .then((res) => res.data.data);

export const getKronenpassAllTiers = (id) =>
  axios
    .get(`/api/kronenpass/user/${id}/tiers`, optionsFunction())
    .then((res) => res.data.data);
