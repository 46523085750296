const getDefaultState = () => {
  return {
    shopChosenPackage: null,
    lobbyOffer: null,
    lastPaymentReference: null,
    bookingResult: null,
    specialOffer: null,
    offerId: "",
    autoShowSpecialOffer: true,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setBookingResult(state, payload) {
    state.bookingResult = payload;
  },
  setLastPaymentReference(state, payload) {
    state.lastPaymentReference = payload;
  },
  setShopChosenPackage(state, payload) {
    state.shopChosenPackage = payload;
  },
  setLobbyOffer(state, payload) {
    state.lobbyOffer = payload;
  },
  setSpecialOffer(state, payload) {
    state.specialOffer = payload;
  },
  setOfferId: (state, string) => (state.offerId = string),
  setAutoShowSpecialOffer: (state, boolean) =>
    (state.autoShowSpecialOffer = boolean),
};

const getters = {
  getBookingResult: (state) => state.bookingResult,
  getLastPaymentReference: (state) => state.lastPaymentReference,
  getShopChosenPackage: (state) => state.shopChosenPackage,
  getLobbyOffer: (state) => state.lobbyOffer,
  getSpecialOffer: (state) => state.specialOffer,
  getSpecialOfferTime: (state) => {
    if (!state.specialOffer) return null;
    let time = new Date(state.specialOffer.valid_until).getTime();
    return time;
  },
};
const actions = {
  resetShopState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
