import dateFunctions from "@/mixins/dateFunctions.js";

const getDefaultState = () => {
  return {
    alertWindow: {
      modalId: null,
      alertText: "",
      twoButtons: false,
      confirmButtonText: null,
      cancelButtonText: null,
      redirectUrl: null,
      titleText: null,
    },
    alertBanner: {
      alertText: "",
      alertHeading: "",
      otherAlertContent: null,
      // other variants: "danger", "success"
      variant: "primary",
      show: false,
      dismissible: false,
      countdown: 5,
      // other types: "danger", "battle", "booster", "check", "fellowship", "friend", "kingspath",
      // "newsletter", "tourney", "offer", "treasury-active", "treasury-full"
      type: "info",
      dismissMessage: "",
    },
    alertBannerQueue: [],
    animationQueue: [],
    queueAnimationIsRunning: false,
    nextNotificationAt: null,
    // Modal id of popup to show once per user session
    manualPopup: "modal-20-percent",
    // Insert date strings for display period of manual popup
    showKingspassRealRewardsPopup: false,
    activeKingspassRealWinnersPopupNew: false,
    showKingspassRealWinnersPopup: false, //winner screen for winter pass
    pipelineQueueStep: 0,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  activateWinnerPopup(state, payload) {
    state.activeKingspassRealWinnersPopupNew = payload;
    state.showKingspassRealWinnersPopup = payload;
  },

  setAnimationQueue(state, payload) {
    if (typeof state.animationQueue[payload.name] !== "undefined") {
      return;
    }

    state.animationQueue[payload.name] = payload.timeline;
  },
  updateAnimationQueues(state, payload) {
    state.animationQueue = payload;
  },
  resetAnimationQueue(state) {
    state.animationQueue = [];
  },
  setQueueAnimationIsRunning(state, payload) {
    state.queueAnimationIsRunning = payload;
  },
  resetQueueAnimationIsRunning(state) {
    state.queueAnimationIsRunning = false;
  },
  setNextNotificationAt(state, payload) {
    state.nextNotificationAt = payload;
  },
  setNextPipelineQueueStep(state) {
    state.pipelineQueueStep++;
  },
  resetPipelineQueueStep(state) {
    state.pipelineQueueStep = 0;
  },
  setModalContent(state, payload) {
    state.alertWindow.modalId = payload.id;
    state.alertWindow.alertText = payload.alertText;
    if (payload.twoButtons) {
      state.alertWindow.twoButtons = true;
    } else {
      state.alertWindow.twoButtons = false;
    }
    if (payload.confirmButtonText) {
      state.alertWindow.confirmButtonText = payload.confirmButtonText;
    }
    if (payload.cancelButtonText) {
      state.alertWindow.cancelButtonText = payload.cancelButtonText;
    }
    if (payload.redirectUrl) {
      state.alertWindow.redirectUrl = payload.redirectUrl;
    }
    if (payload.titleText) {
      state.alertWindow.titleText = payload.titleText;
    }
  },
  setAlertBannerContent(state, payload) {
    let prototypeBanner = state.alertBanner;
    let bannerObject = Object.assign({}, prototypeBanner, payload);
    state.alertBannerQueue.push(bannerObject);
  },
  // add delays to make time for transition animation
  showFirstAlertBanner: (state) => {
    setTimeout(() => (state.alertBannerQueue[0].show = true), 800);
  },
  checkForDuplicateAlerts: (state) => {
    let filteredArray = state.alertBannerQueue.filter(
      (alert, index, self) =>
        index ===
        self.findIndex(
          // check for duplicates based on heading, text and type
          (a) =>
            a.alertHeading === alert.alertHeading &&
            a.alertText === alert.alertText &&
            a.type === alert.type
        )
    );
    // only update queue if duplicates were found
    if (filteredArray.length === state.alertBannerQueue.length) return;
    state.alertBannerQueue = filteredArray;
  },
  hideAlertBanner: (state) => {
    state.alertBannerQueue[0].show = false;
    setTimeout(() => state.alertBannerQueue.shift(), 800);
  },
  clearModalContent(state) {
    state.alertWindow = {
      modalId: null,
      alertText: "",
      twoButtons: false,
      confirmButtonText: null,
      cancelButtonText: null,
      redirectUrl: null,
      titleText: null,
    };
  },
  disableManualPopup: (state) => (state.showManualPopup = false),
  disableKingspassRealRewardsPopup: (state) =>
    (state.showKingspassRealRewardsPopup = false),
  disableKingspassRealRewardsWinnerPopup: (state) =>
    (state.showKingspassRealWinnersPopup = false),
};

const getters = {
  getAnimationQueue: (state) => state.animationQueue,
  getQueueAnimationIsRunning: (state) => state.queueAnimationIsRunning,
  getNextNotificationAt: (state) => state.nextNotificationAt,
  getPipelineQueueStep: (state) => state.pipelineQueueStep,
  showManualPopup: () => {
    // Edit here to set visibility for manual popup
    let startDate = new Date("February 15, 2022 12:00:00");
    let endDate = new Date("February 27, 2022 23:59:00");
    if (
      dateFunctions.methods.isDateInPast(startDate, new Date()) &&
      dateFunctions.methods.isDateInFuture(endDate, new Date())
    ) {
      return true;
    } else {
      return false;
    }
  },
};

const actions = {
  resetPopupsState({ commit }) {
    commit("resetState");
  },
  setModalContent({ commit }, payload) {
    return new Promise((resolve) => {
      commit("setModalContent", payload);
      resolve(payload);
    });
  },

  clearModalContent({ commit }) {
    commit("clearModalContent");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
