import { optionsFunction } from "./_config";
import axios from "axios";

export const findFriends = (query) =>
  axios.get(`api/profile/friends/search/${query}`, optionsFunction());

export const addFriend = (id) =>
  axios
    .post(`api/profile/friends/add/${id}`, {}, optionsFunction())
    .then((response) => response.status)
    .catch((error) => error.response.status);

export const removeFriend = (id) =>
  axios.post(`api/profile/friends/remove/${id}`, {}, optionsFunction());

export const revokeRequest = (id) =>
  axios.delete(`api/profile/friends/revoke/${id}`, optionsFunction());

export const listFriends = () =>
  axios
    .get("api/profile/friends", optionsFunction())
    .then((res) => res.data.data.friends);

export const listOpenFriendRequests = () =>
  axios.get("api/profile/friends/request", optionsFunction());

export const acceptFriendRequest = (id) =>
  axios
    .post(`api/profile/friends/request/${id}`, {}, optionsFunction())
    .then((res) => res.data);

export const declineFriendRequest = (id) =>
  axios
    .delete(`api/profile/friends/request/${id}`, optionsFunction())
    .then((res) => res.data);

export const fetchReferrerLink = () =>
  axios.get("api/profile/friends/link", optionsFunction());

export const fetchFellowshipInfos = () =>
  axios.get("api/treasury/friend", optionsFunction());

export const plunderFellowshipGold = () =>
  axios.post("api/treasury/friend", {}, optionsFunction());
