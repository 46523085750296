<template>
  <div>
    <ModalDailyMain></ModalDailyMain>
  </div>
</template>

<script>
import ModalDailyMain from "@/components/daily-login/modal-daily-main";
export default {
  name: "PipeDailyLogin",
  components: { ModalDailyMain },

  data() {
    return {};
  },

  computed: {},
  watch: {},
  mounted() {
    console.log("--------- Pipeline Step Daily ----------");
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
