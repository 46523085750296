<template>
  <div v-if="avatarUrl" class="avatar-shield">
    <img
      src="@/assets/img/avatar/avatar-selection-bg.png"
      class="shield-bg dead-center"
    />
    <img :src="avatarUrl" class="avatar-img dead-center" />
  </div>
</template>
<script>
export default {
  name: "AvatarShield",
  props: {
    avatarUrl: {
      type: String,
      default: require("@/assets/img/avatar/avatar-placeholder.png"),
    },
    facebookAvatar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.avatar-shield {
  width: 100%;
  height: 100%;
  position: relative;
  .shield-bg {
    max-width: 100%;
    max-height: 100%;
  }
  .avatar-img {
    max-width: 90%;
    max-height: 100%;
  }
}
</style>
