<script>
import firewor_big_blue_0 from "./json/kleinekrone-animation-firework-big-blue-0";
import firewor_big_blue_1 from "./json/kleinekrone-animation-firework-big-blue-1";
import firewor_big_blue_2 from "./json/kleinekrone-animation-firework-big-blue-2";
import firewor_big_blue_3 from "./json/kleinekrone-animation-firework-big-blue-3";
import firewor_big_blue_4 from "./json/kleinekrone-animation-firework-big-blue-4";
import firewor_big_blue_5 from "./json/kleinekrone-animation-firework-big-blue-5";
import firewor_big_blue_6 from "./json/kleinekrone-animation-firework-big-blue-6";
import firewor_big_blue_7 from "./json/kleinekrone-animation-firework-big-blue-7";
import firewor_big_blue_8 from "./json/kleinekrone-animation-firework-big-blue-8";
import firewor_big_blue_9 from "./json/kleinekrone-animation-firework-big-blue-9";
import firewor_big_blue_10 from "./json/kleinekrone-animation-firework-big-blue-10";
import firewor_blue_chain_0 from "./json/adelsliga-aufstieg-firework-0.json";
import firewor_blue_chain_1 from "./json/adelsliga-aufstieg-firework-1.json";
import firewor_blue_chain_2 from "./json/adelsliga-aufstieg-firework-2.json";
import firewor_blue_chain_3 from "./json/adelsliga-aufstieg-firework-3.json";
import firewor_yellow0 from "./json/animation-firework-medium-0";
import firewor_yellow1 from "./json/animation-firework-medium-1";
import firewor_coin from "./json/meinekrone-coinfirework-masked-bottomup-spritesheet";

import AnimationBaseFunctions from "./animation-base-functions";

export default {
  name: "AnimationFirework",
  mixins: [AnimationBaseFunctions],
  methods: {
    AnimationFireworkAnimateFireworkBlueChain: function (
      x,
      y,
      preload,
      parent,
      fps = 24,
      customClass
    ) {
      let json = this.createMergeJsonArr([
        firewor_blue_chain_0,
        firewor_blue_chain_1,
        firewor_blue_chain_2,
        firewor_blue_chain_3,
      ]);

      this.createFirework(
        json,
        x,
        y,
        true,
        parent,
        "blue-chain",
        fps,
        customClass
      );
      this.createFirework(
        json,
        x,
        y,
        false,
        parent,
        "blue-chain",
        fps,
        customClass
      );
    },

    AnimationFireworkAnimateFireworkYellow: function (
      x,
      y,
      preload,
      parent,
      fps = 24,
      customClass
    ) {
      let json = this.createMergeJsonArr([firewor_yellow0, firewor_yellow1]);
      this.createFirework(json, x, y, true, parent, "yellow", fps, customClass);
      this.createFirework(
        json,
        x,
        y,
        false,
        parent,
        "yellow",
        fps,
        customClass
      );
    },
    AnimationFireworkAnimateFireworkBlue: function (
      x,
      y,
      preload,
      parent,
      fps = 24,
      customClass
    ) {
      let json = this.createMergeJsonArr([
        firewor_big_blue_0,
        firewor_big_blue_1,
        firewor_big_blue_2,
        firewor_big_blue_3,
        firewor_big_blue_4,
        firewor_big_blue_5,
        firewor_big_blue_6,
        firewor_big_blue_7,
        firewor_big_blue_8,
        firewor_big_blue_9,
        firewor_big_blue_10,
      ]);

      this.createFirework(json, x, y, true, parent, "blue", fps, customClass);
      this.createFirework(json, x, y, false, parent, "blue", fps, customClass);
    },

    AnimationFireworkCoin: function (x, y, preload, parent, customClass) {
      let json = this.createMergeJsonArr([firewor_coin]);
      this.createFirework(
        json,
        x,
        y,
        preload,
        parent,
        "firework-coin",
        12,
        customClass
      );
    },

    createFirework: function (
      json_arr,
      x,
      y,
      preload,
      parent,
      classes,
      fps = 24,
      customClass,
      repeat = false
    ) {
      let steps = json_arr.length - 1;
      let body = document.body;

      let element = document.createElement("gsap-firework");
      element.classList.add(classes);
      element.classList.add(customClass);

      let parentElement = body;
      if (parent) {
        parentElement = parent;
      }

      parentElement.appendChild(element);

      element.frame = 0;

      if (x === undefined && y === undefined) {
        x = document.body.offsetWidth / 2;
        y = "500";
      }

      this.gsap.set(element, {
        x: x,
        y: y,
      });
      if (preload) {
        this.gsap.set(element, {
          opacity: 0,
        });
      }

      let duration = steps / fps;
      if (preload) {
        duration = 1;
      }

      let tween = this.gsap.to(element, duration, {
        frame: steps,
        ease: `steps(${steps})`,
        onComplete: function () {
          element.parentElement.removeChild(element);
        },
        modifiers: {
          frame: (i, target) => {
            let sprite = json_arr[Math.round(i)].frame;
            //set background images
            this.gsap.set(target, {
              xPercent: -50,
              yPercent: -50,
              background:
                'url("' +
                require("@/assets/img/animation/firework/" +
                  json_arr[Math.round(i)].image) +
                '")',
            });

            //set posiion and with of sprite
            target.style.backgroundPosition =
              -sprite.x + "px " + -sprite.y + "px";
            target.style.width = sprite.w + "px";
            target.style.height = sprite.h + "px";

            return i;
          },
        },
      });

      if (repeat) {
        tween.restart();
      }

      this.$store.commit("animations/addUnsubscribeEvents", () => {
        tween.kill();
      });
    },
  },
};
</script>

<style lang="scss">
gsap-firework {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.sprite {
  position: absolute;
  width: 240px;
  height: 240px;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.sprite img {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
</style>
