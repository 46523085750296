<template>
  <div>
    <b-modal
      id="modal-winter-buy-pass"
      class="custom-modal3"
      :hide-footer="true"
      modal-class="custom-modal3"
      content-class="rectangular-dark-blue-modal"
      @show="playSoundButtonCommon"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default" slot-scope="{ close }">
        <DecoCorners color-variant="gold" />

        <div class="headline1">
          <i18next :translation="$t('events.winter.premium_activate')">
            <template #0>{{ $t("events.winter.premium_pass") }}</template>
          </i18next>
        </div>
        <div class="text">
          <i18next :translation="$t('winter.popup_buy_quest.message')">
            <template #0>
              {{ amount }}
            </template>
            <template #1>
              {{ $t("events.winter.premium_pass") }}
            </template>
          </i18next>
        </div>

        <div id="button-container-left" class="button-container">
          <button class="cancel-button-red" @click="close()">
            {{ $t("winter.popup_buy_quest.button_2") }}
          </button>
        </div>
        <div id="button-container-right" class="button-container">
          <button class="ok-button-green" @click="$emit('buy-kingspass')">
            {{ $t("winter.popup_buy_quest.button_1") }}
          </button>
        </div>
        <div id="deco-bottom" class="to-center-abs"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DecoCorners from "@/components/base/deco-corners.vue";
import i18next from "i18next";

export default {
  name: "ModalWinterBuyPass",
  components: { DecoCorners },
  props: {
    amount: { type: String, default: "0" },
  },
};
</script>
<style lang="scss">
$modal-width: 640px;
$modal-height: 272px;

#modal-winter-buy-pass {
  .modal-body {
    margin-top: -15px;
  }

  .modal-dialog {
    top: 5%;
    max-width: $modal-width;
    height: $modal-height;
  }

  .deco-corner {
    &.top-left {
      top: -20px;
      left: -5px;
    }
    &.top-right {
      top: -20px;
      right: -5px;
    }
    &.bottom-right {
      bottom: -5px;
      right: -5px;
    }

    &.bottom-left {
      bottom: -5px;
      left: -5px;
    }
  }

  #button-container-left {
    position: absolute;
    left: 15px;
    bottom: 40px;

    button {
      font-family: Ubuntu;
      font-size: 24px;
      width: 288px;
      height: 44px;
    }
  }

  #button-container-right {
    position: absolute;
    right: 15px;
    bottom: 40px;

    button {
      font-family: Ubuntu;
      font-size: 24px;
      width: 288px;
      height: 44px;
    }
  }
}
</style>

<style lang="scss" scoped>
#modal-winter-buy-pass {
  .headline1 {
    font-family: Ubuntu-Bold;
    color: white;
    font-size: 32px;
    text-transform: uppercase;
  }

  .text {
    font-family: Ubuntu;
    font-size: 26px;
    color: #c8c8c8;
    line-height: 33px;
    padding: 0 20px;
    margin-top: 10px;
  }
}
</style>
