const AlertWindowMaker = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        // id and text are required
        // buttons defautls to false`
        // confirmText and cancelText default to "Ok" and "Cancel"
        // url defaults to null
        openAlertWindow(
          id,
          text,
          buttons,
          confirmText,
          cancelText,
          url,
          titleText
        ) {
          this.playSoundNotification();
          this.$store.dispatch("popups/setModalContent", {
            id: id,
            alertText: text,
            twoButtons: buttons,
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            redirectUrl: url,
            titleText: titleText,
          });
        },
      },
    });
  },
};

export default AlertWindowMaker;
