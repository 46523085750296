import { Howl, Howler } from "howler";
import Bowser from "bowser";

const SoundSystem = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          music: {
            musicLobby: require("@/assets/sound/general/atmo-1.wav"),
            musicTreasury: require("@/assets/sound/general/atmo-1.wav"),
            musicMissions: require("@/assets/sound/general/atmo-1.wav"),
            musicKingspath: require("@/assets/sound/general/oh-michi.ogg"),
            musicKingswheel: require("@/assets/sound/general/atmo-3.wav"),
            musicTourney: require("@/assets/sound/general/atmo-4.wav"),
            musicBonusSlotBackground: require("@/assets/sound/bonus-slot/kleinekrone-animations-atmosphere-LasVegasAtmo5Sek.mp3"),
            musicBonusSlotRunning: require("@/assets/sound/bonus-slot/kleinekrone-animations-musicident-bonusslot.mp3"),
          },
        };
      },

      computed: {
        loggedIn: function () {
          return this.$store.getters["auth/isLoggedIn"];
        },
        doHtmlSound: function () {
          const browser = Bowser.getParser(
            window.navigator.userAgent
          ).getBrowser().name;
          switch (browser) {
            case "Safari":
              return false;
            case "Internet Explorer":
              return true;
            case "Chrome":
              return false;
            case "Firefox":
              return false;
            default:
              return false;
          }
        },
      },
      methods: {
        playMusic(src, loop = true, volume = 1) {
          // is logged in?
          if (!this.$store.getters["auth/isLoggedIn"]) {
            return;
          }

          //is sound on?
          const sound_on = this.$store.getters["sound/getMusicPreference"];
          if (!sound_on) {
            return;
          }

          //do nothing if already playing
          if (this.isSoundAlreadyPlaying(src)) {
            return;
          }

          //do not play music in slot game
          if (this.isGameSite()) {
            return;
          }

          this.safariActivate();

          //fade out previous music
          this.fadeOutBackgroundMusic();

          //play new music
          const htmlSound = this.doHtmlSound;
          let sound = new Howl({
            src: src,
            loop: loop,
            volume: volume,
            html5: htmlSound,
          });
          sound.play();
          sound.fade(0, volume, 1000);
        },

        playSoundEffect(src, loop = false, volume = 1) {
          // is logged in?
          if (!this.$store.getters["auth/isLoggedIn"]) {
            return;
          }

          //is sound on?
          const sound_on =
            this.$store.getters["sound/getSoundEffectPreference"];
          if (!sound_on) {
            return;
          }

          // this.safariActivate();

          //play sound
          const htmlSound = this.doHtmlSound;
          let sound = new Howl({
            src: src,
            loop: loop,
            volume: volume,
            html5: htmlSound,
          });
          if (!loop) {
            sound.once("end", (id) => {
              this.stopSoundById(id);
            });
          }

          sound.play();
        },
        stopSound(src) {
          Howler._howls.forEach((howl) => {
            if (howl._src == src) {
              howl.unload();
            }
          });
        },
        stopSoundById(id) {
          Howler._howls.forEach((howl) => {
            if (howl._sounds[0]._id == id) {
              howl.unload();
            }
          });
        },
        fadeInSound(src, loop = false, volume = 1, duration = 1000) {
          //is sound on?
          const sound_on = this.$store.getters["sound/getMusicPreference"];
          if (!sound_on) {
            return;
          }

          let sound = new Howl({
            src: src,
            loop: loop,
            volume: 0,
          });
          sound.play();
          sound.fade(0, volume, duration);
        },
        fadeOutSound(src, duration = 1000) {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];
            if (howl._src == src) {
              howl.fade(howl._volume, 0, duration);
              Howler._howls[i].old = true;

              setTimeout(() => {
                howl.unload();
              }, duration);
            }
          }
        },

        stopAllSounds: function () {
          Howler.unload();
        },

        fadeOutAllSounds: function (duration = 1000) {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];

            howl.fade(howl._volume, 0, duration);
            Howler._howls[i].old = true;
            setTimeout(() => {
              howl.unload();
            }, duration);
          }
        },

        logHowler: function () {
          console.log(Howler._howls);
        },

        stopAllSoundsExceptBackground: function () {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];
            if (!howl) {
              continue;
            }
            let isSoundEffect = true;
            for (let props in this.music) {
              let src = this.music[props];
              if (howl._src == src) {
                isSoundEffect = false;
                break;
              }
            }

            if (isSoundEffect) {
              this.stopSound(howl._src);
            }
          }
        },

        //important to activate safari sounds. plays a mute sound first
        safariActivate: function () {
          let src = require("@/assets/sound/general/sound-1.wav");
          let sound = new Howl({
            src: src,
            loop: false,
            volume: 0,
          });
          sound.once("end", (id) => {
            this.stopSoundById(id);
          });

          sound.play();
        },

        playSoundCardFlip: function () {
          let src = require("@/assets/sound/daily/kleinekrone-cardflip-sound-with-magic.mp3");
          this.playSoundEffect(src);
        },
        playSoundTab: function () {
          let src = require("@/assets/sound/general/sound-1.wav");
          this.playSoundEffect(src, false, 0.5);
        },
        playSoundButtonCommon: function () {
          let src = require("@/assets/sound/general/sound-2.wav");
          this.playSoundEffect(src, false, 0.4);
        },
        playSoundButtonUpcraft: function () {
          let src = require("@/assets/sound/general/sound-3.wav");
          this.playSoundEffect(src, false, 0.6);
        },
        playSoundNotification: function () {
          let src = require("@/assets/sound/general/sound-5.wav");
          this.playSoundEffect(src, false, 0.8);
        },
        playSoundTourneyClick: function () {
          let src = require("@/assets/sound/general/sound-10.wav");
          this.playSoundEffect(src, false, 0.2);
        },
        playSoundNotificationMain: function () {
          let src = require("@/assets/sound/general/sound-11.wav");
          this.playSoundEffect(src, false, 0.4);
        },
        playSoundChatSendReceive: function () {
          let src = require("@/assets/sound/general/sound-12.wav");
          this.playSoundEffect(src, false, 0.8);
        },
        playBackgroundKingswheel: function () {
          this.playMusic(this.music.musicKingswheel, true, 0.4, true);
        },
        fadeOutBackgroundKingswheel: function () {
          this.fadeOutSound(this.music.musicKingswheel);
        },
        playBackgroundBonusSlot: function () {
          this.playMusic(this.music.musicBonusSlotBackground, true, 0.5, true);
        },
        fadeOutBackgroundBonusSlot: function () {
          this.fadeOutSound(this.music.musicBonusSlotBackground);
        },
        playBackgroundBonusSlotRunning: function () {
          this.playMusic(this.music.musicBonusSlotRunning, true, 0.4, true);
        },
        fadeOutBackgroundBonusSlotRunning: function () {
          this.fadeOutSound(this.music.musicBonusSlotRunning);
        },
        playBackgroundTreasury: function () {
          this.playMusic(this.music.musicTreasury, true, 0.3, true);
        },
        fadeOutBackgroundTreasury: function () {
          this.fadeOutSound(this.music.musicTreasury);
        },
        playBackgroundMissions: function () {
          this.playMusic(this.music.musicMissions, true, 0.3, true);
        },
        fadeOutBackgroundMissions: function () {
          this.fadeOutSound(this.music.musicMissions);
        },
        playSoundCardScroll: function () {
          let src = require("@/assets/sound/general/sound-8.wav");
          this.playSoundEffect(src, true, 0.5);
        },
        stopSoundCardScroll: function () {
          let src = require("@/assets/sound/general/sound-8.wav");
          this.stopSound(src);
        },

        isSoundAlreadyPlaying: function (src) {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];
            if (howl._src == src && howl.old != true) {
              return true;
            }
          }

          return false;
        },

        isGameSite: function () {
          if (this.$route.name && this.$route.name.indexOf("game") !== -1) {
            return true;
          }

          return false;
        },

        playBackgroundMusic: function (fadein = false) {
          if (!this.loggedIn) {
            this.stopAllSounds();
            return;
          }

          if (this.isGameSite()) {
            this.fadeOutAllSounds();
            return;
          }

          switch (this.$route.path.substring(0, 6)) {
            case "/kings":
              this.playMusic(this.music.musicKingspath, true, 0.3, fadein);
              break;
            case "/tourn":
              this.playMusic(this.music.musicTourney, true, 0.4, fadein);
              break;
            case "/cards":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/leagu":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/achie":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/loyal":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/teams":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/champ":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/profi":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/games":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            case "/":
              this.playMusic(this.music.musicLobby, true, 0.3, fadein);
              break;
            default:
              this.stopBackgroundMusic();
              break;
          }
        },
        stopBackgroundMusic: function () {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];
            for (let props in this.music) {
              let src = this.music[props];
              if (howl._src == src) {
                this.stopSound(howl._src);
                break;
              }
            }
          }
        },
        fadeOutBackgroundMusic: function (duration = 3500) {
          for (let i = Howler._howls.length - 1; i >= 0; i--) {
            let howl = Howler._howls[i];
            for (let props in this.music) {
              let src = this.music[props];
              if (howl._src == src) {
                this.fadeOutSound(howl._src, duration);
                break;
              }
            }
          }
        },
      },
    });
  },
};

export default SoundSystem;
