<script>
import AnimationEffects from "./animation-effects";
import AnimationFirework from "./animation-firework";
import AnimationBaseFunctions from "./animation-base-functions";
import HtmlRewardShield from "@/components/animation/html/reward-shield.html";
import HtmlRewardLevelUp from "@/components/animation/html/reward-level-up.html";
import HtmlRewardKingspass from "@/components/animation/html/reward-kingspass.html";

export default {
  name: "AnimationFullAccomplishment",
  mixins: [AnimationEffects, AnimationFirework, AnimationBaseFunctions],
  data() {
    return {
      isPipeline: null,
      destroyGsapFullAccomplish: false,
    };
  },
  computed: {
    menus: function () {
      let result = [];
      result.push({
        src: "nav-icon-cards.png",
        level: this.entryLevelCards,
        bannerText: this.$t("lobby.navigation.trading_cards"),
        name: "cards",
      });
      result.push({
        src: "nav-icon-kingspath.png",
        level: this.entryLevelKingspath,
        bannerText: this.$t("lobby.navigation.kingspath"),
        name: "kingspath",
      });
      result.push({
        src: "nav-icon-friends.png",
        level: this.entryLevelFriends,
        bannerText: this.$t("lobby.navigation.friends"),
        name: "battle",
      });
      result.push({
        src: "nav-icon-league.png",
        level: this.entryLevelLeague,
        bannerText: this.$t("lobby.navigation.user_league"),
        name: "league",
      });
      result.push({
        src: "nav-icon-tournaments.png",
        level: this.entryLevelTourney,
        bannerText: this.$t("lobby.navigation.tournaments"),
        name: "tournament",
      });
      result.push({
        src: "nav-icon-achievements.png",
        level: this.entryLevelAchieve,
        bannerText: this.$t("lobby.navigation.achievements.web"),
        name: "achievements",
      });
      result.push({
        src: "nav-icon-loyalty.png",
        level: this.entryLevelLoyal,
        bannerText: this.$t("lobby.navigation.crownloyalty"),
        name: "loyalty",
      });

      return result;
    },
  },

  beforeDestroy() {
    this.destroyGsapFullAccomplish = true;
  },
  methods: {
    // context: array with context and value. e.g.
    // context[0]="kingspath"
    // context[1]=14"
    // context[2]=rewardObj (optional)
    AnimationFullAccomplishmentAnimate: function (
      context,
      callback,
      isInPipeline = false
    ) {
      try {
        if (!this.runningAllowed()) {
          return;
        }

        this.destroyGsapFullAccomplish = false;

        //when true, it commits the next pipeline queue Step at the end of the animation
        this.isPipeline = isInPipeline;

        const queueName =
          "animation-full-accomplishment-" + context[0] + "-" + context[1];
        let extraDelay = 0;

        const animationContainer = this.createAnimationContainer();

        let app = document.getElementById("app");
        let element = document.createElement("gsap-full-accomplishment");
        element.classList.add(context[0]);
        animationContainer.appendChild(element);

        let calcObj;
        calcObj = this.calcGridPosition(1, 0, element);
        let x = calcObj.x;
        let y = calcObj.y;
        let scale_val = calcObj.scale_val;

        let light = document.createElement("img");
        light.classList.add("light");
        light.classList.add("to-center-abs");
        light.src = require("@/assets/img/backgrounds/lobby-glow.png");
        element.appendChild(light);

        let flare = document.createElement("img");
        flare.classList.add("flare");
        flare.classList.add("to-center-abs");
        flare.src = require("@/assets/img/animation/full-reward-earned/reward-red-reflex-web.png");
        element.appendChild(flare);

        let sparkle = document.createElement("img");
        sparkle.classList.add("sparkle");
        sparkle.classList.add("to-center-abs");
        sparkle.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
        element.appendChild(sparkle);

        let sparkle2 = document.createElement("img");
        sparkle2.classList.add("sparkle2");
        sparkle2.classList.add("to-center-abs");
        sparkle2.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
        element.appendChild(sparkle2);

        let sparkle3 = document.createElement("img");
        sparkle3.classList.add("sparkle3");
        sparkle3.classList.add("to-center-abs");
        sparkle3.src = require("@/assets/img/bonus-slot/sparkles@3x.png");
        element.appendChild(sparkle3);

        let bg_shield = document.createElement("img");
        bg_shield.classList.add("bg");
        bg_shield.classList.add("to-center-abs");
        element.appendChild(bg_shield);

        let additional_icon = document.createElement("img");
        additional_icon.classList.add("additional-icon");
        additional_icon.classList.add("to-center-abs");
        element.appendChild(additional_icon);

        let icon2Container = document.createElement("div");
        icon2Container.classList.add("banner-container");
        icon2Container.classList.add("to-center-abs");
        element.appendChild(icon2Container);

        let fireworkContainerChain = document.createElement("div");
        fireworkContainerChain.classList.add("firework-container-chain");
        fireworkContainerChain.classList.add("to-center-abs");
        element.appendChild(fireworkContainerChain);

        let fireworkContainerYellow = document.createElement("div");
        fireworkContainerYellow.classList.add("firework-container-yellow");
        fireworkContainerYellow.classList.add("to-center-abs");
        element.appendChild(fireworkContainerYellow);

        let fireworkContainerBlue = document.createElement("div");
        fireworkContainerBlue.classList.add("firework-container-blue");
        fireworkContainerBlue.classList.add("to-center-abs");
        element.appendChild(fireworkContainerBlue);

        let smokeContainer = document.createElement("div");
        smokeContainer.classList.add("smoke-container");
        smokeContainer.classList.add("to-center-abs");
        element.appendChild(smokeContainer);

        let icon2 = document.createElement("img");
        icon2.classList.add("banner");
        icon2.classList.add("animation-shine-img");
        icon2.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-banner-5-4-x@3x.png");
        icon2Container.appendChild(icon2);

        let context_icon = document.createElement("img");
        context_icon.classList.add("context-icon");
        context_icon.classList.add("to-center-abs");

        let context_icon_level_up = document.createElement("img");
        context_icon_level_up.classList.add("context-icon-level-up");
        context_icon_level_up.classList.add("to-center-abs");

        let badge_level_up = document.createElement("img");
        badge_level_up.classList.add("badge-level-up");
        badge_level_up.classList.add("to-center-abs");
        badge_level_up.src = require("@/assets/img/loyalty/badge-large_sign.png");

        let arrow_icon = document.createElement("img");
        arrow_icon.classList.add("arrow-icon");
        arrow_icon.classList.add("to-center-abs");

        let glow1 = document.createElement("div");
        glow1.classList.add("glow1");
        glow1.classList.add("to-center-abs");
        element.appendChild(glow1);

        let text1 = document.createElement("div");
        text1.classList.add("text1");
        text1.classList.add("to-center-abs");
        element.appendChild(text1);

        let text2 = document.createElement("div");
        text2.classList.add("text2");
        text2.classList.add("to-center-abs");

        let glow2 = document.createElement("div");
        glow2.classList.add("glow-bonus");
        glow2.classList.add("glow2");
        glow2.classList.add("to-center-abs");
        element.appendChild(glow2);

        let glow3 = document.createElement("div");
        glow3.classList.add("glow-bonus");
        glow3.classList.add("glow3");
        glow3.classList.add("to-center-abs");
        element.appendChild(glow3);

        let glow4 = document.createElement("div");
        glow4.classList.add("glow-bonus");
        glow4.classList.add("glow4");
        glow4.classList.add("to-center-abs");
        element.appendChild(glow4);

        let glow5 = document.createElement("div");
        glow5.classList.add("glow-bonus");
        glow5.classList.add("glow5");
        glow5.classList.add("to-center-abs");
        element.appendChild(glow5);

        let glow6 = document.createElement("div");
        glow6.classList.add("glow-bonus");
        glow6.classList.add("glow6");
        glow6.classList.add("to-center-abs");
        element.appendChild(glow6);

        let titletext = document.createElement("div");
        titletext.classList.add("title");
        titletext.classList.add("to-center-abs");
        titletext.classList.add("yellow-text");
        element.appendChild(titletext);

        let sound_gold = require("@/assets/sound/animation/full-reward-earned/gold.wav");
        let sound_level_up = require("@/assets/sound/animation/adelsliga-aufstieg-fanfare.mp3");
        let sound_level_down = require("@/assets/sound/animation/adelsliga-abstieg.mp3");
        let sound_achieve = require("@/assets/sound/animation/ewige-erfolge-fanfare.mp3");
        let sound_small = require("@/assets/sound/animation/full-reward-earned/small.wav");

        //default sound
        let begin_sound = sound_level_up;
        // arrow_icon.src = require("@/assets/img/animation/full-accomplishment/arrow-up-4-x@3x.png");

        let second_firework = false;
        //set individual contents
        if (context) {
          switch (context[0]) {
            case "kingspath":
              context_icon.src = require("@/assets/img/animation/full-accomplishment/kingspath-asset-aufgabegeschafft-4-x@3x.png");
              context_icon.classList.add("context-icon-kingspath");
              text1.innerHTML =
                this.$t("animation.kingspath.levelup.message") +
                ' <br> <span class="big">' +
                this.$t("kingspath.map.quest.level") +
                " " +
                context[1] +
                "</span>";
              titletext.classList.add("smaller");
              titletext.innerHTML = this.$t("kingspath.title");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              break;
            case "levelup":
              extraDelay = 4;

              context_icon_level_up.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-crown@3x.png");
              text1.innerHTML =
                this.$t("animation.levelup.message") +
                '! <br> <span class="big">' +
                this.$t("level") +
                " " +
                context[1] +
                "</span>";
              text1.classList.add("down");
              titletext.innerHTML = context[1];
              titletext.classList.add("title-level-up");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              bg_shield.classList.add("level-up-shield");
              arrow_icon.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-arrow@3x.png");
              arrow_icon.classList.add("level-up-arrow");
              icon2Container.classList.add("level-up-banner-container");
              icon2.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-banner@3x.png");
              element.appendChild(context_icon_level_up);
              element.appendChild(badge_level_up);
              break;
            case "loyalty":
              context_icon.src = require("@/assets/img/animation/full-accomplishment/kronentreue-asset-loyalpbadge-4-x@3x.png");
              context_icon.classList.add("context-icon-loyalty");
              titletext.innerHTML = this.$t("lobby.navigation.crownloyalty");
              titletext.classList.add("smaller");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              text1.innerHTML = this.$t("loyalty.level_up.description", {
                0: context[1],
              });
              break;
            case "adel-ascend":
              extraDelay = 2;
              context_icon.src = require("@/assets/img/animation/full-accomplishment/adelsliga-asset-orden-main-4-x@3x.png");
              context_icon.classList.add("context-icon-adel");
              text1.innerHTML =
                this.$t("user_leagues.animation.levelup.message") +
                ' <br><span class="big">' +
                this.$t(`user_leagues.league.${context[1]}`) +
                "-" +
                this.$t(`user_leagues.league`) +
                "</span>";
              arrow_icon.src = require("@/assets/img/animation/full-accomplishment/arrow-up-4-x@3x.png");
              begin_sound = sound_level_up;
              titletext.innerHTML = this.$t("lobby.navigation.user_league");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              break;
            case "adel-descend":
              extraDelay = 2;
              context_icon.src = require("@/assets/img/animation/full-accomplishment/adelsliga-asset-orden-main-4-x@3x.png");
              context_icon.classList.add("context-icon-adel");
              text1.innerHTML =
                this.$t("user_leagues.animation.leveldown.message") +
                ' <br><span class="big">' +
                this.$t(`user_leagues.league.${context[1]}`) +
                "-" +
                this.$t(`user_leagues.league`) +
                "</span>";
              arrow_icon.src = require("@/assets/img/animation/full-accomplishment/arrow-down-4-x@3x.png");
              begin_sound = sound_level_down;
              titletext.innerHTML = this.$t("lobby.navigation.user_league");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              break;
            case "adel-reward":
              context_icon.src = require("@/assets/img/animation/full-accomplishment/adelsliga-asset-orden-main-4-x@3x.png");
              context_icon.classList.add("context-icon-adel");
              text1.innerHTML = this.$t(
                "user_leagues.animation.rewards.message",
                { 0: context[1] }
              );
              text1.classList.add("smaller");
              arrow_icon.src = "";
              begin_sound = sound_level_up;
              titletext.innerHTML = this.$t("lobby.navigation.user_league");
              element.appendChild(arrow_icon);
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              break;
            case "achieve":
              extraDelay = 4;

              //texts
              let achieveName = this.$t(
                `achievements.achievement_title.${context[1].achievement.slug}`
              );

              const achieveLevel = context[1].level;
              let goalCurrent = 0;
              for (let i = 1; i <= achieveLevel; i++) {
                goalCurrent += parseInt(context[1].achievement.level_steps[i]);
              }
              const achieveCurrentTask = this.getAchievementDescription(
                context[1].achievement,
                goalCurrent
              );
              if (achieveLevel <= 9) {
                let goalFuture = 0;
                for (let i = 1; i <= achieveLevel + 1; i++) {
                  goalFuture += parseInt(context[1].achievement.level_steps[i]);
                }
                const achieveNextTask = this.getAchievementDescription(
                  context[1].achievement,
                  goalFuture
                );
                text2.innerHTML =
                  this.$t("achievements.animation.message.next") +
                  "<br>" +
                  achieveNextTask;
                element.appendChild(text2);
              }

              //extra coin shield
              const goldAmount =
                context[1].achievement.level_rewards[achieveLevel].gold;
              let shieldIconHtml = this.getHtmlRewardShield(goldAmount);
              element.insertAdjacentHTML("beforeend", shieldIconHtml);

              context_icon.src = require("@/assets/img/animation/full-accomplishment/erfolge-assets-pokal-4-x@3x.png");
              context_icon.classList.add("context-icon-achieve");
              titletext.classList.remove("super-headline");
              titletext.classList.add("d-flex");
              titletext.classList.add("align-items-center");
              titletext.classList.add("justify-content-center");

              let titletextspan = document.createElement("span");
              titletextspan.classList.add("yellow-text");
              titletextspan.innerHTML = achieveName;
              titletext.appendChild(titletextspan);

              if (achieveName.length > 15) {
                titletextspan.classList.add("small");
              }

              second_firework = true;
              bg_shield.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-shield-2-x@3x.png");
              bg_shield.classList.add("smaller", "to-center-abs");

              let additionalTextContainer = document.createElement("div");
              additionalTextContainer.classList.add(
                "additional-text-container"
              );
              additionalTextContainer.classList.add("to-center-abs");
              element.appendChild(additionalTextContainer);

              let additionalText = document.createElement("div");
              additionalText.classList.add("additional-text");
              additionalText.classList.add("yellow-text");
              additionalText.innerHTML =
                this.$t("tournaments.achievements.level") + " " + achieveLevel;
              additionalTextContainer.appendChild(additionalText);

              text1.innerHTML =
                this.$t("achievements.animation.message_web") +
                '<br><div class="big3">' +
                achieveCurrentTask +
                "</div>";

              begin_sound = sound_achieve;
              break;
            case "kronenpassLevelUp":
              extraDelay = 4;

              context_icon_level_up.src = require("@/assets/img/animation/full-accomplishment/ticket.png");
              text1.innerHTML = this.$t("events.winter.level_up.headline", {
                0: context[1] + 1,
              });
              if (context[1] > 30) {
                text1.innerHTML = this.$t(
                  "events.winter.level_up.headline_complete"
                );
              }

              text1.classList.add("down");
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              bg_shield.classList.add("level-up-shield");
              arrow_icon.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-arrow@3x.png");
              icon2Container.classList.add("level-up-banner-container");
              icon2.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-banner@3x.png");

              let snowBanner = document.createElement("img");
              snowBanner.classList.add("snow-banner");
              snowBanner.src = require("@/assets/img/animation/full-accomplishment/schnee-banner.png");
              icon2Container.appendChild(snowBanner);

              let snowTop = document.createElement("img");
              snowTop.classList.add("snow-top");
              snowTop.classList.add("to-center-abs");
              snowTop.src = require("@/assets/img/animation/full-accomplishment/schnee-schild.png");
              element.appendChild(snowTop);

              element.appendChild(context_icon_level_up);
              break;
            case "kronenpassTierRewards":
              extraDelay = 4;

              context_icon_level_up.src = require("@/assets/img/animation/full-accomplishment/wbj-icn-kingspass-rewardshow.png");
              bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield-4-x@3x.png");
              bg_shield.classList.add("level-up-shield");
              arrow_icon.src = require("@/assets/img/animation/full-accomplishment/kleinekrone-animation-levelup-arrow@3x.png");
              icon2Container.classList.add("level-up-banner-container");
              icon2.src = require("@/assets/img/animation/full-accomplishment/wbj-getpass-check.png");

              let snowTop2 = document.createElement("img");
              snowTop2.classList.add("snow-top");
              snowTop2.classList.add("to-center-abs");
              snowTop2.src = require("@/assets/img/animation/full-accomplishment/schnee-schild.png");
              element.appendChild(snowTop2);

              element.appendChild(context_icon_level_up);
              break;
            default:
              context_icon.src = require("@/assets/img/animation/full-accomplishment/adelsliga-asset-orden-main-4-x@3x.png");
              break;
          }
          element.appendChild(context_icon);
        }

        //do rewards exist? e.g. for level up

        if (context[2]) {
          let html;
          switch (context[0]) {
            case "levelup":
              html = this.getHtmlLevelUpRewards(context[2], context[1]);
              break;
            case "kronenpassLevelUp":
              html = this.getHtmlKingspassRewards(context[2]);
              break;
            case "kronenpassTierRewards":
              html = this.getHtmlKingspassRewards(context[2]);
              break;
            default:
              html = this.getHtmlLevelUpRewards(context[2], context[1]);
              break;
          }

          element.insertAdjacentHTML("beforeend", html);
          this.showUnlockAnimation(context[1]);
        }

        let rewardContainer = animationContainer.getElementsByClassName(
          "animation-reward-container"
        )[0];

        this.gsap.set(element, {
          x: x,
          y: y,
          opacity: 1,
        });

        const timeline = this.gsap.timeline({
          paused: true,
          onComplete: () => {
            this.removeBackgroundElement();
            if (animationContainer.parentNode) {
              animationContainer.parentNode.removeChild(animationContainer);
            }
            this.removeFromQueue(queueName);
            this.destroyGsapFullAccomplish = true;

            if (this.isPipeline) {
              this.$store.commit("popups/setNextPipelineQueueStep");
            }

            if (callback) {
              callback();
            }
          },
        });
        this.$store.commit("animations/addUnsubscribeEvents", () => {
          timeline.kill();
        });

        animationContainer.onclick = () => {
          if (this.skipCurrentAnimation(timeline, context[0] + context[1])) {
            animationContainer.onclick = null;
          }
        };

        let background_darken;
        //background
        timeline.to(app, 0.01, {
          onStart: () => {
            background_darken = this.createBackgroundElement();
            //show container and dark background
            timeline.to(
              background_darken,
              0,
              {
                opacity: 1,
              },
              0
            );
          },
        });

        //show container and dark background
        timeline.to(
          animationContainer,
          0,
          {
            opacity: 1,
            pointerEvents: "auto",
          },
          0
        );

        //sound begin
        timeline.to(
          app,
          0.01,
          {
            onStart: () => {
              this.playSoundEffect(begin_sound, false, 0.3);
            },
          },
          0
        );

        //sound gold
        timeline.to(
          app,
          0.01,
          {
            onStart: () => {
              this.playSoundEffect(sound_gold, false, 0.5);
            },
          },
          0.5
        );

        //show background light
        const timeline_light = this.gsap.timeline();
        this.$store.commit("animations/addUnsubscribeEvents", () => {
          timeline_light.kill();
        });
        timeline_light.to(
          light,
          30,
          {
            rotation: 360,
            ease: "linear.inOut",
          },
          0
        );

        //pop in container
        timeline.fromTo(
          [element],
          0.8,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: scale_val,
          },
          0
        );

        //smoke
        if (context[0] === "adel-descend") {
          timeline.to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationEffectsAnimateSmoke2(
                  255,
                  -20,
                  false,
                  smokeContainer
                );
              },
            },
            0
          );
          timeline.to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationEffectsAnimateDust(element);
              },
            },
            0
          );
        }

        //yellow firework
        if (context[0] !== "adel-descend") {
          timeline.to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  255,
                  0,
                  false,
                  fireworkContainerYellow
                );
              },
            },
            0.4
          );
        }

        //blue firework
        if (second_firework) {
          timeline.to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkBlue(
                  255,
                  0,
                  false,
                  fireworkContainerBlue
                );
              },
            },
            0.9
          );
        }

        //pop in level text
        timeline.fromTo(
          text1,
          0.5,
          {
            scale: 0,
          },
          {
            onStart: () => {
              if (context[0] === "achieve") {
                this.playSoundEffect(sound_small, false, 0.3);
              }
            },
            ease: "elastic",
            scale: 1,
          },
          1
        );

        if (text2) {
          //pop in sub text
          timeline.fromTo(
            text2,
            1,
            {
              opacity: 0,
            },
            {
              opacity: 1,
            },
            1.5
          );
        }

        //pop in level text
        timeline.fromTo(
          [arrow_icon, context_icon_level_up],
          0.5,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: 1,
          },
          1.4
        );

        //chain firework
        if (context[0] === "adel-ascend") {
          timeline.to(
            arrow_icon,
            0.5,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkBlueChain(
                  235,
                  0,
                  false,
                  fireworkContainerChain
                );
              },
            },
            1.1
          );
        }

        // pop out container.
        timeline.to(
          element,
          0.2,
          {
            ease: "linear.inOut",
            scale: 0,
          },
          3.6 + extraDelay
        );

        if (rewardContainer) {
          // pop out container.
          timeline.to(
            rewardContainer,
            0.2,
            {
              opacity: 0,
            },
            3.6 + extraDelay
          );
        }

        // remove blur
        timeline.to(
          app,
          0.01,
          {
            onStart: () => {
              this.removeBackgroundElement();
            },
          },
          3.6 + extraDelay
        );

        timeline.to(app, 0, {}, 4 + extraDelay);

        //add animation to queue
        this.addToQueue(timeline, queueName);

        //set anchor to skip animation
        timeline.addLabel("end" + context[0] + context[1], "-=0.4");

        //sparkle timeline
        const timeline_spark = this.gsap.timeline({
          onComplete: function () {
            this.restart();
          },
        });
        this.$store.commit("animations/addUnsubscribeEvents", () => {
          timeline_spark.kill();
        });
        timeline_spark
          .fromTo(
            sparkle,
            7 + extraDelay,
            {
              y: 0,
              x: 0,
            },
            {
              y: -60,
              x: 20,
              ease: "linear",
            },
            0
          )
          .fromTo(
            sparkle2,
            7 + extraDelay,
            {
              y: 0,
              x: 0,
            },
            {
              y: -90,
              x: -70,
              ease: "linear",
            },
            0
          )
          .fromTo(
            sparkle3,
            7 + extraDelay,
            {
              y: 0,
              x: 0,
            },
            {
              y: -40,
              x: 50,
              ease: "linear",
            },
            0
          );
      } catch (e) {
        console.error(
          "AnimationFullAccomplishmentAnimate konnte nicht geladen werden!",
          e
        );
        this.skipAnimationFull(this.isPipeline);
      }
    },

    showUnlockAnimation: function (newLevel) {
      for (let i = 0; i < this.menus.length; i++) {
        if (this.menus[i].level === newLevel) {
          const menuName = this.menus[i].name;
          const level = this.menus[i].level;
          setTimeout(() => {
            this.AnimationModeUnlock([menuName, level]);
          }, 500);
        }
      }
    },

    getAchievementDescription(achievement, goal) {
      let result = "";
      if (achievement.slug === "joinleaguex") {
        result = this.$t(
          `achievements.achievement_task.joinleaguex.level_${goal}`
        );
      }
      if (achievement.slug !== "joinleaguex") {
        result = this.$t(
          `achievements.achievement_task.${achievement.slug}.other`,
          {
            0: this.formatNumber(goal),
          }
        );
        if (goal === 1) {
          result = this.$t(
            `achievements.achievement_task.${achievement.slug}.one`,
            {
              0: this.formatNumber(goal),
            }
          );
        }
      }

      return result;
    },

    getHtmlRewardShield: function (amount) {
      let html = HtmlRewardShield;
      html = html
        .replace(
          "%%TEXT1%%",
          this.$t("achievements.animation.message.reward_title")
        )
        .replace("%%AMOUNT%%", this.formatNumber(amount));

      return html;
    },
    getHtmlLevelUpRewards: function (rewards, newLevel) {
      //get unlocked slots
      let slots = [];
      const gameData = this.$store.getters["gameplay/getGames"];
      for (let props in gameData) {
        let game = gameData[props];
        if (game.settings && game.settings.unlock_level === newLevel) {
          slots.push(game.images[0].url);
        }
      }

      let html = HtmlRewardLevelUp;

      //add reward amounts
      for (let i = 0; i < rewards.length; i++) {
        html = html.replace(
          "%%AMOUNT_" + rewards[i].reward.toUpperCase() + "%%",
          rewards[i].amount
        );
        html = html.replace(
          "%%CLASS_" + rewards[i].reward.toUpperCase() + "%%",
          ""
        );
      }

      //hide empty rewards
      const type = ["gold", "crown", "card", "loyalty", "elixir"];
      for (let i = 0; i < type.length; i++) {
        html = html.replace(
          "%%CLASS_" + type[i].toUpperCase() + "%%",
          "d-none"
        );
      }

      //add slots
      if (slots.length > 0) {
        html = html.replace("%%SLOT_URL%%", slots[0]);
      } else {
        html = html.replace("%%SLOT_CLASS%%", "d-none");
        html = html.replace("%%SLOT_URL%%", "#");
      }

      return html;
    },

    getHtmlKingspassRewards: function (rewards) {
      //get unlocked slots
      let html = HtmlRewardKingspass;

      //add reward amounts
      for (let i = 0; i < rewards.length; i++) {
        html = html.replace(
          "%%AMOUNT_" + rewards[i].reward.toUpperCase() + "%%",
          rewards[i].amount
        );
        html = html.replace(
          "%%CLASS_" + rewards[i].reward.toUpperCase() + "%%",
          ""
        );
      }

      //hide empty rewards
      const type = ["gold", "expbooster", "card", "ticket", "elixir"];
      for (let i = 0; i < type.length; i++) {
        html = html.replace(
          "%%CLASS_" + type[i].toUpperCase() + "%%",
          "d-none"
        );
      }

      return html;
    },
  },
};
</script>

<style lang="scss">
gsap-full-accomplishment {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  margin-left: -250px;
  margin-top: -250px;
  width: 500px;
  height: 500px;
  pointer-events: none;
  opacity: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.kingspath {
    .text1 {
      font-size: 40px;
      .big {
        font-size: 55px;
      }
    }
  }

  &.loyalty {
    .text1 {
      font-size: 40px;
      .big {
        font-size: 55px;
      }
    }
  }

  &.levelup {
    top: -10%;
    left: -260px;
    transform-origin: 500px 200px;

    .text1 {
      font-size: 50px;
      width: 1000px;
      top: -17%;
      left: -5%;
      line-height: 1.2;

      .big {
        font-size: 70px;
      }
    }
    .bg {
      width: 100% !important;
    }
    .banner-container {
      top: 60% !important;
    }
    .banner {
      width: 330px !important;
      margin-left: -30px !important;
    }
    .arrow-icon {
      width: 31% !important;
      top: 19% !important;
    }

    .badge-level-up {
      width: 38% !important;
      top: 72% !important;
    }

    .title {
      color: white !important;
      background: none !important;
      text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4 !important;
      font-size: 60px !important;
      top: 77% !important;
      -webkit-text-fill-color: white;
    }

    .firework-container-yellow {
      top: 62%;
      left: 5px;
    }
  }

  &.kronenpassLevelUp {
    top: -10%;
    left: -260px;
    transform-origin: 500px 200px;

    .context-icon-level-up {
      top: 28%;
      left: 0;
      width: 300px;
    }

    .snow-banner {
      position: absolute;
      top: -7px;
      left: 20px;
      width: 240px;
      height: 34px;
      z-index: 30;
    }
    .snow-top {
      position: absolute;
      top: 31.3%;
      left: 0;
      width: 160px;
      height: 28px;
      z-index: 30;
    }

    .text1 {
      font-size: 50px;
      width: 1000px;
      top: -17%;
      left: -5%;
      line-height: 1.2;

      .big {
        font-size: 70px;
      }
    }
    .bg {
      width: 100% !important;
    }
    .banner-container {
      top: 77% !important;
    }
    .banner {
      width: 330px !important;
      margin-left: -30px !important;
    }
    .arrow-icon {
      width: 31% !important;
      top: 19% !important;
    }

    .badge-level-up {
      width: 38% !important;
      top: 72% !important;
    }

    .title {
      color: white !important;
      background: none !important;
      text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4 !important;
      font-size: 60px !important;
      top: 77% !important;
      -webkit-text-fill-color: white;
    }

    .firework-container-yellow {
      top: 62%;
      left: 5px;
    }
  }

  &.kronenpassTierRewards {
    top: -10%;
    left: -260px;
    transform-origin: 500px 200px;

    .context-icon-level-up {
      top: 24%;
      left: -20px;
      width: 250px;
      z-index: 999;
    }

    .snow-banner {
      position: absolute;
      top: -7px;
      left: 20px;
      width: 240px;
      height: 34px;
      z-index: 30;
    }
    .snow-top {
      position: absolute;
      top: 31.3%;
      left: 0;
      width: 160px;
      height: 28px;
      z-index: 30;
    }

    .text1 {
      font-size: 50px;
      width: 1000px;
      top: -17%;
      left: -5%;
      line-height: 1.2;

      .big {
        font-size: 70px;
      }
    }
    .bg {
      width: 100% !important;
    }
    .banner-container {
      top: 84% !important;
    }
    .banner {
      width: 100px !important;
      margin-left: 86px !important;
    }
    .arrow-icon {
      width: 31% !important;
      top: 19% !important;
    }

    .badge-level-up {
      width: 38% !important;
      top: 72% !important;
    }

    .title {
      color: white !important;
      background: none !important;
      text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4 !important;
      font-size: 60px !important;
      top: 77% !important;
      -webkit-text-fill-color: white;
    }

    .firework-container-yellow {
      top: 62%;
      left: 5px;
    }
  }

  &.achieve {
    left: -220px;
    transform-origin: 450px 200px;

    .shieldo-container {
      top: 20%;
      right: -400px;
      position: absolute;
      width: 300px;

      .earning-text {
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        font-family: Ubuntu-Bold;
        text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4;
        text-align: center;
        top: 10px;
        z-index: 25;
        line-height: 1.4;
      }
      .earning-shield {
        width: 225px;
        height: 261px;
        top: 50px;
      }
      .earning-container {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 128px;
        margin: 0 8px 0 8px;
        background: url(~@/assets/img/common/earning-shield-bg.png) center/100%
          100% no-repeat;

        .earning-icon {
          top: 15%;
          width: 100px;
          height: 100px;
          background-image: url(~@/assets/img/common/coin1.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .earning-btn {
          top: 120px;
          width: 300%;
          height: 47px;
          left: -100%;
        }

        .earning-amount {
          font-size: 36px;
        }
      }
    }

    .additional-text-container {
      top: 72%;
      z-index: 100;
      text-align: center;
      width: 210px;
      height: 70px;
      border: 4px solid rgb(8, 22, 38);
      line-height: 1.4;
      background: url(~@/assets/img/animation/full-accomplishment/buttonSearch.png)
        center/100% 100% no-repeat;

      .additional-text {
        font-size: 30px;
        line-height: 1.9;
        font-family: Ubuntu-Bold !important;
        text-transform: uppercase;
      }
    }

    .title {
      line-height: 1;
      height: 89px;
      top: 55.5%;

      span {
        font-family: cinzel-decorative-black;
        font-size: 48px;
        background-repeat-y: repeat;
        background-size: auto 25px;
        white-space: nowrap;

        &.small {
          font-size: 36px;
          white-space: normal;
        }
      }
    }

    .text1 {
      font-size: 34px;
      width: 1000px;
      top: -15%;
      left: -5%;

      .big3 {
        font-size: 25px;
        line-height: 1.2;
      }
    }

    .text2 {
      font-family: Ubuntu-Bold;
      color: #1ae5f4;
      font-size: 26px;
      text-align: center;
      top: 95%;
      z-index: 25;
      line-height: 1.4;
      text-transform: uppercase;

      @media (max-height: 768px) {
        top: 86%;
        font-size: 26px;
        line-height: 1;
      }
    }
  }

  gsap-reward-spin {
    top: 210px;
    left: 250px;
    z-index: 10;
  }

  .bg {
    position: absolute;
    top: 23%;
    left: 0;
    width: 86%;
    z-index: 5;

    &.smaller {
      top: 27%;
      left: -55px;
      width: 60%;
    }

    &.level-up-shield {
      top: 32%;
      left: 0;
      width: 74%;
    }
  }

  .additional-icon {
    position: absolute;
    top: 69%;
    left: 0;
    width: 57%;
    z-index: 35;
  }

  .banner-container {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 20;

    .banner {
      position: inherit;
      width: 500px;
    }

    &.level-up-banner-container {
      top: 55%;
      left: 23%;

      .banner {
        width: 270px;
      }
    }
  }

  .context-icon-kingspath {
    position: absolute;
    top: 6%;
    left: 0;
    width: 52%;
    z-index: 15;
  }
  .context-icon-level-up {
    position: absolute;
    top: 43%;
    left: 100px;
    width: 22%;
    z-index: 15;
  }

  .context-icon-loyalty {
    position: absolute;
    top: 15%;
    left: 0;
    width: 44%;
    z-index: 15;
  }
  .context-icon-adel {
    position: absolute;
    top: 8%;
    left: 0;
    width: 36%;
    z-index: 15;
  }

  .context-icon-achieve {
    position: absolute;
    top: 7%;
    left: 0;
    width: 61%;
    z-index: 15;
  }

  .arrow-icon {
    position: absolute;
    top: 16%;
    left: 190px;
    width: 28%;
    z-index: 25;

    &.level-up-arrow {
      left: 0;
      width: 26%;
      top: 24%;
      z-index: 10;
    }
  }

  .badge-level-up {
    position: absolute;
    top: 68%;
    left: 4px;
    width: 32%;
    z-index: 5;
  }

  .glow1 {
    top: 10%;
  }

  .glow2 {
    top: 40%;
    left: -267px;
  }
  .glow3 {
    top: 40%;
    left: -125px;
  }
  .glow4 {
    top: 40%;
    left: 0;
  }
  .glow5 {
    top: 40%;
    left: 125px;
  }
  .glow6 {
    top: 40%;
    left: 267px;
  }

  .text1 {
    font-family: Ubuntu-Bold;
    color: white;
    text-shadow: 1px 1px 2px #1ae5f4, 0 0 12px rgba(77, 229, 255, 0.7),
      0 0 5px rgba(77, 229, 255, 0.7);
    font-size: 48px;
    text-transform: uppercase;
    text-align: center;
    top: -17%;
    z-index: 25;
    line-height: 1.4;
    width: 120%;
    left: -10%;

    &.smaller {
      font-size: 35px;
    }

    &.down {
      top: -9%;
    }
    &.down2 {
      top: 0;
    }
    &.up {
      top: -21%;
    }
  }

  .big {
    font-size: 60px;
    font-family: Ubuntu-Bold;
    color: white;
    text-shadow: 1px 1px 2px rgba(77, 229, 255, 0.7),
      0 0 25px rgba(77, 229, 255, 0.7), 0 0 5px rgba(77, 229, 255, 0.7);
    text-align: center;
    z-index: 25;
  }

  .big2 {
    font-size: 60px;
    font-family: cinzel-decorative-black;
    color: white;
    text-shadow: 1px 1px 2px rgba(77, 229, 255, 0.7),
      0 0 25px rgba(77, 229, 255, 0.7), 0 0 5px rgba(77, 229, 255, 0.7);
    text-align: center;
    z-index: 25;
  }

  .light {
    position: absolute;
    top: 10%;
    width: 100%;
    z-index: 2;
    opacity: 0.1;
  }

  .flare {
    position: absolute;
    top: -120%;
    width: 300%;
    z-index: 15;
    opacity: 0;
    margin-left: -116.5%;
  }

  .sparkle {
    position: absolute;
    top: 30%;
    width: 40%;
    z-index: 10;
  }

  .sparkle2 {
    position: absolute;
    top: 35%;
    width: 40%;
    z-index: 10;
  }

  .sparkle3 {
    position: absolute;
    top: 35%;
    width: 40%;
    z-index: 10;
  }

  .title {
    top: 57%;
    z-index: 30;
    font-size: 60px;
    text-align: center;
    font-family: cinzel-decorative-black;

    &.title-level-up {
      top: 70.5%;
    }

    &.smaller {
      font-size: 54px;
    }
  }

  .firework-container-chain {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 14;

    .blue-chain {
      z-index: 1;
    }
  }

  .firework-container-yellow {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 5;

    .yellow {
      z-index: 1;
    }
  }

  .firework-container-blue {
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 1;

    .blue {
      z-index: 1;
    }
  }

  .smoke-container {
    position: absolute;
    top: 48%;
    left: 0;
    z-index: 1;

    .smoke2 {
      z-index: 1;
    }
  }

  .level-up-reward-layer {
    position: absolute;
    top: 30px;
    right: -960px;
    width: 600px;
    height: 600px;
    font-family: Ubuntu-Bold;

    .earning-shield-big {
      top: 50px;
      .earning-container {
        position: relative;
        width: 175px;
        height: 201px;
        margin: 0 8px 0 8px;
        background: url(~@/assets/img/common/earning-shield-bg.png) center/100%
          100% no-repeat;

        .earning-icon {
          background-size: 100% 100%;
          background-repeat: no-repeat;

          &.gold-icon {
            top: 12%;
            width: 92px;
            height: 93px;
            background-image: url(~@/assets/img/common/coin1.png);
          }
        }
      }

      .earning-btn {
        top: 122px;
        width: 241px;
        height: 43px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
        border-radius: 50px;

        .earning-amount {
          font-size: 36px;
        }
      }
    }
    .earning-shield-small {
      top: 244px;

      &.small1 {
        left: -320px;
      }

      &.small2 {
        left: 0;
      }

      &.small3 {
        left: 320px;
      }

      .earning-container {
        position: relative;
        width: 141px;
        height: 163px;
        margin: 0 8px 0 8px;
        background: url(~@/assets/img/common/earning-shield-bg.png) center/100%
          100% no-repeat;

        .earning-icon {
          background-size: 100% 100%;
          background-repeat: no-repeat;

          &.crown-icon {
            top: 7%;
            width: 110px;
            height: 85px;
            background-image: url(~@/assets/img/common/crown6.png);
          }
          &.card-icon {
            top: 8%;
            width: 85px;
            height: 85px;
            background-image: url(~@/assets/img/nav-menu/nav-icon-cards.png);
          }
          &.loyalty-icon {
            top: 15%;
            width: 60px;
            height: 60px;
            background-image: url(~@/assets/img/common/loyal-royal-icon.png);
          }
        }
      }

      .earning-btn {
        top: 96px;
        width: 122px;
        height: 36px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));

        .earning-amount {
          font-size: 28px;
        }
      }
    }

    .no-shield {
      top: 350px;
      left: -200px;

      .earning-icon {
        top: 60px;
        left: -140px;
        width: 59px;
        height: 85px;
        background-image: url(~@/assets/img/common/elixir.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 10;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
      }

      .earning-btn {
        top: 96px;
        width: 151px;
        height: 43px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
        border-radius: 50px;

        .earning-amount {
          font-size: 36px;
        }
      }
    }

    .slot-container {
      top: 410px;
      left: 350px;

      .lock-img {
        top: -10px;
        left: -270px;
        width: 86px;
        height: 104px;
        background-image: url(~@/assets/img/common/lock-open.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 10;
      }

      .slot-img {
        width: 137px;
        height: 88px;
        filter: drop-shadow(10px -10px 20px rgba(77, 229, 255, 0.59));
        border: 2px solid #12212b;
      }
    }
  }

  .kingspass-reward-layer {
    position: absolute;
    top: 30px;
    right: -960px;
    width: 600px;
    height: 600px;
    font-family: Ubuntu-Bold;

    .earning-shield-big {
      top: 50px;
      .earning-container {
        position: relative;
        width: 175px;
        height: 201px;
        margin: 0 8px 0 8px;
        background: url(~@/assets/img/common/earning-shield-bg.png) center/100%
          100% no-repeat;

        .earning-icon {
          background-size: 100% 100%;
          background-repeat: no-repeat;

          &.gold-icon {
            top: 12%;
            width: 92px;
            height: 93px;
            background-image: url(~@/assets/img/common/coin1.png);
          }
        }
      }

      .earning-btn {
        top: 122px;
        width: 241px;
        height: 43px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
        border-radius: 50px;

        .earning-amount {
          font-size: 36px;
          padding-bottom: 2px;
        }
      }
    }
    .earning-shield-small {
      top: 244px;

      &.small1 {
        left: -320px;
      }

      &.small2 {
        left: 0;
      }

      &.small3 {
        left: 320px;
      }

      .earning-container {
        position: relative;
        width: 141px;
        height: 163px;
        margin: 0 8px 0 8px;
        background: url(~@/assets/img/common/earning-shield-bg.png) center/100%
          100% no-repeat;

        .earning-icon {
          background-size: 100% 100%;
          background-repeat: no-repeat;

          &.expbooster-icon {
            top: 13%;
            width: 80px;
            height: 73px;
            background-image: url(~@/assets/img/common/booster.png);
          }
          &.card-icon {
            top: 8%;
            width: 85px;
            height: 85px;
            background-image: url(~@/assets/img/nav-menu/nav-icon-cards.png);
          }
          &.elixir-icon {
            top: 15%;
            width: 59px;
            height: 85px;
            background-image: url(~@/assets/img/common/elixir.png);
          }
        }
      }

      .earning-btn {
        top: 96px;
        width: 122px;
        height: 36px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));

        .earning-amount {
          font-size: 22px;
          padding-bottom: 2px;
        }
      }
    }

    .no-shield {
      top: 350px;
      left: -200px;

      .earning-icon {
        top: 76px;
        left: -120px;
        width: 68px;
        height: 68px;
        background-image: url(~@/assets/img/animation/full-accomplishment/ticket.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 10;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
      }

      .earning-btn {
        top: 96px;
        width: 151px;
        height: 43px;
        filter: drop-shadow(0 2px 6px rgba(77, 229, 255, 0.59));
        border-radius: 50px;

        .earning-amount {
          font-size: 30px;
        }
      }
    }
  }
}

@media (max-height: 768px) {
  gsap-full-accomplishment {
    top: 5%;

    .text1 {
      font-size: 34px;
      margin-top: 20px;
    }
  }
}
</style>
