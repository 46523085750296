<template>
  <b-modal
    id="modal-winter-real-rewards"
    ref="modal-winter-real-rewards"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div class="preload"></div>
      <div class="w-100 h-100 bg"></div>
      <div id="page2">
        <div class="logo">
          <div class="main"></div>
          <div class="bonus" :class="$store.state.locale"></div>
        </div>

        <div class="headline-white head-badge">
          {{ $t("events.winter.manual.page2.headline") }}
        </div>

        <div class="sub-head headline-sub-white">
          {{ $t("events.winter.manual.page2.sub-head") }}
        </div>

        <div class="price2">
          <div class="text1">1 x</div>
          <div class="text2">
            {{ $t("events.winter.manual.page2.shell.text1") }}
          </div>
          <!--            <div class="text3">-->
          <!--              {{ $t("events.winter.manual.page2.shell.text2") }}-->
          <!--            </div>-->
        </div>

        <div class="price3">
          <div class="text1">2 x</div>
          <div class="text2">
            {{ $t("events.winter.manual.page2.amazon.text1") }}
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";

export default {
  name: "ModalWinterRealRewards",
  components: {},
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],

  data() {
    return {
      modalId: "modal-winter-real-rewards",
      maxPages: 5,
    };
  },

  mounted() {
    // this.$bvModal.show("modal-winter-real-rewards");
  },
  methods: {
    onClose() {
      this.$store.commit("popups/setNextPipelineQueueStep");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 822px;
$modal-height: 535px;

#modal-winter-real-rewards {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png);
  }

  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 80px;

    @media (max-height: 650px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
      margin-bottom: -20%;
    }
  }

  .modal-body {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      background: url(~@/assets/img/events/winter-bonus-jagd/manual-bg-main.png)
        center/100% 100% no-repeat;
    }
  }

  .modal-header {
    .close-button {
      right: -15px;
      top: 20px;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    line-height: 1.2;

    .icn-badge {
      display: inline-block;
      position: relative;
      left: 7px;
      top: -22px;

      .icn-badge-1 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-1.png)
          right/100% 100% no-repeat;
        position: absolute;
      }

      .icn-badge-2 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-2.png)
          right/100% 100% no-repeat;
        position: absolute;
      }
    }

    .bottom-menu {
      top: 50%;
      width: 77%;

      a {
        font-family: Ubuntu-Bold;
        font-size: 22px;
        color: #8e8e8e;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        top: -35px;
        width: 68px;
        height: 68px;
        cursor: pointer;

        &.left {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          left: -120px;
          transform: scaleX(-1);
        }

        &.right {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          right: -120px;
        }
      }
    }

    .logo {
      z-index: 30;
      .main {
        position: absolute;
        left: 13px;
        top: 0;
        z-index: 20;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
          right/100% 100% no-repeat;
      }

      .bonus {
        position: absolute;
        left: 0;
        top: 84px;
        z-index: 21;

        &.en {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
            right/100% 100% no-repeat;
        }
        &.de {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
            right/100% 100% no-repeat;
        }
      }
    }

    .headline-white {
      font-family: Ubuntu-Bold;
      font-size: 36px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.3;
    }

    .headline-sub-white {
      font-family: Ubuntu-Bold;
      font-size: 26px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-sub-white2 {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-gold {
      font-family: Ubuntu-Bold;
      font-size: 33px;
      color: white;
      text-transform: uppercase;
      line-height: 1.3;
      background-image: linear-gradient(
        to bottom,
        #fef7bb,
        #f9bc19 55%,
        #da8408 70%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      //IE10
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ */
        & {
          background: transparent;
          color: rgb(253, 242, 141);
        }
      }

      // Safari (7.1+)
      _::-webkit-full-page-media,
      _:future,
      :root & {
        -webkit-text-fill-color: #ffe824 !important;
      }
    }

    .normal-white {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      line-height: 1.2;
    }

    .normal-white-small {
      font-family: Ubuntu-Medium;
      font-size: 20px;
      color: white;
      line-height: 1.2;
    }

    .highlight {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: #fabe1e;
    }

    #page2 {
      .logo {
        position: absolute;
        left: -60px;
        top: -90px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .head-badge {
        position: absolute;
        right: 60px;
        top: -25px;
        width: 454px;
        height: 70px;
        background: linear-gradient(to bottom, #4de5ff, #050d1c 57%),
          linear-gradient(to bottom, #8b5a00, #f7ffff 41%, #ffee51 75%, #8b5a00);
        border: double 2px transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 25.2px;
        line-height: 70px;
      }

      .sub-head {
        position: absolute;
        right: 75px;
        top: 75px;
        width: 450px;
        text-align: left;
      }

      .price2 {
        width: 128px;
        height: 128px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-badge-amazon-voucher.png)
          right/100% 100% no-repeat;
        position: absolute;
        text-align: center;
        color: white;
        padding: 18px 0 0 0;
        z-index: 10;
        left: 369px;
        top: 157px;

        .text1 {
          font-family: Ubuntu-bold;
          font-size: 27px;
        }
        .text2 {
          font-family: Ubuntu-bold;
          font-size: 13px;
          text-transform: uppercase;
          padding: 0 30px;
          line-height: 1;
        }
        .text3 {
          font-family: Ubuntu-Medium;
          font-size: 11px;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }

      .price3 {
        width: 128px;
        height: 128px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-badge-amazon-voucher.png)
          right/100% 100% no-repeat;
        position: absolute;
        text-align: center;
        color: white;
        padding: 18px 0 0 0;
        z-index: 10;
        left: 613px;
        top: 178px;

        .text1 {
          font-family: Ubuntu-bold;
          font-size: 27px;
        }
        .text2 {
          font-family: Ubuntu-bold;
          font-size: 13px;
          text-transform: uppercase;
          padding: 0 30px;
          line-height: 1;
        }
        .text3 {
          font-family: Ubuntu-Medium;
          font-size: 11px;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
