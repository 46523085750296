<script>
import AnimationBaseFunctions from "./animation-base-functions";

export default {
  name: "AnimationShining",
  mixins: [AnimationBaseFunctions],
  methods: {
    //size: 1,2,3;   direction:left, right
    AnimationShiningAnimate: function (
      parent,
      size,
      direction = "left",
      repeatTime = 0
    ) {
      let elements = parent.getElementsByClassName("animation-shine-img");

      for (let i = elements.length - 1; i >= 0; i--) {
        let src = elements[i].getAttribute("src");
        let elementWidth = elements[i].offsetWidth;
        let elementHeight = elements[i].offsetHeight;
        let parent = elements[i].parentElement;
        let mask;
        if (!parent.classList.contains("animation-shine-img-container")) {
          //add parent container
          let container = document.createElement("div");
          container.classList.add("animation-shine-img-container");
          container.style.width = elementWidth + "px";
          container.style.height = elementHeight + "px";
          parent.append(container);
          container.append(elements[i]);

          //add mask
          mask = document.createElement("div");
          mask.classList.add("animation-shine-img-mask");
          mask.setAttribute("style", '-webkit-mask-image: url("' + src + '")');
          container.append(mask);

          //add svg
          let svg = document.createElement("svg");
          let att = document.createAttribute("height");
          att.value = elementHeight;
          svg.setAttributeNode(att);
          att = document.createAttribute("viewbox");
          att.value = "0 0 500 300";
          svg.setAttributeNode(att);
          container.appendChild(svg);

          //add svg content
          let defs = document.createElement("defs");
          svg.appendChild(defs);
          let svgmask = document.createElement("mask");
          svgmask.id = "mask-firefox";
          defs.appendChild(svgmask);
          let svgimage = document.createElement("image");
          att = document.createAttribute("width");
          att.value = elementWidth;
          svgimage.setAttributeNode(att);
          att = document.createAttribute("height");
          att.value = elementHeight;
          svgimage.setAttributeNode(att);
          att = document.createAttribute("xlink:href");
          att.value = src;
          svgimage.setAttributeNode(att);
          att = document.createAttribute("filter");
          att.value = "url(#filter)";
          svgimage.setAttributeNode(att);
          svgmask.appendChild(svgimage);

          let filter = document.createElement("filter");
          filter.id = "filter";
          svg.appendChild(filter);
          let feFlood = document.createElement("feFlood");
          att = document.createAttribute("flood-color");
          att.value = "white";
          feFlood.setAttributeNode(att);
          filter.appendChild(feFlood);
          let feComposite = document.createElement("feComposite");
          att = document.createAttribute("in2");
          att.value = "SourceAlpha";
          feComposite.setAttributeNode(att);
          att = document.createAttribute("operator");
          att.value = "in";
          feComposite.setAttributeNode(att);
          filter.appendChild(feComposite);
        }

        if (mask === undefined) {
          mask = parent.getElementsByClassName("animation-shine-img-mask")[0];
        }

        this.gsap.fromTo(
          mask,
          0,
          {
            opacity: 0,
          },
          {
            opacity: 1,
          }
        );

        if (direction !== "left") {
          this.gsap.fromTo(
            mask,
            3,
            {
              backgroundPosition: "-" + elementWidth + "px 0px",
            },
            {
              backgroundPosition: "" + elementWidth + "px 0px",
            }
          );
        } else {
          this.gsap
            .fromTo(
              mask,
              3,
              {
                backgroundPosition: elementWidth + "px 0px",
              },
              {
                backgroundPosition: "-" + elementWidth + "px 0px",
              }
            )
            .restart(repeatTime);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.animation-shine-img-container {
  position: relative;
  display: block;
  text-align: center;

  svg {
    position: absolute;
    height: 500px;
    width: 300px;
  }
}

.animation-shine-img-mask {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  background-image: radial-gradient(
    circle farthest-side at 0 0,
    rgba(255, 255, 255, 0) 90%,
    rgba(255, 255, 255, 0.8) 98%,
    rgba(255, 255, 255, 0) 100%
  );
  background-position: 0 0;
  background-repeat: no-repeat;
  mask-image: url("#mask-firefox");
  -webkit-mask-size: cover;
}
</style>
