import store from "@/store/store.js";
import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchAdyenPaymentMethods = (offer_id, locale) =>
  axios
    .post(
      `/api/payment/methods`,
      {
        offer_package_id: offer_id,
        locale: locale,
      },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const sendAdyenPayment = (offer_id, payment_method, return_url) =>
  axios
    .post(
      "/api/payment/request",
      {
        offer_package_id: offer_id,
        payment_method: payment_method,
        return_url: return_url,
      },
      optionsFunction()
    )
    .then((res) => res.data.data)
    .catch((e) => e);

export const sendAdyenPaymentDetails = (
  offer_id,
  transaction_reference,
  details,
  payment_data
) =>
  axios
    .post(
      "/api/payment/request/details",
      {
        offer_package_id: offer_id,
        transaction_reference: transaction_reference,
        details: details,
        payment_data: payment_data,
      },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const fetchPaypalPaymentCapture = (offer_package_id, order_id) =>
  axios
    .post(
      "/api/payment/paypal/capture",
      {
        offer_package_id: offer_package_id,
        order_id: order_id,
      },
      optionsFunction()
    )
    .then((res) => {
      // console.log("RESPONSE", res);
      return res;
    })
    .then((res) => res.data.data);

export const fetchAllPaymentOffers = () =>
  axios
    .post(`/api/payment/offers/list`, {}, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersByOfferType = (type) =>
  axios
    .post(
      `/api/payment/offers/getByType`,
      { offer_type: type },
      optionsFunction()
    )
    .then((res) => res.data.data);

export const fetchPaymentOffersByOfferId = (id) =>
  axios
    .post(`/api/payment/offers/getById`, { offer_id: id }, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersLobby = () =>
  axios
    .post(`/api/payment/offers/overview`, {}, optionsFunction())
    .then((res) => res.data.data);

export const fetchPaymentOffersRandom = () =>
  axios
    .post(`/api/payment/offers/random`, {}, optionsFunction())
    .then((res) => res.data.data);
