<template>
  <main
    id="fallback-page"
    class="h-100 d-flex flex-column justify-content-center"
  >
    <div id="fallback-container" class="container-fluid">
      <b-row align-h="center">
        <b-col id="kk-logo" class="d-flex flex-column align-items-center">
          <img
            id="crown"
            draggable="false"
            alt="Krone"
            src="@/assets/img/common/crown6-big.png" />

          <img
            draggable="false"
            class="logo-title1"
            alt="Kleine"
            src="@/assets/img/icons/title-kleine.png" />
          <img
            draggable="false"
            class="logo-title2"
            alt="Krone"
            src="@/assets/img/icons/title-krone.png" /></b-col
      ></b-row>
      <b-row
        id="button-row"
        align-v="center"
        align-h="center"
        class="mx-auto mt-3"
        ><b-col cols="10" sm="6" class="badge-col"
          ><a
            class="d-flex justify-content-center"
            href="https://play.google.com/store/apps/details?id=com.szentertainment.kleinekrone"
            ><img
              v-if="$store.state.locale === 'de'"
              draggable="false"
              class="badge-img google"
              alt="Jetzt bei Google Play"
              src="@/assets/img/buttons/google-play-de.png" /><img
              v-else
              class="badge-img google"
              draggable="false"
              alt="Get it on Google Play"
              src="@/assets/img/buttons/google-play-en.png" /></a></b-col
        ><b-col cols="10" sm="6" class="badge-col"
          ><a
            class="d-flex justify-content-center"
            href="http://itunes.apple.com/app/id1471196187"
            ><img
              v-if="$store.state.locale === 'de'"
              draggable="false"
              class="badge-img apple"
              alt="Laden im App Store"
              src="@/assets/img/buttons/app-store-de.svg" /><img
              v-else
              class="badge-img apple"
              draggable="false"
              alt="Download on the App Store"
              src="@/assets/img/buttons/app-store-en.svg" /></a></b-col
      ></b-row>
    </div>
  </main>
</template>

<script>
export default {
  name: "FallBackPage",
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    this.winWidth();
    this.$store.commit("setIsPageLoading", false);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    winWidth: function () {
      this.intervalId = setInterval(() => {
        let w = window.innerWidth;
        if (w >= 1024) {
          this.$router.go(-1);
        } else return;
      }, 100);
    },
  },
};
</script>
<style scoped lang="scss">
$logo-width: 649px;
#fallback-page {
  #kk-logo {
    max-width: $logo-width;
    max-height: 400px;
    .logo-title1 {
      width: 100%;
      height: 100%;
      max-width: 561px;
      max-height: 117px;
    }
    .logo-title2 {
      width: 100%;
      height: 100%;
      max-width: 549px;
      max-height: 114px;
    }
    #crown {
      width: 100%;
      height: 100%;
      max-width: 201px;
      max-height: 156px;
      align-self: flex-end;
    }
  }
  #button-row {
    max-width: $logo-width;
    .badge-col {
      .badge-img {
        &.apple {
          width: 80%;
          height: 100%;
        }
        &.google {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
