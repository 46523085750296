import firebaseConfig from "../components/notification/firebase-config";
export default {
  data() {
    return {
      needsRefresh: false,
    };
  },

  watch: {
    needsRefresh: function (value) {
      console.log("NEEDS REFRESH!!!", value);
      if (value) {
        setTimeout(() => {
          this.$router.go(0);
        }, 500);
      }
    },
  },

  mounted() {
    this.needsRefresh = false;
  },

  created() {
    if (this.$store.getters["auth/isLoggedIn"]) {
      this.loadScriptsExternal();
    }

    if (this.isCookieStatistics) {
      this.loadScriptsStatistics();
    }

    if (this.isCookieMarketing) {
      this.loadScriptsMarketing();
    } else {
      this.removeMarketingCookies();
    }

    this.$root.$on("cookie-prefs-updated", this.handlePreferenceChange);
  },

  methods: {
    loadScriptsExternal: function () {},

    loadScriptsStatistics: function () {
      this.loadFirebaseScrips();
    },
    removeScriptsStatistics: function () {
      this.removeFirebaseScrips();
    },
    loadScriptsMarketing: function () {
      this.loadGoogleTagScripts();
      this.loadHotjarScripts();
    },
    removeScriptsMarketing: function () {
      this.removeGoogleTagScripts();
      this.removeHotjarScripts();
    },

    loadGoogleTagScripts: function () {
      if (process.env.NODE_ENV !== "production") {
        return;
      }
      //check if script is already there
      let script = document.getElementById("google-tag-script");
      if (script) {
        return;
      }

      ((w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        j.id = "google-tag-script";
        j.onload = () => {
          this.$store.commit("externalScripts/setGoogleTagScriptLoaded", true);
          console.log("google tag script geladen");
        };
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-NQPS75Q");
    },

    loadHotjarScripts: function () {
      if (process.env.NODE_ENV !== "production") {
        return;
      }

      //check if script is already there
      let script = document.getElementById("hotjar-script");
      if (script) {
        return;
      }

      script = document.createElement("script");
      script.setAttribute("src", "/assets/js/hotjar/init.js");
      script.async = false;
      script.id = "hotjar-script";
      document.head.appendChild(script);
      script.onload = () => {
        this.$store.commit("externalScripts/setHotjarScriptLoaded", true);
        console.log("Hotjar Script eingebunden");
      };
    },

    removeGoogleTagScripts: function () {
      let scriptsActive = false;
      const scripts = ["google-tag-script"];
      for (let i = 0; i < scripts.length; i++) {
        let script = document.getElementById(scripts[i]);
        if (script) {
          script.parentElement.removeChild(script);
          scriptsActive = true;
        }
      }
      this.$store.commit("externalScripts/setGoogleTagScriptLoaded", false);
      console.log("removed Google Tag Scripts");

      if (scriptsActive) {
        this.removeMarketingCookies();
      }
    },

    removeHotjarScripts: function () {
      let scriptsActive = false;
      const scripts = ["hotjar-script"];
      for (let i = 0; i < scripts.length; i++) {
        let script = document.getElementById(scripts[i]);
        if (script) {
          script.parentElement.removeChild(script);
          scriptsActive = true;
        }
      }
      this.$store.commit("externalScripts/setHotjarScriptLoaded", false);
      console.log("removed Hotjar Scripts");

      if (scriptsActive) {
        this.removeMarketingCookies();
      }
    },

    removeMarketingCookies: function () {
      const cookies = this.$cookies.keys();
      for (let i = 0; i < cookies.length; i++) {
        if (
          cookies[i].indexOf("_ga") !== -1 ||
          cookies[i].indexOf("_gid") !== -1 ||
          cookies[i].indexOf("_gcl") !== -1 ||
          cookies[i].indexOf("_hj") !== -1 ||
          cookies[i].indexOf("_cid") !== -1 ||
          cookies[i].indexOf("_uet") !== -1
        ) {
          this.$cookies.remove(cookies[i]);
          this.needsRefresh = true;
        }
      }
      console.log("removed Google Tag Cookies");
    },

    loadFirebaseScrips: function () {
      if (process.env.NODE_ENV !== "production") {
        return;
      }
      //check if script is already there
      let script = document.getElementById("firebase-script");
      if (script) {
        return;
      }

      let script1 = document.createElement("script");
      script1.setAttribute(
        "src",
        "https://www.gstatic.com/firebasejs/7.17.2/firebase-app.js"
      );
      script1.id = "firebase-script";
      document.head.appendChild(script1);
      script1.onload = () => {
        let script2 = document.createElement("script");
        script2.setAttribute(
          "src",
          "https://www.gstatic.com/firebasejs/7.17.2/firebase-analytics.js"
        );
        script2.id = "firebase-analytics";
        document.head.appendChild(script2);
        script2.onload = () => {
          firebase.initializeApp(firebaseConfig);

          this.$store.commit("externalScripts/setFirebaseScriptLoaded", true);

          //only write firebase events when possible to prevent firebase errors.
          const db = indexedDB.open("test");
          //test is indexdDB is available. not available in browser private modes.
          db.onsuccess = () => {
            console.log("FIREBASE eingebunden");
            this.$store.commit("externalScripts/setIndexedDBWriteable", true);
          };
          db.onerror = () => {
            console.log(
              "Firebase konnte nicht geladen werden. Browser Private Mode?"
            );
            this.$store.commit("externalScripts/setIndexedDBWriteable", false);
          };
        };
      };
    },

    removeFirebaseScrips: function () {
      let scriptsActive = false;
      const scripts = ["firebase-script", "firebase-analytics"];
      for (let i = 0; i < scripts.length; i++) {
        let script = document.getElementById(scripts[i]);
        if (script) {
          script.parentElement.removeChild(script);
          scriptsActive = true;
        }
      }
      this.$store.commit("externalScripts/setFirebaseScriptLoaded", false);
      console.log("removed Firebase Scripts");

      if (scriptsActive) {
        this.removeFirebaseCookies();
      }
    },

    removeFirebaseCookies: function () {
      const cookies = this.$cookies.keys();
      for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].indexOf("_g") !== -1) {
          this.$cookies.remove(cookies[i]);
          this.needsRefresh = true;
        }
      }
      console.log("removed Firebase Cookies");
    },
    handlePreferenceChange() {
      if (this.isCookieStatistics) {
        this.loadScriptsStatistics();
      } else {
        this.removeScriptsStatistics();
      }
      if (this.isCookieMarketing) {
        this.loadScriptsMarketing();
      } else {
        this.removeScriptsMarketing();
      }
    },
  },
  computed: {
    isCookieStatistics: function () {
      return this.$store.getters["cookieManager/isTypeAccepted"]("statistics");
    },
    isCookieMarketing: function () {
      return this.$store.getters["cookieManager/isTypeAccepted"]("marketing");
    },
  },
};
