<template>
  <div
    v-if="rowData"
    class="game-row-container position-relative"
    :class="{
      double: rowData.arrangement.startsWith('2_ROWS'),
      special: rowData.arrangement === '1_ROW_5_GAMES',
    }"
  >
    <div
      class="game-row-title white-shadow position-relative w-100 d-inline-flex align-items-center justify-content-between"
    >
      <span class="text-uppercase medium text-white position-relative">
        {{ rowTitle }}
        <span class="icn-row" :class="rowType"></span>
      </span>

      <button v-if="showShowAllButton" class="rect-btn px-3 text-uppercase">
        <router-link :to="`/games/${rowData.slug}`">
          {{ $t("lobby.game_row.show_all") }}</router-link
        >
      </button>
    </div>

    <div class="game-row position-relative" :style="rowStyle">
      <GamePreviewItem
        v-for="(game, index) in shownGames"
        :key="game.id"
        :game-index="index"
        :game="game"
        :kingspath-on-game="kingspathOnGame"
        :active-tournament-game-ids="activeTournamentGameIds"
        :upcoming-tournament-game-ids="upcomingTournamentGameIds"
        :grid-view="false"
        :row-index="rowIndex"
        :grid-arrangement="gridArrangement"
        :row-type="rowType"
      />
      <template v-if="isPlaceholderRow">
        <GamePreviewItem
          v-for="(game, index) in randomGames"
          :key="game.id"
          :game-index="index"
          :game="game"
          :kingspath-on-game="kingspathOnGame"
          :active-tournament-game-ids="activeTournamentGameIds"
          :upcoming-tournament-game-ids="upcomingTournamentGameIds"
          :grid-view="false"
          :row-index="rowIndex"
          :grid-arrangement="gridArrangement"
          :row-type="rowType"
          :is-placeholder="true"
        />
      </template>
    </div>
  </div>
  <div v-else class="game-row-container">
    <div class="game-row" :style="rowStyle">
      <GamePreviewSkeleton v-for="(n, index) in 4" :key="index" />
    </div>
  </div>
</template>
<script>
import GamePreviewItem from "@/components/lobby/game-preview-item.vue";
import GamePreviewSkeleton from "@/components/lobby/game-preview-skeleton.vue";
export default {
  name: "GameRow",
  components: { GamePreviewItem, GamePreviewSkeleton },

  props: {
    rowIndex: {
      type: Number,
      default: 0,
    },

    rowData: {
      type: Object,
      default: null,
    },
    userCanPlayTournaments: {
      type: Boolean,
      default: false,
    },
    kingspathOnGame: {
      type: String,
      required: false,
      default: "",
    },
    activeTournamentGameIds: {
      type: Array,
      required: false,
      default: null,
    },
    upcomingTournamentGameIds: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      startIndex: 0,
    };
  },

  computed: {
    rowTitle() {
      if (!this.rowData) return "";
      switch (this.rowType) {
        case "publisher_games":
          return this.rowData.details.publisher.name;
          break;
        case "tag_games":
          return this.rowData.name;
          break;

        default:
          return this.$t(`lobby.layout_model_type.${this.rowData.type}`);
      }
    },
    shownGames() {
      let endIndex = this.startIndex + this.gamesPerRow;
      let games = this.rowData.details.games;
      if (Array.isArray(games)) {
        return games.slice(this.startIndex, endIndex);
      } else return Object.values(games).slice(this.startIndex, endIndex);
    },
    gridArrangement() {
      if (
        this.gamesPerRow > this.rowData.details.games.length &&
        this.rowType !== "user_fave_games" &&
        this.rowType !== "last_played"
      ) {
        return this.rowData.arrangement_advice;
      } else {
        return this.rowData.arrangement;
      }
    },

    rowStyle() {
      if (!this.rowData) return null;
      let numberOfRows = 1;

      let autoColumns = "296px";
      let rowHeight = "231px";
      let templateColumns = "";
      let rowGap = "";
      if (this.$store.state.windowWidth < 1430) {
        autoColumns = "";
        templateColumns = "repeat(4, 1fr)";
        rowHeight = "188px";
        rowGap = "40px";
      }

      if (this.gridArrangement === "1_ROW_5_GAMES") {
        autoColumns = "233px";
        numberOfRows = 1;
        rowHeight = "394px";
        if (this.$store.state.windowWidth < 1430) {
          // rowGap = "";
          rowHeight = "325px";
          templateColumns = "repeat(5, 1fr)";
        }
        if (this.$store.state.windowWidth < 1100) {
          autoColumns = "";
          templateColumns = "repeat(5, 18%)";
          rowHeight = "225px";
        }
      } else if (this.gridArrangement.startsWith("2_ROWS")) {
        numberOfRows = 2;
      }

      return {
        "grid-template-rows": `repeat(${numberOfRows}, ${rowHeight})`,
        "grid-auto-columns": autoColumns,
        "grid-template-columns": templateColumns,
        "grid-row-gap": rowGap,
      };
    },
    gamesPerRow() {
      if (
        this.rowData.arrangement === "2_ROWS_1_FEATURE_WIDE" ||
        this.rowData.arrangement === "1_ROW_5_GAMES" ||
        this.rowData.arrangement === "2_ROWS_1_FEATURE_HIGH"
      ) {
        return 5;
      } else if (
        this.rowData.arrangement === "2_ROWS_2_FEATURES_WIDE" ||
        this.rowData.arrangement === "2_ROWS_2_FEATURES_HIGH"
      ) {
        return 6;
      } else if (this.rowData.arrangement.endsWith("2_FEATURES")) {
        return 2;
      } else if (this.rowData.arrangement.endsWith("FEATURE_COMPLETE")) {
        return 1;
      } else if (this.rowData.arrangement === "2_ROWS") {
        return 8;
      } else {
        return 4;
      }
    },
    showShowAllButton() {
      return (
        this.rowData.slug !== "top-10-games" &&
        this.rowData.slug !== "top-5-games" &&
        this.rowData.details.games.length > this.gamesPerRow
      );
    },

    rowType() {
      return this.rowData.type.toLowerCase();
    },

    isPlaceholderRow() {
      const placeholderRows = ["user_fave_games", "last_played_user"];
      return placeholderRows.includes(this.rowType);
    },

    placeholderLimit() {
      if (this.rowType === "user_fave_games") {
        return 4;
      } else if (this.rowType === "last_played_user") {
        return 1;
      } else {
        return 0;
      }
    },

    // fill low filled rows with random game placeholder
    randomGames() {
      let result = [];

      const gameCount = this.rowData.details.games.length;
      if (
        gameCount &&
        this.rowData.details.games.length < this.placeholderLimit
      ) {
        for (let i = gameCount; i < this.placeholderLimit; i++) {
          result.push(
            this.allGames[Math.floor(Math.random() * this.allGames.length)]
          );
        }
      }

      return result;
    },

    allGames() {
      return this.$store.state.gameplay.games;
    },
  },
};
</script>
<style lang="scss">
.game-row-container {
  margin-bottom: 26px;
  .game-row-title {
    padding-left: 24px;
    padding-right: 24px;

    span {
      font-size: 32px;
    }
  }

  .game-row {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    overflow: visible;
    grid-gap: 16px;
    padding: 14px 24px 24px 24px;
    background-image: url(~@/assets/img/backgrounds/slotrow-bg-default.svg);
    @media (max-width: 1340px) {
      padding: 14px 24px 40px 24px;
    }
  }
  .rect-btn {
    background-image: linear-gradient(
      rgba(22, 63, 81, 0.5),
      rgba(5, 13, 28, 0.5)
    );
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      filter: none;
    }
    a {
      font-size: 14px;
      font-family: Ubuntu-medium;
      color: #5499a4;
      &:hover {
        text-decoration: none;
        color: white;
        text-shadow: 0 0 8px rgba(77, 229, 255, 0.8);
      }
    }
  }

  .icn-row {
    position: absolute;
    right: -50px;
    left: auto;
    margin: auto;
    top: 0;
    bottom: 0;

    &.top_10_games {
      background: url(~@/assets/img/lobby/icn-top-10-stars-small.png)
        center/100% 100% no-repeat;
      width: 50px;
      height: 23px;
      right: -60px;
    }
  }
}
</style>
