const getDefaultState = () => {
  return {
    kingspathNeedUpdate: false,
    kingwheelNeedUpdate: false,
    kingspathMissionUpdate: null, //changed kingspath mission
    kingspathRewardsCompleted: null,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setKingspathNeedUpdate(state, payload) {
    state.kingspathNeedUpdate = payload;
  },
  setKingwheelNeedUpdate(state, payload) {
    state.kingwheelNeedUpdate = payload;
  },
  setKingspathMissionUpdate(state, payload) {
    state.kingspathMissionUpdate = payload;
  },
  setKingspathRewardsCompleted(state, payload) {
    state.kingspathRewardsCompleted = payload;
  },
};

const getters = {
  getKingspathNeedUpdate: (state) => state.kingspathNeedUpdate,
  getKingwheelNeedUpdate: (state) => state.kingwheelNeedUpdate,
  getKingspathMissionUpdate: (state) => state.kingspathMissionUpdate,
  getKingspathRewardsCompleted: (state) => state.kingspathRewardsCompleted,
};
const actions = {
  resetKingspathState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
