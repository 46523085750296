import * as ChatAPI from "@/API/chat.js";

const getDefaultState = () => {
  return {
    displayedMessages: [],
    cardWishTime: 0,
    latestCardGift: null,
    onlineTeamMembers: [],
    unreadMessages: 0,
    chatHistoryFetched: false,
  };
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDisplayedMessages(state, messageArray) {
    state.displayedMessages = messageArray;
  },
  setListOfOnlineTeamMembers(state, array) {
    state.onlineTeamMembers = array;
  },
  addTeamMemberToList(state, user) {
    state.onlineTeamMembers.push(user);
  },
  removeTeamMemberFromList(state, user) {
    let index = state.onlineTeamMembers.findIndex(
      (member) => member.id === user.id
    );
    if (index === -1) {
      return;
    } else {
      state.onlineTeamMembers.splice(index, 1);
    }
  },

  addMessageToChat(state, payload) {
    let lastMessage =
      state.displayedMessages[state.displayedMessages.length - 1];
    if (payload.message !== lastMessage) {
      state.displayedMessages.push(payload.message);
      if (payload.route !== "/friends/chat") {
        state.unreadMessages += 1;
      }
    } else {
      return;
    }
  },
  setCardWishTime(state, time) {
    state.cardWishTime = time;
  },
  setLatestCardGift(state, gift) {
    state.latestCardGift = gift;
  },
  setUnreadMessages: (state, number) => (state.unreadMessages = number),
  markChatHistoryAsFetched: (state) => (state.chatHistoryFetched = true),
};

const getters = {
  cardWishActive: (state) => {
    let now = Date.now();
    return state.cardWishTime - now <= 0;
  },
};

const actions = {
  resetChatState({ commit }) {
    commit("resetState");
  },
  fetchChatHistory({ commit, rootGetters }) {
    ChatAPI.getRecentMessages()
      .then((res) => {
        let messageArray = res.data.data.messages;
        let unreadMessages = messageArray.filter(
          (message) => message.sender.id !== rootGetters["user/currentUser"].id
        );
        commit("setDisplayedMessages", messageArray.reverse());
        commit("setUnreadMessages", unreadMessages.length);
        commit("markChatHistoryAsFetched");
      })
      .catch((e) => {
        commit("setDisplayedMessages", []);
        console.log(e);
      });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
