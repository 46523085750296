<template>
  <div>
    <Modal20Percent v-if="$store.getters['popups/showManualPopup']" />
  </div>
</template>

<script>
import * as notificationAPI from "@/API/notifications";
import * as tournamentAPI from "@/API/tournament.js";
import AnimationBaseFunctions from "@/components/animation/animation-base-functions";
import AnimationFullAccomplishment from "@/components/animation/animation-full-accomplishment";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import * as rewardsAPI from "@/API/rewards.js";
import eventPopups from "@/mixins/eventPopups.js";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import firebaseConfig from "@/components/notification/firebase-config";
import Modal20Percent from "@/components/shop/offers/modal-20-percent.vue";

export default {
  name: "Notification",
  components: { Modal20Percent },
  mixins: [
    AnimationBaseFunctions,
    AnimationFullAccomplishment,
    AnimationParsingFunctions,
    eventPopups,
  ],

  data() {
    return {
      loading: false,
      error: false,
      pipeline: [],
      notificationIndex: 0,
    };
  },

  computed: {
    user: function () {
      return this.$store.getters["user/currentUser"];
    },
    manualPopupEnabled() {
      return (
        this.$store.getters["popups/showManualPopup"] &&
        this.$store.state.popups.manualPopup
      );
    },
  },

  watch: {
    notificationIndex() {
      // display next notification after first notification has been fired
      if (this.notificationIndex > 0) {
        this.startPipeline();
      }
    },
  },
  mounted() {
    if (isSupported && this.pushMessagesUnlocked) {
      this.setUpFirebaseMessaging();
    }
    this.setUpPipeline();
  },

  methods: {
    setUpFirebaseMessaging() {
      initializeApp(firebaseConfig);
      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          const md5 = require("md5");
          const device = md5(window.navigator.userAgent);

          if (currentToken) {
            if (
              this.$store.state.firebaseClientToken !== currentToken ||
              this.$store.state.firebaseClientToken === ""
            ) {
              this.$store.commit("setFirebaseClientToken", currentToken);
              notificationAPI.postFirebaseMessagingClientToken(
                "web",
                currentToken,
                device
              );
            }
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    },
    setUpPipeline() {
      // Popups that need to be enabled and disabled manually and are shown once per user session

      if (this.manualPopupEnabled) {
        this.pipeline.push({ notificationType: "modal", name: "manual-popup" });
      }

      // fetch all possible notifications
      // bundle them in one pipeline and start it once all have been fetched
      Promise.all([
        this.addOnlineStatusListener(),
        this.checkNotifications(),
        this.checkFriendRequests(),
        this.checkNewsletterRewards(),
      ]).then(() => this.startPipeline());
    },
    checkFriendRequests() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("user/checkFriendRequests")
          .then((res) => {
            if (res[0]) {
              this.pipeline.push({
                notificationType: "alert",
                name: "friend-request-alert",
                payload: {
                  alertHeading: this.$t(
                    "friends.request_friend.new_request.alert.title"
                  ),
                  alertText: this.$t(
                    "friends.request_friend.new_request.alert.message",
                    { 0: res[0].user.name }
                  ),
                  type: "friend",
                  variant: "success",
                  dismissMessage: "ok-friend-alert",
                },
              });
            }
          })
          .catch((e) => console.log(e));
        resolve();
      });
    },
    checkNewsletterRewards() {
      return new Promise((resolve) => {
        rewardsAPI
          .checkRewards()
          .then((res) => {
            let newsletterRewards = res.data.data.collectables;
            if (newsletterRewards.length > 0) {
              rewardsAPI
                .collectRewards()
                .then(() => {
                  this.pipeline.push({
                    notificationType: "alert",
                    name: "newsletter-reward-event",
                    payload: {
                      alertHeading: this.$t(
                        "alerts.newsletter_rewards_received.title"
                      ),
                      otherAlertContent: newsletterRewards,
                      variant: "success",
                      type: "newsletter",
                      dismissMessage: "ok-newsletter-alert",
                    },
                  });
                })
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
        resolve();
      });
    },

    addOnlineStatusListener: function () {
      return new Promise((resolve) => {
        window.addEventListener("offline", () => {
          this.playSoundNotificationMain();
          this.$store.commit("popups/setAlertBannerContent", {
            alertHeading: this.$t("alerts.title.error"),
            alertText: this.$t("general.offline_error"),
            variant: "danger",
            type: "danger",
          });
        });
        resolve();
      });
    },

    checkNotifications() {
      // fetch offline notifications from server and
      // put them into this components notification pipeline
      return new Promise((resolve) => {
        notificationAPI
          .fetchNotifications()
          .then((res) => {
            const notifications = res.data.data.notifications;

            for (let i = 0; i < notifications.length; i++) {
              let data = notifications[i].payload;
              let type = notifications[i].payload_type;
              //if (type.indexOf("LeagueRewardEvent") !== -1) {
              // console.log(data);
              // this.pipeline.push({
              //   type: "alert",
              //   name: "league-reward-login",
              //   text: this.$t("dailylogin.notification.bonus_cards")
              // });
              //}
              if (
                type.indexOf("UserLeagueChangeEvent") !== -1 &&
                this.popupsUnlocked
              ) {
                this.pipeline.push({
                  notificationType: "animation",
                  name: "league-change",
                  data: data,
                });
              }
              if (type.indexOf("UserFriendEvent") !== -1) {
                if (data.status === "ACCEPTED") {
                  this.pipeline.push({
                    notificationType: "alert",
                    name: "friend-event",
                    payload: {
                      alertText: this.$t(
                        "popup_friend_request_accepted.message",
                        {
                          0: data.friend.name,
                        }
                      ),
                      alertHeading: this.$t("alerts.title.congrats"),
                      type: "friend",
                      variant: "success",
                      dismissMessage: "ok-friend-event",
                    },
                  });
                }
              }
              if (
                type.indexOf("UserAchievementEvent") !== -1 &&
                this.popupsUnlocked
              ) {
                this.pipeline.push({
                  notificationType: "animation",
                  name: "achievement-earned",
                  data: data,
                });
              }
              // if (type.indexOf("UserJoinTeamEvent") !== -1) {
              //   this.pipeline.push({
              //     type: "alert",
              //     name: "team-join",
              //     text: this.$t("popup_team_join_level_reached.message")
              //   });
              // }

              if (type.indexOf("UserTournamentEvent") !== -1) {
                this.pipeline.push({
                  notificationType: "modal",
                  name: "tournament-end",
                  data: data,
                });
              }
            }
          })
          .catch((e) => console.log(e));
        resolve();
      });
    },
    startPipeline() {
      let firstNotification = this.pipeline[this.notificationIndex];
      if (!firstNotification) return;
      // handle notifications differently depending on notification type
      if (firstNotification.notificationType === "alert") {
        this.$store.commit(
          "popups/setAlertBannerContent",
          firstNotification.payload
        );
        this.$root.$on(firstNotification.payload.dismissMessage, () => {
          // proceed to next element in pipeline after alert window is hidden
          this.notificationIndex += 1;
        });
      } else if (firstNotification.notificationType === "modal") {
        if (firstNotification.name === "manual-popup") {
          let manualModalId = this.$store.state.popups.manualPopup;
          this.$bvModal.show(manualModalId);
          this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
            if (modalId === manualModalId) {
              // proceed to next element in pipeline after modal is closed
              this.notificationIndex += 1;
            }
          });
        } else if (firstNotification.name === "tournament-end") {
          tournamentAPI.fetchHistoryTournament().then((res) => {
            const tournament_id = firstNotification.data.tournament_history_id;
            let tournaments = res.list;
            let result;
            for (let i = 0; i < tournaments.length; i++) {
              if (tournaments[i].tournament_history_id === tournament_id) {
                result = tournaments[i];
                result["gold_amount"] = firstNotification.data["gold_amount"];
                result["crown_amount"] = firstNotification.data["crown_amount"];
                result["loyalty_amount"] =
                  firstNotification.data["loyalty_amount"];
                break;
              }
            }

            this.$store.dispatch("tournaments/updateTournamentWin", result);
            this.$bvModal.show("modal-tournament-win" + result.id);

            this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
              if (modalId === "modal-tournament-win" + result.id) {
                // proceed to next element in pipeline after modal is closed
                this.notificationIndex += 1;
              }
            });
          });
        } else {
          this.$bvModal.show(firstNotification.name);
          this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
            if (modalId === firstNotification.name) {
              this.notificationIndex += 1;
            }
          });
        }
      } else if (firstNotification.notificationType === "animation") {
        if (firstNotification.name === "levelup") {
          this.AnimationFullAccomplishmentAnimate([
            "levelup",
            firstNotification.data.newLevel,
            firstNotification.data.rewards,
          ]);
        } else if (firstNotification.name === "league-change") {
          this.mapUserLeagueChange(firstNotification.data);
        } else if (firstNotification.name === "achievement-earned") {
          const old_lvl = firstNotification.data.original.level;
          const new_lvl = firstNotification.data.level;
          if (new_lvl > old_lvl) {
            const achievName = this.$t(
              `achievements.achievement_title.${firstNotification.data.achievement.slug}`
            );
            this.AnimationFullAccomplishmentAnimate([
              "achieve",
              achievName,
              new_lvl,
            ]);
          }
        }
        // proceed to next notification in pipeline once animation has finished
        if (!this.$store.getters["popups/getQueueAnimationIsRunning"]) {
          this.notificationIndex += 1;
        }
      }
    },
  },
};
</script>
