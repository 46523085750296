<template>
  <div
    class="favorite-badge"
    :title="[
      isGameFavorite ? $t('favorite.title.remove') : $t('favorite.title.add'),
    ]"
  >
    <div
      class="favorite bg-img clickable"
      :class="[isGameFavorite ? 'active' : '', loading ? 'pulsating' : '']"
      @click="handleFavoriteClick"
    ></div>
  </div>
</template>
<script>
import {
  getUserFavoriteGames,
  markGameAsFavorite,
  removeGameFromFavorites,
} from "@/API/games.js";

export default {
  name: "FavoriteBadge",
  components: {},
  props: {
    gameId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isGameFavorite() {
      return (
        this.$store.state.gameplay.userFavoriteGames.findIndex(
          (game) => game.id === this.gameId
        ) >= 0
      );
    },
  },

  mounted() {},

  methods: {
    handleFavoriteClick() {
      this.playSoundTab();
      this.loading = true;
      if (!this.isGameFavorite) {
        markGameAsFavorite(this.gameId)
          .then(() =>
            getUserFavoriteGames()
              .then((res) => {
                this.$store.commit(
                  "gameplay/setUserFavoriteGames",
                  res.data.data.games
                );
                this.$store.dispatch("gameplay/fetchLobbyLayout");
              })
              .catch((e) => console.log(e))
              .finally(() => (this.loading = false))
          )
          .catch((e) => console.log(e));
      } else {
        removeGameFromFavorites(this.gameId)
          .then(() =>
            getUserFavoriteGames()
              .then((res) => {
                this.$store.commit(
                  "gameplay/setUserFavoriteGames",
                  res.data.data.games
                );
                this.$store.dispatch("gameplay/fetchLobbyLayout");
              })
              .catch((e) => console.log(e))
              .finally(() => (this.loading = false))
          )
          .catch((e) => console.log(e));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.favorite-badge {
  .favorite {
    width: 24px;
    height: 24px;
    background-image: url(~@/assets/img/slot/icn-favorite-default.svg);
    &.active {
      background-image: url(~@/assets/img/slot/icn-favorite-active.svg);
    }
    &.pulsating {
      animation-name: pulse;
      animation-duration: 0.5s;
      animation-timing-function: ease-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-play-state: running;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
    @keyframes pulse {
      0% {
        transform: scale(0.5);
      }

      100% {
        transform: scale(1.3);
      }
    }
  }
}
</style>
