<template>
  <b-row id="legal-row" align-v="end" align-h="center"
    ><b-col cols="8">
      <b-nav align="center">
        <b-nav-item :to="{ path: '/tac' }">{{ $t("legal.tac") }}</b-nav-item
        ><b-nav-item :to="{ path: '/privacy' }" class="mx-3">{{
          $t("legal.privacy")
        }}</b-nav-item
        ><b-nav-item :to="{ path: '/imprint' }">{{
          $t("legal.imprint")
        }}</b-nav-item
        ><b-nav-item to="#" @click="$bvModal.show('cookie-modal')">{{
          $t("legal.cookie_settings")
        }}</b-nav-item></b-nav
      ></b-col
    ><b-col cols="12" class="text-center mt-3"
      ><span class="legal-text"
        >© Glücksfaktor 7 GmbH 2019-{{ currentYear }}</span
      ></b-col
    ><b-col cols="12" class="text-center"
      ><p class="legal-text mt-3">
        {{ $t("legal.disclaimer") }}
      </p>
      <SeoRow />

      <div class="legal-description text-normal">
        <div class="legal-headline">
          {{ $t("legal.description.title") }}
        </div>

        <div class="question">
          {{ $t("legal.description.question1") }}
        </div>
        <div class="answer">
          {{ $t("legal.description.answer1") }}
        </div>
        <div class="question">
          {{ $t("legal.description.question2") }}
        </div>
        <div class="answer">
          {{ $t("legal.description.answer2") }}
        </div>
        <div class="question">
          {{ $t("legal.description.question3") }}
        </div>
        <div class="answer">
          {{ $t("legal.description.answer3") }}
        </div>
        <div class="question">
          {{ $t("legal.description.question4") }}
        </div>
        <div class="answer">
          {{ $t("legal.description.answer4") }}
        </div>
        <div class="question">
          {{ $t("legal.description.question5") }}
        </div>
        <div class="answer">
          {{ $t("legal.description.answer5") }}
        </div>
      </div> </b-col
    ><CookieModal
  /></b-row>
</template>
<script>
import CookieModal from "@/components/legal/cookie-modal.vue";
import Cookies from "vue-cookies";
import SeoRow from "@/components/seo/seo-row";
export default {
  name: "LegalRow",
  components: { SeoRow, CookieModal },
  computed: {
    currentYear() {
      return new Date().getUTCFullYear();
    },
  },

  mounted() {
    if (
      !this.$store.getters["cookieManager/isCookieSet"]("kk-prefs") &&
      !Cookies.get("kk-cookie-prefs")
    ) {
      this.$bvModal.show("cookie-modal");
    }
  },
};
</script>
<style lang="scss">
#legal-row {
  text-align: center;
  font-size: 16px;

  a {
    color: grey;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
      filter: $hover-shadow;
    }
  }
  .legal-text {
    font-size: 13px;
    color: #718096;
    text-transform: uppercase;
    padding-left: 10%;
    padding-right: 10%;
  }

  .legal-description {
    margin-top: 30px;
    padding-bottom: 50px;
    padding-left: 10%;
    padding-right: 10%;

    .legal-headline {
      font-size: 18px;
      color: white;
      text-shadow: 0 0 13px rgb(77 229 255 / 60%), 0 0 6px rgb(77 229 255 / 60%),
        0 0 3px rgb(0 94 110 / 80%);
    }

    .question {
      margin-top: 20px;
      font-family: Ubuntu-Bold;
      font-size: 18px;
      color: white;
      text-shadow: 0 0 13px rgb(77 229 255 / 60%), 0 0 6px rgb(77 229 255 / 60%),
        0 0 3px rgb(0 94 110 / 80%);
    }

    .answer {
      font-family: Ubuntu-medium;
      font-size: 14px;
      color: white;
    }
  }
}
</style>
