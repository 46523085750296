import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchNotifications = () =>
  axios
    .get(`/api/notifications`, optionsFunction())
    .then((res) => res)
    .catch((e) => console.log(e));

export const fetchNotificationsByUserId = (id) =>
  axios
    .get(`/api/notifications?user=${id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const deleteNotificationsById = (id) =>
  axios
    .delete(`/api/notifications?user=${id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const postFirebaseMessagingClientToken = (platform, token, device) =>
  axios
    .post(
      `/api/mobile/firebase/${platform}/${token}?device=${device}`,
      {},
      optionsFunction()
    )
    .then((res) => console.log(res))
    .catch((e) => console.log(e));
