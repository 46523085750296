<template>
  <div class="position-relative h-100 w-100">
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Das hat geklappt!
    </div>
    <div
      class="modal-prompt w-50 h-50 text-center text-xl dead-center bold text-white"
    >
      <p>Schön, dass du bei uns bleibst!</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToSPageOptIn2",
};
</script>
