<template>
  <div id="final-opt-out-screen" class="position-relative h-100">
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Auf Wiedersehen
    </div>
    <div class="modal-prompt">
      <div class="text-center">
        <p>
          Klicke auf “Account löschen", um deinen Account zeitnah löschen zu
          lassen. Diese Aktion ist unwiderruflich.
        </p>
        <p>
          Wir wünschen dir alles Gute, <br />
          dein Meine Krone Team
        </p>
      </div>
    </div>
    <div
      id="button-row"
      class="to-center-abs d-inline-flex align-items-center justify-content-center"
    >
      <button
        id="final-reject-btn"
        type="button"
        class="ok-button-royal red bold p-0 d-inline-flex align-items-center justify-content-center"
        @click="$emit('reject')"
      >
        Account löschen
      </button>
      <button
        id="changed-my-mind"
        type="button"
        class="ok-button-royal bold p-0 d-inline-flex align-items-center justify-content-center"
        @click="$emit('back')"
      >
        Ich bleibe
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToSPageOptOut2",
};
</script>
<style lang="scss">
#button-row {
  bottom: -16px;
}
</style>
