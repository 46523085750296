import * as profileAPI from "@/API/profile.js";

export default {
  data() {
    return {
      stats: {},
    };
  },
  methods: {
    fetchPublicUser(id) {
      this.playSoundTab();
      profileAPI
        .fetchUserProfile(id)
        .then((res) => {
          this.$store.commit("user/setUserSelectedProfile", res.data.data);
        })
        .catch((e) => console.log(e));
    },
    getUserStats(id) {
      profileAPI
        .fetchUserProfile(id)
        .then((res) => (this.stats = res.data.data))
        .catch((e) => console.log(e));
    },
  },
};
