import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchGames = () =>
  axios.get("/api/games", optionsFunction()).catch((e) => console.log(e));
export const fetchRandomGame = () =>
  axios.get("/api/games/random", optionsFunction());

export const fetchGameStats = () =>
  axios.get("/api/games/stats", optionsFunction());

export const fetchGameInfo = (gameId) =>
  axios.get(`/api/games/${gameId}`, optionsFunction());

export const getLobbyLayout = () =>
  axios.get(`/api/lobby/layout?platform=web`, optionsFunction());

export const getSlider = () =>
  axios.get(`/api/lobby/slider?platform=web`, optionsFunction());

export const getGamesForRow = (slug) =>
  axios.get(`/api/lobby/model/games?slug=${slug}`, optionsFunction());

export const markGameAsFavorite = (id) =>
  axios.put(`/api/games/like/${id}`, {}, optionsFunction());

export const removeGameFromFavorites = (id) =>
  axios.delete(`/api/games/unlike/${id}`, optionsFunction());

export const getUserFavoriteGames = () =>
  axios.get(`/api/games/likes/list`, optionsFunction());

export const getGamesByTag = (tag) =>
  axios.get(`/api/games/tag/list?tag_id=${tag}`, optionsFunction());
