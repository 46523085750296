import { optionsFunction } from "./_config";
import axios from "axios";

export const getRecentMessages = () =>
  axios.get(`/api/chat/team`, optionsFunction());

export const getPredefinedMessages = () =>
  axios.get("api/chat/messages", optionsFunction());

export const postMessage = (id) =>
  axios.post(`/api/chat/team/${id}`, {}, optionsFunction());
