import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchNextMatchday = () =>
  axios.get("api/leagues/user/matchday", optionsFunction());

export const fetchAllLeagues = () =>
  axios.get("api/leagues/user", optionsFunction());

export const fetchUserLeagueById = (id) =>
  axios.get(`api/leagues/user/${id}`, optionsFunction());

export const fetchAllLeagueRewards = () =>
  axios.get(`api/leagues/rewards/`, optionsFunction());

export const fetchUserRewards = () =>
  axios.get(`api/leagues/user/reward`, optionsFunction());

export const fetchLeagueMembers = (userId, leagueId, page) =>
  axios.get(
    `api/leagues/user/league/${userId}/${leagueId}?page=${page}&perPage=50`,
    optionsFunction()
  );

export const fetchTopLeagueMembers = (userId, leagueId) =>
  axios.get(
    `api/leagues/user/league/${userId}/${leagueId}?perPage=50`,
    optionsFunction()
  );
