<script>
import AnimationBaseFunctions from "./animation-base-functions";
import gsap from "gsap";

export default {
  name: "AnimationNumberIncrease",
  mixins: [AnimationBaseFunctions],
  methods: {
    AnimationNumberIncreaseAnimate: function (
      element,
      startNumber,
      targetNumber,
      duration,
      leading_sign,
      sound,
      isTime,
      callback
    ) {
      if (typeof startNumber === "string") {
        startNumber = parseInt(startNumber.split(".").join(""), 10);
      }
      if (typeof targetNumber === "string") {
        targetNumber = parseInt(targetNumber.split(".").join(""), 10);
      }

      let obj = { value: startNumber };

      //leading sign
      let leadS = "";
      if (leading_sign) {
        leadS = leading_sign;
      }

      let currentVal = 0;
      let oldVal = 0;
      let tween = gsap.to(obj, duration, {
        value: targetNumber,
        ease: "none",
        onUpdate: () => {
          currentVal = parseInt(obj.value.toFixed(0));

          if (currentVal !== oldVal) {
            oldVal = currentVal;

            if (isTime) {
              currentVal = this.secondsToTime(currentVal);
            } else {
              currentVal = currentVal
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }

            element.innerHTML = leadS + "" + currentVal;

            if (currentVal > 0 && sound && targetNumber - startNumber <= 30) {
              this.playSoundEffect(sound, false, 0.4);
            }
          }
        },
        onComplete: () => {
          if (currentVal > 0 && sound && targetNumber - startNumber > 30) {
            this.playSoundEffect(sound, false, 0.4);
          }
          if (callback) {
            callback();
          }
        },
      });
      return tween;
    },
    secondsToTime: function (sec) {
      let date = new Date(0);
      date.setSeconds(sec);
      date = date.getTime();

      let hours = Math.floor((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((date % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((date % (1000 * 60)) / 1000);
      hours = ("0" + hours).substr(-2, 2);
      minutes = ("0" + minutes).substr(-2, 2);
      seconds = ("0" + seconds).substr(-2, 2);

      if (hours === "00") {
        return minutes + ":" + seconds;
      }

      return hours + ":" + minutes + ":" + seconds;
    },

    timeToSeconds: function (time) {
      time = time.replace("+", "");
      time = time.trim();

      if (time.length > 5) {
        let hours = parseInt(time.substr(0, 2));
        let minutes = parseInt(time.substr(3, 2));
        let seconds = parseInt(time.substr(6, 2));
        return seconds + minutes * 60 + hours * 3600;
      } else {
        let minutes = parseInt(time.substr(0, 2));
        let seconds = parseInt(time.substr(3, 2));
        return seconds + minutes * 60;
      }
    },
  },
};
</script>
