<template>
  <div>
    <component :is="pipeComponentName" />
  </div>
</template>

<script>
import notification from "@/components/notification/notification.vue";
import PipeDailyLogin from "@/components/pipeline/pipe-daily-login";
import PipeEvent from "@/components/pipeline/pipe-event";
import PipePass from "@/components/pipeline/pipe-pass";
import PipeLobbyOffer from "@/components/pipeline/pipe-lobby-offer";
import PipeNews from "@/components/pipeline/pipe-news";
import PipeEmailConfirm from "@/components/pipeline/pipe-email-confirm.vue";
import PipeNewsletterConfirm from "@/components/pipeline/pipe-newsletter-confirm.vue";
import PipeNotifications from "@/components/pipeline/pipe-notifications";
import PipeWelcome from "@/components/pipeline/pipe-welcome";
import AnimationBaseFunctions from "@/components/animation/animation-base-functions";
import PipeToSConfirm from "./pipe-tos-confirm.vue";

export default {
  name: "Pipeline",
  components: {
    PipeNews,
    PipeNotifications,
    PipeLobbyOffer,
    PipeEvent,
    PipePass,
    PipeDailyLogin,
    PipeToSConfirm,
    notification,
    PipeEmailConfirm,
    PipeWelcome,
    PipeNewsletterConfirm,
  },
  mixins: [AnimationBaseFunctions],
  beforeRouteUpdate(to, from) {
    if (from.name === "GameGrid") {
      return;
    }

    if (to.name === "home") {
      this.playLobbyOnly = true;
      this.currentStepIndex = 0;
      this.activatePipe();
    }
  },
  data() {
    return {
      loading: false,
      currentStepIndex: 0,
      pipeComponentName: "",
      playLobbyOnly: false,
      steps: [
        { name: "ToSConfirm", lobbyOnly: false, showOnRedirectToLobby: true },
        { name: "Welcome", lobbyOnly: true, showOnRedirectToLobby: true },
        { name: "DailyLogin", lobbyOnly: false, showOnRedirectToLobby: false },
        { name: "EmailConfirm", lobbyOnly: true, showOnRedirectToLobby: true },
        {
          name: "NewsletterConfirm",
          lobbyOnly: true,
          showOnRedirectToLobby: true,
        },
        { name: "LobbyOffer", lobbyOnly: true, showOnRedirectToLobby: true },
        { name: "Event", lobbyOnly: false, showOnRedirectToLobby: true },
        { name: "Pass", lobbyOnly: false, showOnRedirectToLobby: true },
        { name: "News", lobbyOnly: false, showOnRedirectToLobby: true },

        {
          name: "Notifications",
          lobbyOnly: false,
          showOnRedirectToLobby: false,
        },
      ],
      intervalId: "",
    };
  },

  computed: {
    queueStep() {
      return this.$store.getters["popups/getPipelineQueueStep"];
    },
    routeName() {
      return this.$route.name;
    },
    termsId() {
      return this.$store.state.termsIdToAccept;
    },
  },

  watch: {
    //step in queue increased. activate next pipe
    queueStep: {
      handler: function (data) {
        if (data && this.currentStepIndex < this.steps.length - 1) {
          this.currentStepIndex++;
          this.activatePipe();
        } else {
          this.playLobbyOnly = false;
        }
      },
    },
    termsId: {
      handler: function (data) {
        if (data) {
          window.location.reload();
        }
      },
    },

    //activate pipelines when redirect back to lobby
    routeName: {
      handler: function (data) {
        if (data) {
          if (data === "home") {
            this.playLobbyOnly = true;
            this.currentStepIndex = 0;
            this.activatePipe();
          }
        }
      },
    },
  },
  created() {
    this.$store.commit("popups/resetPipelineQueueStep");
  },
  mounted() {
    if (this.routeName !== "GameGrid") {
      this.activatePipe();

      //play animations from queue
      this.intervalId = setInterval(() => {
        this.playNext();
      }, 200);
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    nextStep() {
      this.currentStepIndex++;
    },

    //check step restrictions. true if passed
    isStepDoable(stepIndex) {
      const step = this.steps[stepIndex];

      //not during other routes than the lobby
      if (
        step.lobbyOnly &&
        this.routeName !== "home" &&
        this.routeName !== "GameGrid"
      ) {
        return false;
      }
      return true;
    },
    activatePipe() {
      if (
        this.playLobbyOnly &&
        !this.steps[this.currentStepIndex].showOnRedirectToLobby
      ) {
        console.log("SKIP:", this.steps[this.currentStepIndex].name);
        this.$store.commit("popups/setNextPipelineQueueStep");
        return;
      }

      if (!this.isStepDoable(this.currentStepIndex)) {
        console.log("SKIP:", this.steps[this.currentStepIndex].name);
        this.$store.commit("popups/setNextPipelineQueueStep");
        return;
      }

      this.pipeComponentName = "Pipe" + this.steps[this.currentStepIndex].name;
    },
  },
};
</script>

<style scoped lang="scss"></style>
