const getDefaultState = () => {
  return {
    doRewardFlyHeader: null, //null, gold, crown, card,
    unsubscribeEvents: [],
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDoRewardFlyHeader(state, payload) {
    state.doRewardFlyHeader = payload;
  },
  addUnsubscribeEvents(state, payload) {
    state.unsubscribeEvents.push(payload);
  },
  resetUnsubscribeEvents(state, payload) {
    state.unsubscribeEvents = [];
  },
};

const getters = {
  getDoRewardFlyHeader: (state) => state.doRewardFlyHeader,
  getUnsubscribeEvents: (state) => state.unsubscribeEvents,
};
const actions = {
  resetAnimationsState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
