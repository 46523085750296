<template>
  <div>
    <ModalMailConfirm />
    <ModalMailConfirmReminder />
  </div>
</template>

<script>
import ModalMailConfirm from "@/components/notification/mail-confirm.vue";
import ModalMailConfirmReminder from "@/components/notification/mail-confirm-reminder.vue";

export default {
  name: "PipeEmailConfirm",
  components: {
    ModalMailConfirm,
    ModalMailConfirmReminder,
  },
  created() {
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId.startsWith("modal-mail-confirm")) {
        this.$store.commit("popups/setNextPipelineQueueStep");
      }
    });
  },
  mounted() {
    console.log("--------- Pipeline Step MailConfirm ----------");
    this.$store.dispatch("user/increaseMailReminderCounter");

    if (this.$store.getters["user/showMailConfirmModal"]) {
      this.$bvModal.show("modal-mail-confirm");
    } else if (this.$store.getters["user/showMailConfirmReminderModal"]) {
      this.$bvModal.show("modal-mail-confirm-reminder");
    } else {
      this.$store.commit("popups/setNextPipelineQueueStep");
    }
  },
};
</script>

<style scoped lang="scss"></style>
