import store from "@/store/store.js";
export default {
  methods: {
    //check if first date is smaller than second date
    isDateInPast(firstDate, secondDate) {
      return firstDate.getTime() < secondDate.getTime();
    },

    //check if first date is higher than second date
    isDateInFuture(firstDate, secondDate) {
      return firstDate.getTime() > secondDate.getTime();
    },

    //check if both dates are in the same day
    isDateInSameDay(firstDate, secondDate) {
      return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
      );
    },

    //check if date is more than one day in the future
    isDateMoreThanOneDay(date) {
      const then = date;
      const now = new Date().getTime();
      return then - now > 86400000;
    },

    //convert timestamp from API to date
    unixTimestampToDate(unix_timestamp) {
      return new Date(unix_timestamp * 1000);
    },

    //get real date based of server time diff (user could have a wrong time on local computer)
    getCurrentDate() {
      let date = new Date();
      let diff = store.getters["user/getTimeDifference"];
      let fullTime = date.getTime() + diff;
      return new Date(fullTime);
    },
  },
};
