<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Allgemeine Geschäftsbedingungen
    </div>
    <div class="modal-prompt">
      <div id="new-tos-container" class="bg-window overflow-y-scroll px-3 mt-3">
        <TacTextDe></TacTextDe>
      </div>
      <button
        class="button-empty text-decoration-underline"
        @click="$emit('back')"
      >
        zurück
      </button>
    </div>
  </div>
</template>
<script>
import TacTextDe from "@/components/legal/tac-text_de.vue";
export default {
  name: "ModalToSPageToSText",
  components: { TacTextDe },
};
</script>
