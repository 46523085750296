import * as teamAPI from "@/API/teams.js";

const getDefaultState = () => {
  return {
    userTeam: null,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setUserTeam(state, payload) {
    state.userTeam = payload;
  },
};

const getters = {
  isTeamMember: (state, getters, rootState, rootGetters) => {
    if (!rootGetters["auth/isLoggedIn"]) {
      return false;
    }

    return !!rootGetters["user/currentUser"].team_id;
  },
  teamActive: (state, getters) =>
    getters.isTeamMember &&
    state.userTeam &&
    state.userTeam.status === "ACTIVE",
};
const actions = {
  resetTeamsState({ commit }) {
    commit("resetState");
  },
  getUserTeamInfo({ dispatch, commit, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      teamAPI
        .fetchUserTeam(rootGetters["user/currentUser"].team_id)
        .then((res) => {
          commit("setUserTeam", res.data.data.team);
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },

  joinTeam({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      teamAPI
        .joinTeam(id)
        .then((res) => {
          commit("setUserTeam", res.data.data.team);
          dispatch("user/updateUser", {}, { root: true });
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  leaveTeam({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      teamAPI
        .leaveTeam()
        .then((res) => {
          commit("setUserTeam", null);
          dispatch("user/updateUser", {}, { root: true });
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
