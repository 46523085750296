<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Datenschutzerklärung
    </div>
    <div class="modal-prompt">
      <div
        id="new-privacy-container"
        class="bg-window overflow-y-scroll px-3 mt-3"
      >
        <PrivacyTextDe></PrivacyTextDe>
      </div>
      <button
        class="button-empty text-decoration-underline"
        @click="$emit('back')"
      >
        zurück
      </button>
    </div>
  </div>
</template>
<script>
import PrivacyTextDe from "@/components/legal/privacy-text_de.vue";
export default {
  name: "ModalPrivacyPageToSText",
  components: { PrivacyTextDe },
};
</script>
