const getDefaultState = () => {
  return {
    missionComplete: false,
    updateMission: false,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setMissionComplete(state, payload) {
    state.missionComplete = payload;
  },
  setUpdateMission(state, payload) {
    state.updateMission = payload;
  },
};

const getters = {
  getMissionComplete: (state) => state.missionComplete,
  getUpdateMission: (state) => state.updateMission,
};
const actions = {
  resetMissionsState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
