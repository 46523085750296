<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-bailout-3"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <b-row>
          <b-col cols="12">
            <div class="icon-gold">
              <img src="@/assets/img/common/coin1.png" class="w-100" />
            </div>
          </b-col>
          <b-col
            cols="12"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[0].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </b-col>

          <b-col cols="12" class="bonus-col">
            <b-row class="package-bonus-icons">
              <div
                v-if="rewards[0].crown > 0"
                class="col package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/crown6.png"
                    alt="Krone"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].crown) }} x</div>
              </div>
              <b-col
                v-if="rewards[0].card > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].card) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[0].loyalty > 0"
                class="package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[0].loyalty) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[0].elixir > 0"
                class="package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[0].elixir) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[0].expbooster > 0"
                class="package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="position-relative">
            <div
              class="submit-button position-relative"
              @click="buyItem(0, 'Offer', 'Bailout')"
            >
              <div>
                {{ $t("shop.offers.buy_button", { 0: "" }) }}
                <br />
                <span class="big"
                  >{{ formatPrice(price[0]) }} €

                  <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                    {{ formatPrice(oldPrices[0]) }} €
                    <div class="offer-red-line to-center-abs"></div>
                  </div>
                  <div
                    v-if="moreContent && moreContent[0]"
                    class="big-badge-more-percent smaller"
                  >
                    <span class="big">{{ moreContent[0] }}</span>
                    {{ $t("shop.offers.more_contents2") }}
                  </div>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <b-row class="position-relative">
          <div class="top-deal-text to-center-abs">Chance!</div>
          <b-col cols="12">
            <div class="icon-gold-container position-relative">
              <div class="icon-gold icon-gold1 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold2 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[1].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[1].gold) }}
            </div>
          </b-col>

          <b-col cols="12" class="bonus-col">
            <b-row class="package-bonus-icons">
              <b-col
                v-if="rewards[1].crown > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/crown6.png"
                    alt="Krone"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[1].crown) }} x</div>
              </b-col>
              <b-col
                v-if="rewards[1].card > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[1].card) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[1].loyalty > 0"
                class="package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[1].loyalty) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[1].elixir > 0"
                class="package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[1].elixir) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[1].expbooster > 0"
                class="package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[1].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="position-relative">
            <div
              class="submit-button to-center-abs"
              @click="buyItem(1, 'Offer', 'Bailout')"
            >
              <div>
                {{ $t("shop.offers.buy_button", { 0: "" }) }}
                <br />
                <span class="big position-relative"
                  >{{ formatPrice(price[1]) }} €

                  <div
                    v-if="oldPrices && oldPrices[1]"
                    class="offer-old-price postion-relative"
                  >
                    {{ formatPrice(oldPrices[1]) }} €
                    <div class="offer-red-line to-center-abs"></div>
                  </div>
                  <div
                    v-if="moreContent && moreContent[1]"
                    class="big-badge-more-percent smaller"
                  >
                    <span class="big">{{ moreContent[1] }}</span>
                    {{ $t("shop.offers.more_contents2") }}
                  </div>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="rewards[2]" class="offer-reward-container3">
        <b-row class="position-relative">
          <div class="top-deal-text to-center-abs">
            {{ $t("shop.offers.top_deal") }}
          </div>
          <b-col cols="12">
            <div class="icon-gold-container postion-relative">
              <div class="icon-gold icon-gold1 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold2 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
              <div class="icon-gold icon-gold3 to-center-abs">
                <img src="@/assets/img/common/coin1.png" class="w-100" />
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            :style="{ visibility: rewards[2].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div
              class="amount-gold yellow-text"
              :class="rewards[2].gold.toString().length > 9 ? 'small' : ''"
            >
              {{ formatNumber(rewards[2].gold) }}
            </div>
          </b-col>

          <b-col cols="12" class="bonus-col">
            <b-row class="package-bonus-icons">
              <b-col
                v-if="rewards[2].crown > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/crown6.png"
                    alt="Krone"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[2].crown) }} x</div>
              </b-col>
              <b-col
                v-if="rewards[2].card > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/common/cardstack-3.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[2].card) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[2].loyalty > 0"
                class="package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/nav-menu/nav-icon-loyalty.png"
                    class="h-100"
                  />
                </div>
                <div class="text">{{ formatNumber(rewards[2].loyalty) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[2].elixir > 0"
                class="package-icon package-bonus-icon-elixir"
              >
                <div class="icon">
                  <img src="@/assets/img/common/elixir.png" class="h-100" />
                </div>
                <div class="text">{{ formatNumber(rewards[2].elixir) }} x</div>
              </b-col>

              <b-col
                v-if="rewards[2].expbooster > 0"
                class="package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <img
                    src="@/assets/img/shop/level-turbo-6-x-copy@2x.png"
                    class="h-100"
                  />
                </div>
                <div class="text">
                  {{ Math.round(rewards[2].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="position-relative">
            <div
              class="submit-button to-center-abs"
              @click="buyItem(2, 'Offer', 'Bailout')"
            >
              <div>
                {{ $t("shop.offers.buy_button", { 0: "" }) }}
                <br />
                <span class="big position-relative"
                  >{{ formatPrice(price[2]) }} €

                  <div
                    v-if="oldPrices && oldPrices[2]"
                    class="offer-old-price position-relative"
                  >
                    {{ formatPrice(oldPrices[2]) }} €
                    <div class="offer-red-line to-center-abs"></div>
                  </div>
                  <div
                    v-if="moreContent && moreContent[2]"
                    class="big-badge-more-percent"
                  >
                    <span class="big">{{ moreContent[2] }}</span>
                    {{ $t("shop.offers.more_contents2") }}
                  </div>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
export default {
  name: "ModalOfferBailout3",
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-bailout-3");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-bailout-3 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/kleine-krone-offer-bailoutgold-3-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: -10px;
    right: 0px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-bailout-3 {
  .offer-reward-container1 {
    top: 252px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 14px;

    .icon-gold {
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    .amount-gold {
      font-size: 36px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-top: 7px;
      line-height: 1;

      &.small {
        font-size: 28px;
      }
    }

    .bonus-col {
      margin-top: 10px;
      padding: 0 10%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 160px;
      height: 64px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      margin-top: 10px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 30px;
      }

      .offer-old-price {
        top: -55px;
        right: -140px;
      }
      .big-badge-more-percent {
        top: -330px;
        right: -130px;
      }
    }
  }

  .offer-reward-container2 {
    top: 255px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    right: 36px;

    .top-deal-text {
      color: #7d3107;
      font-family: Ubuntu-Bold;
      font-size: 26px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 5px white,
        0 0 2px white;
      top: -84px;
    }

    .icon-gold-container {
      height: 75px;

      .icon-gold {
        &.icon-gold1 {
          z-index: 1;
          right: -55px;
          width: 64px;
        }
        &.icon-gold2 {
          z-index: 2;
          width: 80px;
          top: -15px;
          right: 20px;
        }
      }
    }

    .amount-gold {
      font-size: 40px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1.2;

      &.small {
        font-size: 28px;
      }
    }

    .bonus-col {
      margin-top: 10px;
      padding: 0 10%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 200px;
      height: 72px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      margin-top: 10px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        top: -55px;
        right: -140px;
      }
      .big-badge-more-percent {
        top: -330px;
        right: -130px;
      }
    }
  }

  .offer-reward-container3 {
    top: 190px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 330px;

    .top-deal-text {
      color: #7d3107;
      font-family: Ubuntu-Bold;
      font-size: 33px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 5px white,
        0 0 2px white;
      top: -94px;
    }

    .icon-gold-container {
      height: 80px;

      .icon-gold {
        &.icon-gold1 {
          z-index: 1;
          left: -95px;
          width: 88px;
        }
        &.icon-gold2 {
          z-index: 2;
          width: 112px;
          top: -25px;
        }

        &.icon-gold3 {
          z-index: 1;
          right: -95px;
          width: 88px;
        }
      }
    }

    .amount-gold {
      font-size: 54px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1.3;
      margin-top: 15px;

      &.small {
        font-size: 44px;
      }
    }
    .bonus-col {
      margin-top: 10px;
      padding: 0 10%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: #4de5ff;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .submit-button {
      width: 352px;
      height: 182px;
      background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      padding-top: 10%;
      display: inline-flex;
      line-height: 1;
      left: -17px;
      top: -15px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }
      .offer-old-price {
        top: -55px;
        right: -140px;
      }
      .big-badge-more-percent {
        top: -430px;
        right: -200px;
      }
    }
  }
}
</style>
