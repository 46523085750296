<template>
  <b-carousel
    id="top-news-carousel"
    v-model="slide"
    :interval="5000"
    class="to-center-abs"
    :controls="data.length > 1"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide
      v-for="news in data"
      :key="news.name"
      :caption="$t(news.title)"
      :img-src="newsEntryImg(news)"
      :text-html="$t(news.content)"
    >
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
export default {
  name: "TopNews",
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },

  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    newsEntryImg(entry) {
      if (entry.gallery.length > 0) {
        return entry.gallery[0].url;
      } else if (entry.banner.length > 0) {
        return entry.banner[0].url;
      } else if (entry.news_type && entry.news_type.banner.length > 0) {
        return entry.news_type.banner[0].url;
      } else {
        return require("@/assets/img/notification/icn-nc-lobby.png");
      }
    },
  },
};
</script>

<style lang="scss">
#top-news-carousel {
  width: 94%;
  height: 100%;
  position: absolute;
  top: 0;
  padding-top: 100px;
  .carousel-inner {
    height: 100%;
    .carousel-item {
      height: 100%;
      img {
        width: auto !important;
        height: 100%;
        max-height: 210px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .carousel-caption {
      top: 200px;
      width: 100%;
      left: auto;
      right: auto;
      padding-left: 20px;
      padding-right: 20px;

      h3 {
        font-family: Ubuntu-Bold;
        font-size: 21px;
        margin: 0 0 3px 0;
      }
      p {
        font-family: Ubuntu;
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  .carousel-control-prev {
    left: -5px;
    .carousel-control-prev-icon {
      width: 48px;
      height: 48px;
      background-image: url(~@/assets/img/notification/shape-copy.png);
    }
  }
  .carousel-control-next {
    right: -5px;

    .carousel-control-next-icon {
      width: 48px;
      height: 48px;
      background-image: url(~@/assets/img/notification/btn-featured-slide.png);
    }
  }
}
</style>
