import { render, staticRenderFns } from "./back-arrow.vue?vue&type=template&id=e3e544ce&scoped=true"
var script = {}
import style0 from "./back-arrow.vue?vue&type=style&index=0&id=e3e544ce&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3e544ce",
  null
  
)

export default component.exports