<template>
  <b-modal
    id="modal-winter-help"
    ref="modal-winter-help"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="onOpen"
  >
    <template slot="default">
      <div class="preload"></div>
      <div class="w-100 h-100 bg"></div>
      <!--page 1-->
      <div v-if="currentPage === 1" id="page1">
        <div class="logo">
          <div class="main"></div>
          <div class="bonus" :class="$store.state.locale"></div>
        </div>

        <div class="text1 to-center-abs">
          {{ $t("events.winter.intro") }}
        </div>

        <div class="visual to-center-abs"></div>
        <div class="sweepstakes-text text-center to-center-abs">
          <p class="m-0">{{ $t("events.winter.winner.foot_note") }}</p>
          <p class="m-0">
            <i18next :translation="$t('events.winter.tac.footnote')">
              <template #0>
                <a
                  href="/legal/raffle_tac"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ $t("events.winter.tac") }}</a
                ></template
              >
            </i18next>
          </p>
        </div>
      </div>

      <!--page 2-->
      <!-- <div
        v-else-if="currentPage === 2"
        id="page2"
        class="container-fluid position-relative h-100"
      >
        <div class="logo">
          <div class="main"></div>
          <div class="bonus" :class="$store.state.locale"></div>
        </div>
        <div
          class="h-100 container-fluid position-relative pt-5 d-flex flex-column align-items-center justify-content-around"
        >
          <div
            class="headline-white head-badge d-flex align-items-center justify-content-center"
          >
            {{ $t("events.winter.manual.page2.headline") }}
          </div>
          <b-row class="h-100">
            <b-col cols="12" class="sub-head headline-sub-white mt-3">
              {{ $t("events.winter.manual.page2.sub-head") }}
            </b-col>
            <b-col
              v-for="entry in combinedRaffleData"
              :key="entry.id"
              class="d-flex flex-column"
              ><div>{{ "Position " + entry.position }}</div>
              <div>{{ "Users " + entry.users }}</div>
              <img
                v-if="entry?.asset?.image_urls?.image_url"
                class="w-75 mx-auto"
                :src="entry.asset.image_urls.image_url"
              />
              <div>{{ entry.amount }} x {{ entry.asset.title }}</div></b-col
            >
            <div class="sweepstakes-text text-center">
              <span
                >Durch die Teilnahme an den Gewinnspielen akzeptiere ich die
                <a
                  href="/legal/raffle_tac"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Gewinnspielbedingungen</a
                ></span
              >
            </div></b-row
          >
        </div>
      </div> -->

      <!--page 3-->
      <div
        v-else-if="currentPage === 2"
        id="page3"
        class="container-fluid h-100 position-relative"
      >
        <b-row
          class="h-100 p-3 position-relative"
          align-h="center"
          align-v="center"
        >
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>
          <b-col cols="7" class="ps-5">
            <div class="text-start">
              <div class="headline-gold">
                {{ $t("events.winter.manual.page3.headline") }}
              </div>

              <div class="normal-white mt-4">
                <i18next :translation="$t('events.winter.how_it_works')">
                  <template v-if="eventDays" #0>{{
                    eventDays
                  }}</template> </i18next
                ><span class="icn-badge">
                  <span class="icn-badge-1"></span>
                </span>
              </div>

              <div class="normal-white mt-4">
                <span
                  ><i18next :translation="$t('events.winter.get_premium')">
                    <template #0>{{
                      $t("events.winter.premium_pass")
                    }}</template>
                  </i18next>
                </span>
                <span class="icn-badge">
                  <span class="icn-badge-2"></span>
                </span>
              </div></div
          ></b-col>
          <b-col cols="5" class="h-100 d-flex align-items-end">
            <div class="example-img"></div></b-col
        ></b-row>
      </div>

      <!--page 4-->
      <div
        v-else-if="currentPage === 3"
        id="page4"
        class="container-fluid h-100 position-relative"
      >
        <b-row align-h="center" align-v="center" class="h-100 ps-5 py-3 pe-3">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>
          <b-col cols="12">
            <div class="headline-gold text-start">
              {{ $t("events.winter.manual.page4.headline") }}
            </div>
          </b-col>

          <b-col cols="12" class="text-side">
            <div class="normal-white mt-3">
              <span>
                {{ $t("events.winter.raffle_rewards_intro") }}
              </span>
            </div></b-col
          >
          <b-col
            v-for="entry in combinedRaffleData"
            :key="entry.id"
            class="d-flex flex-column"
          >
            <img
              v-if="entry?.asset?.image_urls?.image_url"
              class="w-75 mx-auto"
              :src="entry.asset.image_urls.image_url"
            />
            <div>{{ entry.amount }} x {{ entry.asset.title }}</div></b-col
          >
          <b-col cols="12">
            <div class="normal-white mt-3">
              {{ $t("events.winter.raffle_info") }}
            </div>
            <div class="normal-white mt-3">
              {{ $t("events.winter.good_luck") }}
            </div>
          </b-col>
        </b-row>
      </div>

      <!--page 5-->
      <!-- <div
        v-else-if="currentPage === 5"
        id="page5"
        class="container-fluid h-100 position-relative"
      >
        <div class="logo">
          <div class="main"></div>
          <div class="bonus" :class="$store.state.locale"></div>
        </div>
        <b-row align-h="center" align-v="center" class="h-100 ps-5 py-3 pe-3">
          <b-col cols="6" class="text-start">
            <div class="page5-headline headline-gold">
              {{ $t("events.winter.manual.page5.headline") }}
            </div>
            <div class="normal-white-small mt-3">
              Mit dem Premiumpass schaltest Du auch alle Premium-Gewinne bereits
              gespielter Level frei.
            </div>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="12">
            <div class="normal-white-small mt-3 text-start">
              {{ $t("events.winter.manual.page5.text4") }}
            </div></b-col
          >
          <b-col cols="12">
            <div
              class="text-bottom d-inline-flex align-items-center justify-content-center"
            >
              <div class="text-icon"></div>
              <div class="text1 headline-white text-start w-50">
                Die Gewinner:innen werden nach Ende des Events ermittelt und per
                E-Mail benachrichtigt.
              </div>
            </div></b-col
          >
          <b-col cols="12">
            <div class="text2 normal-white">
              {{ $t("events.winter.manual.page5.text6") }}
            </div>
            <div class="sweepstakes-text text-center">
              <span
                >Durch die Teilnahme an den Gewinnspielen akzeptiere ich die
                <a
                  href="/legal/raffle_tac"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Gewinnspielbedingungen</a
                ></span
              >
            </div></b-col
          >
        </b-row>
      </div> -->

      <div class="bottom-menu to-center-abs">
        <div v-if="currentPage >= 2" class="arrow left" @click="prevPage"></div>
        <div class="arrow right" @click="nextPage"></div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
import i18next from "i18next";

export default {
  name: "ModalWinterHelp",
  components: {},
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    raffleRewards: {
      type: Array,
      default: () => [],
    },
    raffleWinners: {
      type: Array,
      default: () => [],
    },
    eventDays: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      modalId: "modal-winter-help",
      currentPage: 1,
      maxPages: 3,
    };
  },
  computed: {
    combinedRaffleData() {
      if (!this.raffleRewards || !this.raffleWinners) return null;

      return this.raffleRewards
        .map((reward) => {
          const winner = this.raffleWinners.find(
            (winner) => winner.id === reward.raffle_winner_id
          );

          // Only include matches
          if (winner) {
            return {
              id: winner.id, // or `reward.id` if preferred
              position: winner.position,
              users: winner.users,
              amount: reward.amount,
              asset: reward.asset,
            };
          }
        })
        .filter(Boolean); // Remove undefined results if there’s no match
    },
  },

  mounted() {
    // this.$bvModal.show("modal-winter-help");
  },
  methods: {
    onOpen() {
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      this.currentPage--;
    },
    nextPage() {
      if (this.currentPage >= this.maxPages) {
        this.$bvModal.hide(this.modalId);
        return;
      }
      this.currentPage++;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 822px;
$modal-height: 535px;

#modal-winter-help {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png);
  }

  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 80px;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }

  .modal-body {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      background: url(~@/assets/img/events/winter-bonus-jagd/manual-bg-main.png)
        center/100% 100% no-repeat;
    }
  }

  .modal-header {
    visibility: hidden;
  }
  .sweepstakes-text {
    a {
      color: $light-blue !important;
    }
    p {
      font-size: 10px;
    }
    bottom: 12px;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    line-height: 1.2;

    .icn-badge {
      display: inline-block;
      position: relative;
      left: 7px;
      top: -22px;

      .icn-badge-1 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-1.png)
          right/100% 100% no-repeat;
        position: absolute;
      }

      .icn-badge-2 {
        width: 28px;
        height: 29px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-badge-2.png)
          right/100% 100% no-repeat;
        position: absolute;
      }
    }

    .bottom-menu {
      top: 50%;
      width: 77%;

      a {
        font-family: Ubuntu-Bold;
        font-size: 22px;
        color: #8e8e8e;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        top: -35px;
        width: 68px;
        height: 68px;
        cursor: pointer;

        &.left {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          left: -120px;
          transform: scaleX(-1);
        }

        &.right {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          right: -120px;
        }
      }
    }

    .logo {
      z-index: 30;
      .main {
        position: absolute;
        left: 13px;
        top: 0;
        z-index: 20;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
          right/100% 100% no-repeat;
      }

      .bonus {
        position: absolute;
        left: 0;
        top: 84px;
        z-index: 21;

        &.en {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
            right/100% 100% no-repeat;
        }
        &.de {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
            right/100% 100% no-repeat;
        }
      }
    }

    .headline-white {
      font-family: Ubuntu-Bold;
      font-size: 36px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.3;
    }

    .headline-sub-white {
      font-family: Ubuntu-Bold;
      font-size: 26px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-sub-white2 {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      text-shadow: 0 0 18px rgba(77, 229, 255, 0.5),
        0 0 18px rgba(77, 229, 255, 0.5);
      text-transform: uppercase;
      line-height: 1.2;
    }

    .headline-gold {
      font-family: Ubuntu-Bold;
      font-size: 33px;
      color: white;
      text-transform: uppercase;
      line-height: 1.3;
      background-image: linear-gradient(
        to bottom,
        #fef7bb,
        #f9bc19 55%,
        #da8408 70%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      //IE10
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ */
        & {
          background: transparent;
          color: rgb(253, 242, 141);
        }
      }

      // Safari (7.1+)
      _::-webkit-full-page-media,
      _:future,
      :root & {
        -webkit-text-fill-color: #ffe824 !important;
      }
    }

    .normal-white {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      line-height: 1.2;
    }

    .normal-white-small {
      font-family: Ubuntu-Medium;
      font-size: 20px;
      color: white;
      line-height: 1.2;
    }

    .highlight {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: #fabe1e;
    }

    #page1 {
      .logo {
        position: absolute;
        left: 210px;
        top: -60px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .text1 {
        top: 145px;
        padding: 0 101px;
        font-size: 24px;
      }

      .visual {
        bottom: 25px;
        width: 712px;
        height: 218px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-1-visual.png)
          right/100% 100% no-repeat;
      }
    }

    #page2 {
      .logo {
        position: absolute;
        left: -60px;
        top: -90px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .head-badge {
        position: absolute;
        right: 60px;
        top: -25px;
        width: 454px;
        height: 70px;
        background: linear-gradient(to bottom, #4de5ff, #050d1c 57%),
          linear-gradient(to bottom, #8b5a00, #f7ffff 41%, #ffee51 75%, #8b5a00);
        border: double 2px transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 25.2px;
        line-height: 70px;
      }
    }

    #page3 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .example-img {
        width: 281px;
        height: 309px;
        background: url(~@/assets/img/events/winter-bonus-jagd/manual-demo-panel.png)
          right/100% 100% no-repeat;
      }
    }

    #page4 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .text-side {
        text-align: left;

        .headline-white {
          line-height: 1.1;
        }
      }
    }

    #page5 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .text-icon {
        width: 142px;
        height: 113px;
        background: url(~@/assets/img/events/winter-bonus-jagd/icn-tickets.png)
          right/100% 100% no-repeat;
      }

      .text1 {
        font-size: 21px;
        text-transform: none;
        line-height: 1.2;
      }

      .text2 {
        font-size: 16px;
        font-family: Ubuntu;
        margin-top: 7px;
      }
    }
  }
}
</style>
