const GoogleTag = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {};
      },

      computed: {
        currentUser: function () {
          return this.$store.getters["user/currentUser"];
        },
        isWriteable: function () {
          return this.$store.getters["externalScripts/getIndexedDBWriteable"];
        },
        scriptLoaded: function () {
          return this.$store.getters[
            "externalScripts/getGoogleTagScriptLoaded"
          ];
        },
        cookie: function () {
          return this.$store.getters["cookies/cookiePreferences"];
        },
        user: function () {
          return this.$store.getters["user/currentUser"];
        },
      },
      methods: {
        googleTagLog: function (obj) {
          if (!this.scriptLoaded) {
            return;
          }

          try {
            obj.user_id = this.user.id; //add user id
            dataLayer.push(obj);
          } catch (e) {}
        },

        googleTagLogNormalEvent: function (eventName) {
          this.googleTagLog({
            event: eventName,
          });
        },
        googleTagLogAdvancedEvent: function (eventObj) {
          this.googleTagLog(eventObj);
        },

        googleTagLogShopAddToCard: function (productPackage) {
          this.googleTagLog({
            event: "addToCart",
            ecommerce: {
              currencyCode: productPackage.package.currency,
              add: {
                // 'add' actionFieldObject measures.
                products: [
                  {
                    //  adding a product to a shopping cart.
                    name: productPackage.package.slug,
                    id: productPackage.package.id,
                    price: productPackage.package.price,
                    brand: "KleineKrone",
                    category: productPackage.category,
                    variant: productPackage.variant,
                    quantity: 1,
                  },
                ],
              },
            },
          });
        },
        googleTagLogShopCheckoutStep1: function (
          productPackage,
          paymentMethod
        ) {
          this.googleTagLog({
            event: "checkout",
            ecommerce: {
              checkout: {
                actionField: { step: 1, option: paymentMethod },
                products: [
                  {
                    name: productPackage.package.slug,
                    id: productPackage.package.id,
                    price: productPackage.package.price,
                    brand: "KleineKrone",
                    category: productPackage.category,
                    variant: productPackage.variant,
                    quantity: 1,
                  },
                ],
              },
            },
          });
        },
        googleTagLogShopPurchased: function (lastProductRef) {
          this.googleTagLog({
            event: "purchase",
            ecommerce: {
              // google analytics 4 structure
              transaction_id: lastProductRef.transaction_reference,
              affiliation: lastProductRef.payment_method,
              tax: lastProductRef.taxAmount,
              value: lastProductRef.package.price,
              currency: "EUR",
              items: [
                {
                  item_id: lastProductRef.package.id,
                  item_name: lastProductRef.package.slug,
                  affiliation: lastProductRef.payment_method,
                  currency: "EUR",
                  item_category: lastProductRef.category,
                  item_variant: lastProductRef.variant,
                  price: lastProductRef.package.price,
                  quantity: 1,
                },
              ],

              // google universal structure
              purchase: {
                actionField: {
                  id: lastProductRef.transaction_reference,
                  affiliation: lastProductRef.payment_method,
                  revenue: lastProductRef.package.price, // Total transaction value (incl. tax and shipping)
                  tax: lastProductRef.taxAmount,
                },
                products: [
                  {
                    name: lastProductRef.package.slug,
                    id: lastProductRef.package.id,
                    price: lastProductRef.package.price,
                    brand: "KleineKrone",
                    category: lastProductRef.category,
                    variant: lastProductRef.variant,
                    quantity: 1,
                  },
                ],
              },
            },
          });
        },
      },
    });
  },
};

export default GoogleTag;
