import { listAchievements } from "@/API/achievements.js";

const getDefaultState = () => {
  return {
    userAchievements: [],
  };
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAchievements: (state, array) => (state.userAchievements = array),
};

const actions = {
  resetAchievementsState({ commit }) {
    commit("resetState");
  },
  fetchAchievements({ commit }) {
    return new Promise((resolve, reject) => {
      listAchievements()
        .then((res) => {
          let list = Object.values(res.data.data.list);
          commit("setAchievements", list);
          resolve(list);
        })
        .catch((e) => {
          commit("setAchievements", []);
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
