<template>
  <div>
    <b-modal
      v-if="data"
      id="modal-daily-main"
      :hide-footer="true"
      modal-class="custom-modal-transparent"
      @shown="onOpen"
      @hidden="onClose"
    >
      <template slot="modal-header" slot-scope="{ close }">
        <a href="#" class="close-button" @click="close()"> </a>
      </template>

      <template slot="default" slot-scope="{ close }">
        <div class="bg1"></div>
        <div class="bg2"></div>

        <div class="header to-center-abs">
          <div
            class="headline to-center-abs"
            :class="$store.state.locale"
          ></div>
          <div class="counter-numbers-black"></div>
          <div class="counter-numbers-active" :class="'day' + days"></div>
          <div
            class="big-number to-center-abs"
            :class="days === 7 ? 'on' : 'off'"
          ></div>
        </div>

        <div class="left-side">
          <div class="head-text">
            {{ $t("dailylogin.extras_text") }}
          </div>
          <div
            v-if="rewardsAmount && leagueRewards && loyaltyConfig"
            class="earnings"
          >
            <div class="earning">
              <EarningShieldDaily
                earning="user-league"
                :locked-info="leagueCards > 0 ? 'unlocked' : 'locked'"
                ><template #earning-amount-or-text>{{
                  extraCardText
                }}</template>
              </EarningShieldDaily>
              <InfoBadge
                v-if="leagueCards <= 1"
                id="daily-info-bonus-card"
                :info-text="
                  $t('dailylogin.info2.next_level_new', {
                    0: $t(
                      `user_leagues.league.${leaguesWithBonusCards.first.league.title}`
                    ),
                    1: leaguesWithBonusCards.first.amount,
                    2: $t('dailylogin.counter.extra_card.one'),
                  })
                "
              />
              <InfoBadge
                v-else
                id="daily-info-bonus-card"
                :info-text="
                  $t('dailylogin.info2.next_level_new', {
                    0: $t(
                      `user_leagues.league.${leaguesWithBonusCards.second.league.title}`
                    ),
                    1: leaguesWithBonusCards.second.amount,
                    2: $t('dailylogin.counter.extra_card.other'),
                  })
                "
              />
            </div>
            <div class="earning">
              <EarningShieldDaily
                earning="loyalty"
                :locked-info="loyaltyCards > 0 ? 'unlocked' : 'locked'"
                ><template #earning-amount-or-text
                  >+
                  {{
                    $t("dailylogin.bonus_cards.one", {
                      0: loyaltyCards === 0 ? 1 : loyaltyCards,
                    })
                  }}</template
                ></EarningShieldDaily
              >
              <InfoBadge
                v-if="loyaltyCards <= 1"
                id="daily-info-bonus-card2"
                :info-text="
                  $t('dailylogin.info1.next_level_new', {
                    0: loyaltyLevelsWithBonusCards.first.level,
                    1: $t(
                      `loyalty.title.level_${loyaltyLevelsWithBonusCards.first.level}`
                    ),
                    2: $t('dailylogin.counter.extra_card.one'),
                  })
                "
              />
              <InfoBadge
                v-else
                id="daily-info-bonus-card2"
                :info-text="
                  $t('dailylogin.info1.next_level_new', {
                    0: loyaltyLevelsWithBonusCards.second.level,
                    1: $t(
                      `loyalty.title.level_${loyaltyLevelsWithBonusCards.second.level}`
                    ),
                    2: $t('dailylogin.counter.extra_card.other'),
                  })
                "
              />
            </div>
            <div v-if="$store.getters.facebookEnabled" class="earning">
              <EarningShieldDaily
                earning="facebook"
                :locked-info="facebookConnected ? 'unlocked' : 'locked'"
                ><template #earning-amount-or-text>{{
                  formatNumber(facebookBonus)
                }}</template></EarningShieldDaily
              >
              <InfoBadge
                id="daily-info-bonus-card3"
                :info-text="$t('dailylogin.info3')"
              />
            </div>
          </div>
        </div>

        <div class="middle-side to-center-abs">
          <div
            class="sub-headline to-center-abs d-flex align-items-center justify-content-center"
          >
            <div class="scroll-text-content">
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext1") }}
              </div>
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext2") }}
              </div>
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext3") }}
              </div>
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext4") }}
              </div>
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext5") }}
              </div>
              <div class="scroll-text to-center-abs">
                {{ $t("dailylogin.scrolltext6") }}
              </div>
            </div>
          </div>

          <b-col id="daily-card-col" class="custom-cards-col">
            <template v-for="(card, index) in currentCards">
              <CardDailyLogin
                :key="card.id"
                :flipped="card.flipped"
                :do-animation="card.doAnimation"
                :rewards="card.rewards"
                :index="index"
                :click-counter="clickCounter"
                @click.native="
                  () => {
                    clickCounter++;
                    flipCard(card.id);
                  }
                "
              ></CardDailyLogin>
            </template>
          </b-col>
        </div>

        <div class="right-side">
          <div class="head-text">
            {{ $t("dailylogin.rewards_text") }}
          </div>
          <div class="earnings">
            <div id="daily-earning-crown" class="earning">
              <EarningShieldDaily earning="crown" :has-shield="false"
                ><template #earning-amount-or-text>{{
                  formatNumber(rewardsAmount.crown)
                }}</template>
              </EarningShieldDaily>
            </div>
            <div id="daily-earning-card" class="earning">
              <EarningShieldDaily earning="card" :has-shield="false"
                ><template #earning-amount-or-text>
                  {{ formatNumber(rewardsAmount.card) }}</template
                ></EarningShieldDaily
              >
            </div>
            <div id="daily-earning-gold" class="earning">
              <EarningShieldDaily earning="gold" :has-shield="false"
                ><template #earning-amount-or-text>{{
                  formatNumber(rewardsAmount.gold)
                }}</template></EarningShieldDaily
              >
            </div>
          </div>
        </div>

        <div class="button-container to-center-abs">
          <div ref="cardCont" class="buttonCont w-100 h-100">
            <div
              ref="cardBack"
              class="light-button to-center-abs"
              @click="close()"
            >
              <div class="top-text to-center-abs">
                {{ $t("dailylogin.counter.complete") }}
              </div>
              <div class="submit-text to-center-abs">
                {{ $t("dailylogin.button_collect_new") }}
              </div>
            </div>

            <div ref="cardFront" class="dark-button to-center-abs">
              <div v-if="opened >= days" class="lights-extra to-center-abs">
                <template v-for="(bonus, index) in maxDays">
                  <div :key="index" class="light">
                    <div
                      class="light-bg mx-auto"
                      :class="
                        maxDays - index <= amountBonusCards ? 'on' : 'off'
                      "
                    ></div>
                  </div>
                </template>
              </div>
              <div v-else class="lights-normal to-center-abs">
                <template v-for="(opening, index) in maxDays">
                  <div :key="index" class="light">
                    <div
                      class="light-bg mx-auto"
                      :class="
                        maxDays - index - 1 < days - opened ? 'on' : 'off'
                      "
                    ></div>
                  </div>
                </template>
              </div>
              <div
                class="lights-text to-center-abs"
                :class="opened >= days ? 'on' : 'off'"
              >
                {{ $t("dailylogin.counter.extra_card.one") }}
              </div>
              <div class="card-left-text to-center-abs">
                {{
                  $t("dailylogin.button_cards_left_new", {
                    0: "xxx",
                  }).split("xxx")[0]
                }}
                <span class="big">
                  {{ openings - opened }}
                </span>
                {{
                  $t("dailylogin.button_cards_left_new", {
                    0: "xxx",
                  }).split("xxx")[1]
                }}
              </div>
            </div>
          </div>
        </div>

        <div
          :class="[
            facebookConnected ||
            !showFacebookConnectButton ||
            !$store.getters.facebookEnabled
              ? 'd-none'
              : 'd-flex',
          ]"
          class="fb-button to-center-abs"
        >
          <FacebookConnectButton
            @facebook-working="showFacebookConnectButton = true"
          />
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as dailyAPI from "@/API/daily-login.js";
import * as leagueAPI from "@/API/leagues";
import EarningShieldDaily from "./earning-shield-daily.vue";
import CardDailyLogin from "./card-daily-login";
import Vue from "vue";
import AnimationParsingFunctions from "@/components/animation/animation-parsing-functions";
import FacebookConnectButton from "../base/facebook-connect-button.vue";
import InfoBadge from "@/components/base/info-badge.vue";
import unixTimestampToDate from "@/mixins/dateFunctions";

export default {
  name: "ModalDailyMain",
  components: {
    CardDailyLogin,
    FacebookConnectButton,
    EarningShieldDaily,
    InfoBadge,
  },
  mixins: [AnimationParsingFunctions, unixTimestampToDate],
  data() {
    return {
      testing: false,
      data: null,
      randomizedRewards: null,
      cards: [],
      league: {},
      clickCounter: 0,
      showFacebookConnectButton: false,
    };
  },

  computed: {
    facebookBonus: function () {
      return this.$store.getters["getGeneralConfig"].dailylogin.bonus_facebook;
    },
    loyaltyConfig() {
      return this.$store.state.generalConfig.loyality;
    },
    leagueRewards() {
      return this.$store.state.generalConfig.leagueRewards;
    },
    openings: function () {
      if (!this.data) {
        return;
      }

      return this.data.openings;
    },
    days: function () {
      if (!this.data) {
        return;
      }

      if (this.data.counter >= this.maxDays) {
        return this.maxDays;
      }

      return this.data.counter;
    },

    maxDays: function () {
      return 7;
    },
    amountBonusCards: function () {
      if (this.days >= this.openings) {
        return 0;
      }

      return this.openings - this.days;
    },

    opened: function () {
      if (!this.data) {
        return;
      }

      return this.data.opened;
    },

    currentCards: function () {
      if (!this.data) {
        return;
      }

      let result = this.cards;
      let rewards = this.data.rewards;
      let index = 0;

      if (rewards) {
        for (let props in rewards) {
          result[index].rewards = rewards[props];
          result[index].flipped = true;
          index++;
        }
      }

      return result.slice(0, 14);
    },

    user: function () {
      return this.$store.getters["user/currentUser"];
    },

    loyaltyCards: function () {
      if (!this.user.loyality || !this.user.loyality.loyality) {
        return 0;
      }

      return this.user.loyality.loyality.bonus_dailylogin_card;
    },

    leagueCards: function () {
      if (!this.league) {
        return 0;
      }

      if (
        this.league.league &&
        this.league.league.order < this.leaguesWithBonusCards.first.league.order
      ) {
        return 0;
      } else if (
        this.league.league &&
        this.league.league.order >=
          this.leaguesWithBonusCards.first.league.order &&
        this.league.league.order <
          this.leaguesWithBonusCards.second.league.order
      ) {
        return this.leaguesWithBonusCards.first.amount;
      } else {
        return this.leaguesWithBonusCards.second.amount;
      }
    },

    rewardsAmount: function () {
      if (!this.data) {
        return;
      }

      let rewards = {};
      rewards.gold = rewards.crown = rewards.card = 0;
      for (let i = 0; i < this.currentCards.length; i++) {
        if (this.currentCards[i].rewards === undefined) {
          continue;
        }

        for (let j = 0; j < this.currentCards[i].rewards.length; j++) {
          let type = this.currentCards[i].rewards[j].type;

          switch (type) {
            case "gold":
              rewards.gold += this.currentCards[i].rewards[j].amount;
              break;
            case "crown":
              rewards.crown += this.currentCards[i].rewards[j].amount;
              break;
            case null:
              rewards.card += this.currentCards[i].rewards[j].amount;
          }
        }
      }

      if (this.facebookConnected) {
        rewards.gold += this.facebookBonus;
      }

      return rewards;
    },
    facebookConnected() {
      if (this.$store.getters["user/currentUser"].facebook_id) {
        return true;
      }

      return false;
    },
    leaguesWithBonusCards() {
      // filter league rewards to find the league levels where additional daily
      // login cards are awarded
      let objectArray = Object.values(this.leagueRewards);
      let firstBonusCard = objectArray
        .flat()
        .find((reward) => reward.asset.endsWith("DailyLoginCard"));
      let secondBonusCard = objectArray
        .flat()
        .find(
          (reward) =>
            reward.asset.endsWith("DailyLoginCard") && reward.amount > 1
        );
      return { first: firstBonusCard, second: secondBonusCard };
    },
    loyaltyLevelsWithBonusCards() {
      let firstBonusCard = this.loyaltyConfig
        .flat()
        .find((level) => level.bonus_dailylogin_card > 0);

      let secondBonusCard = this.loyaltyConfig
        .flat()
        .find((level) => level.bonus_dailylogin_card > 1);
      return { first: firstBonusCard, second: secondBonusCard };
    },
    extraCardText() {
      if (this.leagueCards > 1) {
        return (
          "+ " +
          this.$t("dailylogin.bonus_cards.other", { 0: this.leagueCards })
        );
      } else {
        return "+ " + this.$t("dailylogin.bonus_cards.one", { 0: 1 });
      }
    },
  },

  watch: {
    opened: {
      handler(data) {
        if (data && data >= this.openings) {
          this.animateFlipButton();
        }
      },
    },
  },
  created: function () {
    this.createCards();
    this.fetchData();
  },

  methods: {
    onOpen() {
      this.$store.dispatch("user/setDelayedUpdate", true);
    },
    onClose() {
      this.$store.dispatch("user/setDelayedUpdate", false);
      this.collectAll();
    },

    //remove flying rewards when modal closed
    removeFlyingElements: function () {
      let elements = document.getElementsByTagName("gsap-reward-fly");
      for (let i = elements.length - 1; i >= 0; i--) {
        elements[i].parentNode.removeChild(elements[i]);
        // element[i].classList.add("d-none");
      }
    },

    animateFlipButton: function () {
      //config elements
      const { cardCont, cardFront, cardBack } = this.$refs;
      if (!cardCont) {
        setTimeout(() => {
          this.animateFlipButton();
        }, 500);
        return;
      }
      const timeline = this.gsap.timeline({
        defaults: {
          transformPerspective: 3000,
        },
      });

      /*ANIMATION BEGINS*/
      //flip
      timeline
        .to(cardFront, 1, { rotationY: 180 })
        .to(cardBack, 1, { rotationY: 0 }, 0)
        .to(cardCont, 0.5, { z: 50 }, 0)
        .to(cardCont, 0.5, { z: 0 }, 0.5);

      //play flip sound
      timeline.to(
        cardCont,
        0,
        {
          onComplete: () => {
            this.playSoundTab();
          },
        },
        0.2
      );
    },

    fetchData: function () {
      dailyAPI
        .login()
        .then((res) => {
          this.data = res.data.dailyLoginUser;

          //get real time and calculate difference from users local computer
          const currentUnixTime = this.unixTimestampToDate(res.time);
          const now = new Date();
          let dif = currentUnixTime.getTime() - now.getTime();
          this.$store.commit("user/setTimeDifference", dif);

          if (this.testing && process.env.NODE_ENV !== "production") {
            this.data = JSON.parse(
              '{"id":6093,"openings":10,"opened":1,"collected":false,"day":"2021-02-22T05:57:54.000000Z","counter":7,"rewards":{"element0":[{"asset":"App\\\\Models\\\\Asset\\\\Currency","type":"gold","amount":400000},{"asset":"App\\\\Models\\\\Asset\\\\Currency","type":"crown","amount":5},{"asset":"App\\\\Models\\\\Asset\\\\Card","type":null,"amount":3}]},"user_id":2}\n'
            );
          }

          //is daily login active today?
          if (this.data && !this.data.collected) {
            return Promise.all([
              leagueAPI.fetchUserLeagueById(
                this.$store.getters["user/currentUser"].id
              ),
              dailyAPI.randomize(),
            ])
              .then((values) => {
                this.league = values[0].data.data.league;
                this.randomizedRewards = this.parseRandomRewards(
                  values[1].rewards
                );
                this.$bvModal.show("modal-daily-main");

                setTimeout(() => {
                  this.animateScrollText();
                }, 100);
              })
              .catch((e) => console.log(e));
          } else if (this.data && this.data.collected) {
            this.$store.commit("popups/setNextPipelineQueueStep");
          }
        })
        .catch((e) => console.log(e));
    },

    //create array with all card objects
    createCards: function () {
      let cards = new Array(16);
      for (let i = 0; i < cards.length; i++) {
        cards[i] = {
          id: i,
          flipped: false,
        };
      }
      this.cards = cards;
    },

    //get card data and mark this card as open
    flipCard: async function (index) {
      if (
        this.cards[index].flipped ||
        this.cards[index].doAnimation ||
        this.openings === this.opened
      ) {
        return;
      }

      if (!this.randomizedRewards) {
        return;
      }

      let temp_card = this.cards[index];
      temp_card.rewards = this.randomizedRewards[this.data.opened];
      temp_card.doAnimation = true;
      Vue.set(this.cards, index, temp_card);

      this.data.opened = this.data.opened + 1;
    },

    collectAll: async function () {
      await dailyAPI
        .collect()
        .then((result) => {
          this.startAnimations(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    startAnimations: function (result) {
      this.$bvModal.hide("modal-daily-main");
      this.mapApiJsonDailyLogin(result.dailyLoginUser.rewards);
    },

    animateScrollText: function () {
      const scrollTexts = document.getElementsByClassName("scroll-text");
      const space = 1500;
      const intervalTime = 4;
      let currentTextIndex = 0;

      this.gsap.set(scrollTexts, { left: space });
      this.gsap.set(scrollTexts[0], { left: 0 });

      const nextSlide = () => {
        if (!scrollTexts[currentTextIndex]) {
          //modal is closed
          return;
        }
        this.gsap.to(scrollTexts[currentTextIndex], 1, { left: -space });

        if (currentTextIndex < scrollTexts.length - 1) {
          currentTextIndex++;
        } else {
          currentTextIndex = 0;
        }

        this.gsap.fromTo(
          scrollTexts[currentTextIndex],
          2,
          { left: space },
          { left: 0 }
        );
        this.gsap.delayedCall(intervalTime, nextSlide);
      };

      this.gsap.delayedCall(intervalTime, nextSlide);
    },
    parseRandomRewards: function (data) {
      let result = {};
      for (let i = 0; i < data.length; i++) {
        result[i] = data[i].reward.items;
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1184px;
$modal-height: 850px;

#modal-daily-main {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 0;

    @media (max-height: 820px), (max-width: 1250px) {
      transform: scale(0.9);
      top: -40px;
      margin-bottom: -10%;

      .info-badge-popover {
        top: 40px !important;
        left: 15px !important;
      }
    }
    @media (max-height: 740px), (max-width: 1130px) {
      transform: scale(0.8);
      top: -80px;
      margin-bottom: -20%;

      .info-badge-popover {
        top: 70px !important;
      }
    }

    @media (max-width: 1130px) {
      left: -50px;
    }

    .close-button {
      top: 60px;
      right: 50px;
    }
  }

  .modal-body {
    margin-top: 0 !important;
    width: $modal-width;
    height: $modal-height;
  }

  .fb-button {
    width: 306px;
    height: 43px;
    top: 745px;

    #facebook-connect-button {
      border-radius: 26px;
      border: 1px solid #4de5ff;
      background-image: linear-gradient(#325a9e, #0c1b36);
    }

    .token {
      margin-left: 10px;
      margin-right: 0;
    }

    .facebook-connect-text {
      font-family: Ubuntu-Medium;
      font-size: 18px;
      color: white;
      filter: drop-shadow(0 -2px 6px #4de5ff);
    }
    .facebook-connect-amount {
      font-family: Ubuntu-Medium;
      font-size: 24px;
      color: white;
      filter: drop-shadow(0 -2px 6px #4de5ff);
    }
    .facebook-connect-icon {
      margin-top: -3px;
      margin-right: 5px;
    }
  }

  .info-button {
    width: 28px;
    height: 27px;
    min-width: 28px;
    min-height: 27px;
    background: url(~@/assets/img/common/icn-help.png) center/100% 100%
      no-repeat;
    position: absolute;
    top: 40px;
    right: -30px;
    &:hover {
      filter: $hover-shadow;
    }
  }
}
</style>

<style scoped lang="scss">
$modal-width: 1276px;
$modal-height: 800px;

#modal-daily-main {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/mission/mission-close-button-hover.png),
      url(~@/assets/img/mission/mission-green-button-hover-kopie.png),
      url(~@/assets/img/bonus-slot/bs-paytable-back-half.png),
      url(~@/assets/img/bonus-slot/paytable-symbol-dis-glow-green.png);
  }

  .bg1 {
    margin-top: 80px;
    width: 592px;
    height: 643px;
    background: url(~@/assets/img/daily-login/luckyday-back-half.png) bottom
      no-repeat;
    position: absolute;
    right: 1px;
    top: 0;
    transform: scaleX(-1);
  }
  .bg2 {
    margin-top: 80px;
    width: 592px;
    height: 643px;
    background: url(~@/assets/img/daily-login/luckyday-back-half.png) bottom
      no-repeat;
    position: absolute;
    left: 0;
    top: 0;
  }

  .header {
    .headline {
      top: 0;
      left: -180px;
      height: 129px;
      width: 414px;
      background-size: 100%;
      background-repeat: no-repeat;

      &.de {
        background-image: url("~@/assets/img/daily-login/luckday-head-de.png");
      }

      &.en {
        background-image: url("~@/assets/img/daily-login/luckday-head-en.png");
      }
    }

    .counter-numbers-black {
      position: absolute;
      left: 588px;
      top: 100px;
      height: 58px;
      width: 184px;
      background-size: 184px 58px;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/daily-login/luckyday-counter-black.png");
    }
    .counter-numbers-active {
      position: absolute;
      left: 588px;
      top: 100px;
      height: 58px;

      background-size: 184px 58px;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/daily-login/luckyday-counter-gold.png");

      &.day1 {
        width: 20px;
      }
      &.day2 {
        width: 45px;
      }
      &.day3 {
        width: 70px;
      }
      &.day4 {
        width: 100px;
      }
      &.day5 {
        width: 150px;
      }
      &.day6,
      &.day7 {
        width: 210px;
      }
    }

    .big-number {
      top: -15px;
      left: 500px;
      height: 197px;
      width: 186px;
      background-size: 100%;
      background-repeat: no-repeat;

      &.on {
        background-image: url("~@/assets/img/daily-login/luckyday-7-active.png");
      }
      &.off {
        background-image: url("~@/assets/img/daily-login/luckyday-7-black.png");
      }
    }
  }

  .left-side {
    width: 300px;
    position: absolute;
    top: 160px;
    left: -85px;

    .head-text {
      margin-left: auto;
      margin-right: auto;
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 21px;
      width: 169px;
      height: 36px;
      background-image: linear-gradient(#12212b, #020203);
      border: 2px solid #4de5ff;
      border-radius: 18px;
    }

    .earnings {
      width: 100px;
      margin-left: auto;
      margin-right: auto;

      .earning {
        width: 100%;
        height: 100px;
        margin-bottom: 25px;
        position: relative;
      }
    }
  }

  .middle-side {
    width: 939px;
    top: 230px;

    .sub-headline {
      top: -50px;
      height: 50px;
      width: 750px;
      line-height: 50px;

      .scroll-text-content {
        width: 100%;
        height: 50px;
        overflow: hidden;
        position: relative;

        .scroll-text {
          font-family: Ubuntu-Medium;
          font-size: 26px;
          color: white;
          filter: drop-shadow(0 -2px 6px #4de5ff);
          white-space: pre;
        }
        .scroll-text:first-of-type {
          font-size: 30px;
        }
      }
    }
  }

  .right-side {
    width: 300px;
    position: absolute;
    top: 160px;
    right: -85px;

    .head-text {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      color: #4de5ff;
      font-family: Ubuntu-Bold;
      font-size: 21px;
      width: 169px;
      height: 36px;
      background-image: linear-gradient(#12212b, #020203);
      border: 2px solid #4de5ff;
      border-radius: 18px;
    }

    .earnings {
      width: 100px;
      margin-left: auto;
      margin-right: auto;

      .earning {
        width: 100%;
        height: 100px;
        margin-bottom: 25px;
        position: relative;
      }
      .info-button {
        width: 28px;
        height: 27px;
        min-width: 28px;
        min-height: 27px;
        background: url(~@/assets/img/common/icn-help.png) center/100% 100%
          no-repeat;
        position: absolute;
        top: 40px;
        right: -30px;
        &:hover {
          filter: $hover-shadow;
        }
      }
    }
  }

  .button-container {
    top: 600px;
    width: 756px;
    height: 133px;
    z-index: 200;

    .dark-button {
      font-family: Ubuntu-Medium;
      background: url(~@/assets/img/daily-login/counter-bottom-background-all.png)
        no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      z-index: 10;

      .lights-normal {
        width: 50%;
        top: 18px;
        left: -210px;

        .light {
          position: relative;
          height: 16px;
          width: 16px;
          margin: 0 5px;
          display: inline-block;

          .light-bg {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            border-radius: 50%;

            &.on {
              background-color: #4de5ff;
            }

            &.off {
              background-image: linear-gradient(to bottom, #414040, #505050);
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      .lights-extra {
        width: 50%;
        top: 18px;
        left: -210px;

        .light {
          position: relative;
          height: 16px;
          width: 16px;
          margin: 0 5px;
          display: inline-block;

          .light-bg {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            border-radius: 50%;

            &.on {
              background-color: #43ff00;
            }

            &.off {
              background-image: linear-gradient(to bottom, #414040, #505050);
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }

      .lights-text {
        font-size: 25px;
        text-transform: uppercase;
        top: 9px;
        right: -210px;

        &.on {
          color: #43ff00;
        }

        &.off {
          color: #4f4f4f;
        }
      }

      .card-left-text {
        font-size: 30px;
        color: #c8c8c8;
        text-transform: uppercase;
        top: 58px;

        .big {
          font-size: 62px;
          color: #ffffff;
          filter: drop-shadow(0 2px 10px rgba(77, 229, 255, 0.6));
          vertical-align: middle;
          padding-bottom: 12px;
          padding-left: 15px;
          padding-right: 15px;
          line-height: 50px;
          display: inline-block;
        }
      }
    }

    .light-button {
      font-family: Ubuntu-Medium;
      background: url(~@/assets/img/daily-login/btn-collect-bottom-all.png)
        no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      cursor: pointer;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      z-index: 10;
      transform: rotateY(180deg);

      .top-text {
        font-size: 25px;
        color: #43ff00;
        text-transform: uppercase;
        top: 9px;
      }

      .submit-text {
        font-size: 40px;
        color: #ffffff;
        text-transform: uppercase;
        top: 58px;
        filter: drop-shadow(0 -4px 12px #43ff00);
      }
    }
  }
}
</style>
