<template>
  <b-modal
    id="modal-tos-confirm"
    size="xl"
    centered
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="true"
  >
    <template #default>
      <div
        class="pt-4 h-100 component-wrapper position-relative h-100"
        :class="page"
      >
        <component
          :is="currentContentComponent"
          :stay-rewards="stayRewards"
          :newsletter-rewards="newsletterRewards"
          @back="page = 1"
          @open-tos="page = 'ToSText'"
          @open-privacy="page = 'PrivacyText'"
          @reject="handleFinalOptOut"
        >
        </component>

        <form
          v-if="
            page !== 'OptOut2' &&
            page !== 'OptIn2' &&
            page !== 'PrivacyText' &&
            page !== 'ToSText' &&
            page !== 'ImprintText'
          "
          id="checkbox-form"
          @submit.prevent="handleFormSubmit"
        >
          <div class="form-group w-75 p-3 mx-auto mb-3">
            <!-- TOS -->
            <label
              v-if="page !== 'OptIn1'"
              class="royal-check-label mb-0"
              name="tos-check"
              for="tos-check"
              tabindex="0"
              role="checkbox"
            >
              <div class="pseudo-label text-start" v-html="ToSLabel"></div>
              <input
                id="tos-check"
                v-model="acceptToSCheck"
                type="checkbox"
                tabindex="-1" /><span class="royal-checkmark"></span
            ></label>

            <!-- Age -->
            <label
              v-if="page === 1 || page === 'OptOut1'"
              class="royal-check-label mb-0"
              name="age-check"
              for="age-check"
              tabindex="0"
              role="checkbox"
            >
              <div class="pseudo-label text-start">
                Ich bin älter als 18 Jahre alt
              </div>
              <input
                id="age-check"
                v-model="ageCheck"
                type="checkbox"
                tabindex="-1" /><span class="royal-checkmark"></span
            ></label>

            <!-- Newsletter -->
            <label
              class="royal-check-label mb-0"
              name="newsletter-check"
              for="newsletter-check"
              tabindex="1"
              role="checkbox"
            >
              <div class="pseudo-label text-start">
                <template v-if="page !== 'OptIn1'"
                  >Bitte schickt mir tägliche Geschenke und Neuigkeiten an meine
                  E-Mail Adresse</template
                >
                <template v-else> Ja, bitte schickt mir die Geschenke</template>
              </div>
              <input
                id="newsletter-check"
                v-model="subscribeNewsletterCheck"
                type="checkbox"
                tabindex="-1" /><span class="royal-checkmark"></span
            ></label>
          </div>

          <button
            id="tos-accept-submit"
            type="submit"
            :disabled="!acceptToSCheck || !ageCheck"
            class="ok-button-royal to-center-abs bold p-0 d-inline-flex align-items-center justify-content-center"
            :class="
              page === 'OptIn1' && !subscribeNewsletterCheck ? 'large' : ''
            "
          >
            <template v-if="page === 'OptIn1' && !subscribeNewsletterCheck">
              Ohne Geschenke spielen
            </template>

            <template v-else>Jetzt spielen!</template>
          </button>
          <button
            v-if="page !== 'OptOut1' && page !== 'OptIn1' && page !== 'OptIn2'"
            id="opt-out-1-btn"
            type="button"
            class="button-empty text-decoration-underline to-center-abs"
            @click="page = 'OptOut1'"
          >
            Ich bin nicht überzeugt
          </button>
          <button
            v-else-if="page !== 'OptIn1'"
            id="opt-out-2-btn"
            type="button"
            class="button-empty text-decoration-underline to-center-abs"
            @click="page = 'OptOut2'"
          >
            Ich möchte nicht mehr spielen
          </button>
        </form>
        <button
          v-if="page === 1"
          id="imprint-btn"
          type="button"
          class="button-empty text-decoration-underline position-absolute"
          @click="page = 'ImprintText'"
        >
          Impressum
        </button>
        <button
          v-if="page === 'OptOut1'"
          id="tos-text-btn-2"
          type="button"
          class="button-empty text-decoration-underline position-absolute"
          @click="page = 'ToSText'"
        >
          AGB
        </button>
        <button
          v-else-if="page === 'OptIn2'"
          type="button"
          class="ok-button-royal to-center-abs bold p-0 d-inline-flex align-items-center justify-content-center"
          @click="celebrateAccept"
        >
          Jetzt spielen!
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ModalToSPage1 from "./modal-tos-page-1.vue";
import ModalToSPageToSText from "./modal-tos-page-tos-text.vue";
import ModalToSPagePrivacyText from "./modal-tos-page-privacy-text.vue";
import ModalToSPageOptOut1 from "./modal-tos-page-opt-out-1.vue";
import ModalToSPageOptOut2 from "./modal-tos-page-opt-out-2.vue";
import ModalToSPageOptIn1 from "./modal-tos-page-opt-in-1.vue";
import ModalToSPageOptIn2 from "./modal-tos-page-opt-in-2.vue";
import BackArrow from "../../legal/back-arrow.vue";
import ModalToSPageImprintText from "./modal-tos-page-imprint-text.vue";
import AnimationParsingFunctions from "../../animation/animation-parsing-functions.vue";

export default {
  name: "ModalToSConfirm",
  components: {
    ModalToSPage1,
    ModalToSPageToSText,
    ModalToSPagePrivacyText,
    ModalToSPageImprintText,
    BackArrow,
    ModalToSPageOptOut1,
    ModalToSPageOptOut2,
    ModalToSPageOptIn1,
    ModalToSPageOptIn2,
  },
  mixins: [AnimationParsingFunctions],
  data() {
    return {
      page: 1,
      acceptToSCheck: false,
      ageCheck: false,
      subscribeNewsletterCheck: false,
      userEmail: "",
    };
  },
  computed: {
    currentContentComponent() {
      return "ModalToSPage" + this.page;
    },
    ToSLabel() {
      return "Ich akzeptiere die AGB von Glücksfaktor7 GmbH";
    },
    stayRewards() {
      return this.$store.state.user.rewardsAccepted;
    },
    newsletterRewards() {
      return this.$store.state.user.rewardsNewsletter;
    },
  },

  methods: {
    handleFormSubmit() {
      if (!this.subscribeNewsletterCheck && this.page !== "OptIn1") {
        this.page = "OptIn1";
      } else {
        this.handleFinalOptIn();
      }
    },
    handleFinalOptIn() {
      let rewards;
      if (!this.subscribeNewsletterCheck) {
        rewards = this.stayRewards;
      } else {
        rewards = this.newsletterRewards.concat(this.stayRewards);
      }
      this.$store
        .dispatch("user/acceptNewToS", this.subscribeNewsletterCheck)
        .then((res) => {
          this.$store.dispatch("setToSAccepted");
          this.page = "OptIn2";
          res.data.data.rewards.forEach((reward) => {
            if (reward && reward.card) {
              reward.card.amount = reward.amount;
              rewards.push(reward.card);
            }
          });

          this.mapTermsRewards(rewards);
        })
        .catch((e) => console.log("oh no ", e));
    },
    handleFinalOptOut() {
      this.$store
        .dispatch("user/rejectNewToS")
        .then(this.$store.dispatch("destroyStore"));
    },
    celebrateAccept() {
      this.$bvModal.hide("modal-tos-confirm");
    },
  },
};
</script>
<style lang="scss">
#modal-tos-confirm {
  .modal-header {
    border: none !important;
    text-transform: uppercase;
    text-align: center;
  }

  .modal-content {
    background-color: transparent;
  }
  .component-wrapper {
    background-image: url(~@/assets/img/modal/frame_regular.png);
    background-size: contain; /* Ensure the entire image fits */
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 2 / 1; /* Maintain a 2:1 aspect ratio */
    height: auto; /* Allow the height to adjust automatically */
    &.OptOut2 {
      background-image: url(~@/assets/img/modal/frame_leave.png);
    }
    &.OptIn2 {
      background-image: url(~@/assets/img/modal/frame_success.png);
    }
  }

  .modal-dialog {
    max-width: 1000px; /* Set a max width */
    width: 100%; /* Ensure it scales down on smaller screens */
    margin: 0 auto; /* Center the modal */
  }
  .modal-body {
    position: relative;
    padding: 0; /* Remove default padding */
    height: 100%;
  }
  .tos-package {
    display: flex;
    justify-content: center;
    .icon {
      width: 72px;
    }
    .package-item {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
    }
  }
  .modal-pagetitle {
    font-family: cinzel-bold;
    font-size: 20px;
    padding-top: 10px;
  }
  .modal-prompt {
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 60px;
    color: #582204;

    &p {
      text-align: start;
      font-size: 18px;
    }
  }
  #checkbox-form {
    padding-left: 70px;
    padding-right: 70px;
  }

  .form-group {
    background-color: #fdf0cc;
  }
  .button-empty {
    color: #582204;
    padding: 0 !important;
    &:hover {
      filter: drop-shadow(0 0 8px #8f6524);
    }
  }

  .ok-button-royal {
    width: 300px;
    height: 57px;
    bottom: -13px;
    font-size: 20.6px;
    text-shadow: 0 2px 3px black;
    &.large {
      width: 480px;
    }
  }
  #imprint-btn,
  #tos-text-btn-2 {
    right: 130px;
    bottom: 20px;
  }

  #opt-out-1-btn,
  #opt-out-2-btn {
    bottom: -45px;
    color: #8f6524;
  }
  #new-tos-container,
  #new-privacy-container,
  #new-imprint-container {
    a {
      text-decoration: underline !important;
      &:hover {
        filter: drop-shadow(0 0 8px #8f6524);
      }
    }
    .table tbody tr {
      text-transform: none;
      color: #582204 !important;
      --bs-table-color: #582204;
    }
    .table thead th {
      text-transform: none;
      color: #582204 !important;
      --bs-table-color: #582204;
    }
    .table tbody tr:nth-of-type(odd) td,
    table tbody tr:nth-of-type(odd) td {
      background-color: transparent;
    }
    &.bg-window {
      max-height: 300px;
      background-color: #fdf0cc;
      border: 2px solid #8f6524;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5), 0 2px 10px rgba(0, 0, 0, 0.5),
        0 2px 4px rgba(0, 0, 0, 0.5);
      &::-webkit-scrollbar {
        height: 12px;
        width: 12px;
        background: $dark-blue-03;
      }

      &::-webkit-scrollbar-thumb {
        background: #8f6524;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      &::-webkit-scrollbar-corner {
        background: #000;
      }
    }
  }
}
</style>
