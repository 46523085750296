var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deco-corners"},[_c('div',{staticClass:"deco-corner top-left",class:[
      _vm.colorVariant,
      { 'extra-space': _vm.borderOffset },
      { 'move-to-top': _vm.modalOffset },
    ]}),_c('div',{staticClass:"deco-corner top-right",class:[
      _vm.colorVariant,
      { 'extra-space': _vm.borderOffset },
      { 'move-to-top': _vm.modalOffset },
    ]}),_c('div',{staticClass:"deco-corner bottom-right",class:[_vm.colorVariant, { 'extra-space': _vm.borderOffset }]}),_c('div',{staticClass:"deco-corner bottom-left",class:[_vm.colorVariant, { 'extra-space': _vm.borderOffset }]})])
}
var staticRenderFns = []

export { render, staticRenderFns }