import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchTournament = () =>
  axios
    .get(`/api/tournaments/list`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
export const fetchTournamentByGameId = (game_id) =>
  axios
    .get(`/api/tournaments/list/${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const getTournamentByTournId = (tournament_id) =>
  axios
    .get(`/api/tournaments/get/${tournament_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchActiveTournaments = () =>
  axios.get(`/api/tournaments/actives`, optionsFunction());

export const getActiveTournamentByGameId = (game_id) =>
  axios
    .get(`/api/tournaments/actives?game_id=${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchUpcomingTournaments = () =>
  axios.get(`/api/tournaments/upcoming`, optionsFunction());

export const getUpcomingTournamentByGameId = (game_id) =>
  axios
    .get(`/api/tournaments/upcoming?game_id=${game_id}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchHistoryTournament = () =>
  axios
    .get(`/api/tournaments/history`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));

export const fetchHistoryTournamentLimit = (limit) =>
  axios
    .get(`/api/tournaments/history?limit=${limit}`, optionsFunction())
    .then((res) => res.data.data)
    .catch((e) => console.log(e));
