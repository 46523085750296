<template>
  <div class="game-grid-container">
    <div
      v-if="gridTitle"
      class="game-row-title position-relative w-100 px-3 pt-1 d-inline-flex align-items-center justify-content-between"
    >
      <span class="text-uppercase bold text-white">{{ gridTitle }}</span>
    </div>
    <div class="game-grid-bg px-3">
      <div class="game-grid">
        <GamePreviewItem
          v-for="(game, index) in games"
          :key="game.id"
          :game-index="index"
          :game="game"
          :kingspath-on-game="kingspathOnGame"
          :active-tournament-game-ids="activeTournamentGameIds"
          :upcoming-tournament-game-ids="upcomingTournamentGameIds"
          :is-featured="isFeatured"
          :grid-view="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import GamePreviewItem from "@/components/lobby/game-preview-item.vue";
export default {
  name: "GameGrid",
  components: { GamePreviewItem },
  props: {
    isFeatured: {
      type: Boolean,
      default: false,
    },
    games: {
      type: Array,
      default: () => [],
    },

    userCanPlayTournaments: {
      type: Boolean,
      default: false,
    },
    kingspathOnGame: {
      type: String,
      required: false,
      default: "",
    },
    activeTournamentGameIds: {
      type: Array,
      required: false,
      default: null,
    },
    upcomingTournamentGameIds: {
      type: Array,
      required: false,
      default: null,
    },
    gridTitle: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.game-grid-container {
  .game-grid-bg {
    background: url(~@/assets/img/backgrounds/slotrow-bg-default.svg);
    padding-top: 15px;

    .game-grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(296px, 1fr));
      grid-column-gap: 8px;
      grid-row-gap: 10px;
      grid-auto-rows: 245px;
      place-items: center;

      @media (max-width: 1300px) {
        grid-template-columns: repeat(3, minmax(265px, 1fr));
        grid-auto-rows: 272px;
      }
      .game-preview-item {
        .slot-img-container {
          .slot-img {
            height: auto;
            width: 100%;
          }
        }
        &.details-view {
          height: 160%;
          width: 140%;
          @media (max-width: 1300px) {
            height: 125%;
            width: 110%;
          }
        }
      }
    }
  }
}
</style>
