<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Bist du dir sicher?
    </div>
    <div class="modal-prompt">
      <p>
        Neue Turniere, neue Events, neues Mit- und Gegeneinander. Bleib dabei
        und erlebe, wie das Spiel sich in den nächsten Monaten verbessert.
      </p>

      <p>Und vielleicht hilft diese kleine Starthilfe dich zu überzeugen?</p>

      <div class="tos-package">
        <div
          v-for="(reward, index) in stayRewards"
          :key="reward.type + index"
          class="package-item"
        >
          <img draggable="false" :src="getRewardIcon(reward)" class="icon" />
          <div>{{ formatNumber(reward.amount) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalToSPageOptOut1",
  props: {
    stayRewards: {
      type: Array,
      required: true,
    },
  },

  methods: {
    getRewardIcon(reward) {
      if (reward.type && reward.type === "gold") {
        return require("@/assets/img/icons/icon-coin.png");
      } else if (reward.type && reward.type === "crown") {
        return require("@/assets/img/icons/icon-crown.png");
      } else if (!reward.type && reward.asset.endsWith("Card")) {
        return require("@/assets/img/icons/icon-cards.png");
      } else if (!reward.type && reward.asset.endsWith("Elixir")) {
        return require("@/assets/img/icons/icon-elixier.png");
      } else {
        return require("@/assets/img/icons/icon-coin.png");
      }
    },
  },
};
</script>
