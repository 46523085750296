import Vue from "vue";

const getDefaultState = () => {
  return {
    forcedWishMode: false,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCardsForcedWishMode(state, payload) {
    state.forcedWishMode = payload;
  },
};

const getters = {
  getCardsForcedWishMode: (state) => state.forcedWishMode,
};

const actions = {
  resetTournamentsState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
