<script>
import AnimationSpineBaseFunctions from "@/components/animation/animation-spine-base-functions";
import { Spine } from "pixi-spine";

export default {
  name: "AnimationSpineRewardFlyCustom",
  mixins: [AnimationSpineBaseFunctions],
  data() {
    return {
      intervalId: null,
      destroyApp: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.intervalId);

    this.destroyApp = true;
  },

  methods: {
    playAnimationSpineRewardFlyDailyLogin: function (
      targetElement,
      rewardType,
      loop = false,
      startElement
    ) {
      //load needed assets only
      const assets = [];
      switch (rewardType) {
        case "gold":
          assets.push([
            "gold",
            "/assets/spines/reward-fly/skeleton-coinfly-main.json",
          ]);
          break;
        case "card":
          assets.push([
            "card",
            "/assets/spines/reward-fly/skeleton-cardfly-main.json",
          ]);
          break;
        case "crown":
          assets.push([
            "crown",
            "/assets/spines/reward-fly/skeleton-crownfly-main.json",
          ]);
          break;
        default:
          assets.push([
            "gold",
            "/assets/spines/reward-fly/skeleton-coinfly-main.json",
          ]);
          break;
      }

      const options = {
        width: window.innerWidth,
        height: window.innerHeight,
        antialias: true,
        backgroundAlpha: 0,
        autoResize: true,
      };
      const app = this.createCanvas(options, assets);
      app.view.id = "canvas-fly-custom-" + +Math.floor(Math.random() * 100);
      app.view.classList.add("canvas-full-width");

      app.ticker.add(() => {
        if (this.destroyApp) {
          this.destroyApp = false;
          this.destroyAll(app);
        }
      });

      const setup = (loader, res) => {
        let spineData = this.getSpineData(res);

        spineData.forEach((data) => {
          const spine = new Spine(data);
          const skeleton = spine.skeleton;

          //start point
          const boneBegin = skeleton.findBone("ctrl-start");
          boneBegin.x = app.screen.width;
          boneBegin.y = -app.screen.height;
          if (startElement) {
            boneBegin.x =
              startElement.getBoundingClientRect().left * 2 +
              startElement.offsetWidth;
            boneBegin.y =
              -startElement.getBoundingClientRect().top * 2 -
              startElement.offsetHeight;
          }

          //end point
          const boneEnd = skeleton.findBone("ctrl-end");
          boneEnd.x =
            targetElement.getBoundingClientRect().left * 2 +
            targetElement.offsetWidth;
          boneEnd.y =
            -targetElement.getBoundingClientRect().top * 2 -
            targetElement.offsetHeight;

          //set scale
          spine.scale.set(0.5);

          //add spine to stage
          app.stage.addChild(spine);

          //get correct animation
          let animation = this.getAnimationByName(data, "ani-fly");

          //set animation end
          const duration = animation.duration * 1000;

          //run animation
          spine.state.setAnimation(0, animation.name);

          //add loop
          if (loop) {
            this.intervalId = setInterval(() => {
              spine.state.setAnimation(0, animation.name);
            }, duration);
          } else {
            setTimeout(() => {
              this.destroyAll(app);
            }, duration);
          }
        });
      };

      app.loader.load(setup);
    },
  },
};
</script>

<style lang="scss"></style>
