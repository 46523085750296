import * as newsAPI from "@/API/news.js";

const getDefaultState = () => {
  return {
    messages: [],
    userMessages: [],
    readMessages: [],
    deletedMessages: [],
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setMessages(state, payload) {
    state.messages = payload;
  },
  setUserMessages(state, payload) {
    state.userMessages = payload;
  },

  markAsRead: (state, id) => state.readMessages.push(id),
  markAsDeleted: (state, id) => state.deletedMessages.push(id),
  updateUserMessage: (state, message) => {
    let userMessageToUpdate = state.userMessages.findIndex(
      (msg) => msg.id === message.id
    );
    if (userMessageToUpdate) {
      state.userMessages[userMessageToUpdate] = message;
    }
  },
};

const getters = {
  availableMessages: (state) => {
    let availableMessages = state.messages.filter(
      (message) => !state.deletedMessages.includes(message.id)
    );
    let availableUserMessages = state.userMessages.filter(
      (message) => !state.deletedMessages.includes(message.id)
    );
    return availableMessages.concat(availableUserMessages);
  },
  unreadMessages: (state) => {
    let unreadMessages = state.messages.filter(
      (message) =>
        !state.readMessages.includes(message.id) &&
        !state.deletedMessages.includes(message.id)
    );
    let unreadUserMessages = state.userMessages.filter(
      (message) =>
        !message.seen_at &&
        !state.readMessages.includes(message.id) &&
        !state.deletedMessages.includes(message.id)
    );
    return unreadMessages.concat(unreadUserMessages);
  },
  unreadMessagesAvailable: (state, getters) =>
    getters.unreadMessages && getters.unreadMessages.length > 0,
  newsCategories: (state, getters) => {
    let normalFilters = [];
    let userFilters = [];
    let result = [];
    getters.availableMessages.forEach((message) => {
      if (message.news_type) {
        if (message.user_id) {
          userFilters.push(message.news_type.title);
        } else {
          normalFilters.push(message.news_type.title);
        }
      }
    });
    let normalFiltersMinusDuplicates = new Set(normalFilters);
    let userFiltersMinusDuplicates = new Set(userFilters);
    normalFiltersMinusDuplicates.forEach((item) =>
      result.push({ title: item, userNews: false })
    );
    userFiltersMinusDuplicates.forEach((item) =>
      result.push({ title: item, userNews: true })
    );

    return result;
  },
};
const actions = {
  fetchNews({ commit }) {
    return Promise.all([newsAPI.fetchNews(), newsAPI.fetchUserNews()])
      .then((values) => {
        commit("setMessages", values[0].data.data.news);
        commit(
          "setUserMessages",
          (this.userMessages = values[1].data.data.news)
        );
      })
      .catch((e) => console.log(e));
  },
  markUserNewsAsRead({ state, commit }) {
    state.userMessages.forEach((news) => {
      if (!news.seen_at) {
        newsAPI
          .markUserNewsAsRead(news.id)
          .then((res) => commit("updateUserMessage", res.data.data.news))
          .catch((e) => console.log(e));
      }
    });
  },
  markNewsAsDeleted({ state, commit }, id) {
    commit("markAsDeleted", id);
    let newsIsUserNews = state.userMessages.findIndex((msg) => msg.id === id);
    if (newsIsUserNews >= 0) {
      newsAPI.deleteUserNews(id).catch((e) => console.log(e));
    }
  },
  resetNewsState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
