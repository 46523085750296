import * as Cookies from "vue-cookies";
const state = {
  listOfCookies: [],
  preferences: [
    {
      type: "essential",
      checked: true,
    },
    {
      type: "comfort",
      checked: false,
    },
    {
      type: "statistics",
      checked: false,
    },
    {
      type: "marketing",
      checked: false,
    },
  ],
};

const mutations = {
  setPreferences(state, array) {
    state.preferences = array;
  },
  setCookie(state, payload) {
    if (
      state.listOfCookies.findIndex((cookie) => cookie.name === payload.name) <
      0
    ) {
      // add new cookie if not already in list
      state.listOfCookies.push(payload);
    } else {
      // update data of already existing cookie
      state.listOfCookies.forEach((cookie) => {
        if (cookie.name === payload.name) {
          cookie.data = payload.data;
        }
      });
    }
  },
  removeCookie(state, name) {
    let cookieIndex = state.listOfCookies.findIndex(
      (cookie) => cookie.name === name
    );
    state.listOfCookies.splice(cookieIndex, 1);
    Cookies.remove(name);
  },
};

const getters = {
  getPreferences: (state) => state.preferences,
  isTypeAccepted: (state) => (type) => {
    let result = state.preferences.filter((c) => c.type === type);
    return result[0].checked;
  },
  isCookieSet: (state) => (name) => {
    if (
      state.listOfCookies.findIndex((cookie) => cookie.name === name) >= 0 ||
      Cookies.isKey(name)
    ) {
      return true;
    } else {
      return false;
    }
  },
  getCookieData: (state) => (name) =>
    state.listOfCookies.find((cookie) => cookie.name === name),
};

const actions = {
  setCookie({ commit }, payload) {
    commit("setCookie", payload);
    Cookies.set(payload.name, payload.data, "30d", null, null, true, "none");
  },
  removeCookie({ commit }, name) {
    commit("removeCookie", name);
    Cookies.remove(name);
  },
  setPreferences({ commit }, array) {
    commit("setPreferences", array);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
