<template>
  <div>
    <modal-offer-lobby-3 v-if="offerId === 'modal-offer-lobby-3'" />
    <modal-offer-lobby-2 v-if="offerId === 'modal-offer-lobby-2'" />
    <modal-offer-lobby-1 v-if="offerId === 'modal-offer-lobby-1'" />
    <modal-offer-daily-3 v-if="offerId === 'modal-offer-daily-3'" />
    <modal-offer-daily-2 v-if="offerId === 'modal-offer-daily-2'" />
    <modal-offer-daily-1 v-if="offerId === 'modal-offer-daily-1'" />

    <modal-offer-starter-1 v-if="offerId === 'modal-offer-starter-1'" />
    <modal-offer-halloween-1 v-if="offerId === 'modal-offer-halloween-1'" />
    <modal-offer-winter-1 v-if="offerId === 'modal-offer-winter-1'" />
    <modal-offer-winterhere-1 v-if="offerId === 'modal-offer-winterhere-1'" />
    <modal-offer-spring-1 v-if="offerId === 'modal-offer-spring-1'" />
    <modal-offer-summer1 v-if="offerId === 'modal-offer-summer-1'" />
    <modal-offer-ab-testing v-if="offerId === 'modal-offer-ab-testing'" />
  </div>
</template>

<script>
import offerModal from "@/mixins/offerModal";
import ModalOfferHalloween1 from "@/components/shop/offers/modal-offer-halloween-1.vue";
import ModalOfferWinter1 from "@/components/shop/offers/modal-offer-winter-1.vue";
import ModalOfferWinterhere1 from "@/components/shop/offers/modal-offer-winterhere-1.vue";
import ModalOfferSpring1 from "@/components/shop/offers/modal-offer-spring-1.vue";
import ModalOfferLobby3 from "@/components/shop/offers/modal-offer-lobby-3.vue";
import ModalOfferLobby2 from "@/components/shop/offers/modal-offer-lobby-2.vue";
import ModalOfferLobby1 from "@/components/shop/offers/modal-offer-lobby-1.vue";
import ModalOfferDaily3 from "@/components/shop/offers/modal-offer-daily-3.vue";
import ModalOfferDaily2 from "@/components/shop/offers/modal-offer-daily-2.vue";
import ModalOfferDaily1 from "@/components/shop/offers/modal-offer-daily-1.vue";

import ModalOfferStarter1 from "@/components/shop/offers/modal-offer-starter-1.vue";
import ModalOfferAbTesting from "@/components/shop/offers/modal-offer-ab-testing";
import ModalOfferSummer1 from "@/components/shop/offers/modal-offer-summer-1";

export default {
  name: "PipeLobbyOffer",
  components: {
    ModalOfferSummer1,
    ModalOfferAbTesting,
    ModalOfferHalloween1,
    ModalOfferWinter1,
    ModalOfferWinterhere1,
    ModalOfferSpring1,
    ModalOfferLobby3,
    ModalOfferLobby2,
    ModalOfferLobby1,
    ModalOfferDaily3,
    ModalOfferDaily2,
    ModalOfferDaily1,
    ModalOfferStarter1,
  },
  mixins: [offerModal],

  computed: {
    offerId() {
      return this.$store.state.shop.offerId;
    },
  },
  mounted() {
    console.log("--------- Pipeline Step LobbyOffer ----------");

    if (!this.popupsUnlocked) {
      this.$store.commit("popups/setNextPipelineQueueStep");
      return;
    }

    this.fetchOfferLobby();
  },
};
</script>

<style scoped lang="scss"></style>
