const BranchIo = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {};
      },

      computed: {
        currentUser: function () {
          return this.$store.getters["user/currentUser"];
        },
        isWriteable: function () {
          return this.$store.getters["externalScripts/getIndexedDBWriteable"];
        },
        scriptLoaded: function () {
          return this.$store.getters["externalScripts/getBranchIoScriptLoaded"];
        },
        cookie: function () {
          return this.$store.getters["cookies/cookiePreferences"];
        },
      },
      methods: {
        branchIoLog: function (obj) {
          if (!this.scriptLoaded) {
            return;
          }
          console.log("branchIo event", obj);
          try {
            branch.logEvent(obj);
          } catch (e) {}
        },
        branchIoLogRegister: function (type) {
          const event_and_custom_data = {
            type: type,
          };

          if (!this.scriptLoaded) {
            return;
          }

          try {
            branch.logEvent(
              "COMPLETE_REGISTRATION",
              event_and_custom_data,
              null,
              function (err) {
                console.log(err);
              }
            );
          } catch (e) {}
        },

        branchIoLogLogin: function () {
          const event_and_custom_data = {
            // type: type
          };

          if (!this.scriptLoaded) {
            return;
          }

          try {
            branch.logEvent(
              "LOGIN",
              event_and_custom_data,
              null,
              function (err) {
                console.log(err);
              }
            );
          } catch (e) {}
        },

        branchIoLogPurchased: function (lastProductRef) {
          const event_and_custom_data = {
            transaction_id: lastProductRef.transaction_reference,
            currency: "EUR",
            revenue: lastProductRef.package.price,
            tax: lastProductRef.taxAmount,
            affiliation: "Online Store",
          };

          const content_items = [
            {
              $content_schema: "COMMERCE_PRODUCT",
              $og_title: lastProductRef.package.slug,
              $canonical_identifier: lastProductRef.package.id.toString(),
              $price: lastProductRef.package.price,
              $quantity: 1,
              $product_brand: "KleineKrone",
            },
          ];

          const customer_event_alias = "PURCHASE";

          if (!this.scriptLoaded) {
            return;
          }

          try {
            branch.logEvent(
              "PURCHASE",
              event_and_custom_data,
              content_items,
              customer_event_alias,
              function (err) {
                console.log(err);
              }
            );
          } catch (e) {}
        },

        branchIoLogSlot: function (game_id) {
          const event_and_custom_data = {
            game_id: game_id,
          };

          if (!this.scriptLoaded) {
            return;
          }

          try {
            branch.logEvent(
              "open_slot",
              event_and_custom_data,
              null,
              function (err) {
                console.log(err);
              }
            );
          } catch (e) {}
        },

        testBranchIo: function () {
          const event_and_custom_data = {
            transaction_id: "tras_Id_1232343434",
            currency: "USD",
            revenue: 180.2,
            shipping: 10.5,
            tax: 13.5,
            coupon: "promo-1234",
            affiliation: "high_fi",
            description: "Preferred purchase",
            purchase_loc: "Palo Alto",
            store_pickup: "unavailable",
          };

          const content_items = [
            {
              $content_schema: "COMMERCE_PRODUCT",
              $og_title: "Nike Shoe",
              $og_description: "Start loving your steps",
              $og_image_url: "http://example.com/img1.jpg",
              $canonical_identifier: "nike/1234",
              $publicly_indexable: false,
              $price: 101.2,
              $locally_indexable: true,
              $quantity: 1,
              $sku: "1101123445",
              $product_name: "Runner",
              $product_brand: "Nike",
              $product_category: "Sporting Goods",
              $product_variant: "XL",
              $rating_average: 4.2,
              $rating_count: 5,
              $rating_max: 2.2,
              $creation_timestamp: 1499892854966,
              $exp_date: 1499892854966,
              $keywords: ["sneakers", "shoes"],
              $address_street: "230 South LaSalle Street",
              $address_city: "Chicago",
              $address_region: "IL",
              $address_country: "US",
              $address_postal_code: "60604",
              $latitude: 12.07,
              $longitude: -97.5,
              $image_captions: ["my_img_caption1", "my_img_caption_2"],
              $condition: "NEW",
              $custom_fields: { foo1: "bar1", foo2: "bar2" },
            },
            {
              $og_title: "Nike Woolen Sox",
              $canonical_identifier: "nike/5324",
              $og_description:
                "Fine combed woolen sox for those who love your foot",
              $publicly_indexable: false,
              $price: 80.2,
              $locally_indexable: true,
              $quantity: 5,
              $sku: "110112467",
              $product_name: "Woolen Sox",
              $product_brand: "Nike",
              $product_category: "Apparel & Accessories",
              $product_variant: "Xl",
              $rating_average: 3.3,
              $rating_count: 5,
              $rating_max: 2.8,
              $creation_timestamp: 1499892854966,
            },
          ];

          const customer_event_alias = "my custom alias";

          if (!this.scriptLoaded) {
            return;
          }

          try {
            branch.logEvent(
              "PURCHASE",
              event_and_custom_data,
              content_items,
              customer_event_alias,
              function (err) {
                console.log(err);
              }
            );
          } catch (e) {}
        },
      },
    });
  },
};

export default BranchIo;
