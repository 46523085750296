<template>
  <div
    class="earning-container-daily mx-auto"
    :class="!hasShield ? 'no-shield' : ''"
  >
    <img
      class="earning-icon to-center-abs"
      draggable="false"
      :src="require('@/assets/img/common/' + icon)"
      :style="earningStyle"
    />

    <div v-if="iconText" class="icon-text text-center">
      {{ iconText }}
    </div>

    <div
      class="earning-btn to-center-abs gradient-indent glow text-center"
      :class="size"
    >
      <span
        class="earning-amount"
        :class="[
          size,
          hasShield === false ? 'white' : '',
          lockedInfo && lockedInfo === 'unlocked' ? 'green' : 'black',
        ]"
      >
        <slot name="earning-amount-or-text"> </slot>
        <img
          v-if="earning === 'facebook'"
          src="@/assets/img/common/coin1.png"
          class="icon-right"
          :class="[lockedInfo && lockedInfo === 'locked' ? 'disabled' : '']"
      /></span>
      <img
        v-if="lockedInfo && lockedInfo === 'unlocked'"
        src="@/assets/img/cards/icon-set-complete.png"
        class="icon-complete icon-left"
      />
      <img
        v-else-if="lockedInfo && lockedInfo === 'locked'"
        src="@/assets/img/common/lock-gold.png"
        class="icon-lock icon-left"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "EarningShieldDaily",
  props: {
    earning: {
      type: String,
      default: "gold",
    },
    size: {
      type: String,
      default: "",
    },
    lockedInfo: {
      type: String,
      default: "",
    },
    iconText: {
      type: String,
      default: "",
    },
    hasShield: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    icon: function () {
      switch (this.earning) {
        case "gold":
          return "coin1.png";
        case "card":
          return "cardstack-3.png";
        case "crown":
          return "crown6.png";
        case "loyalty":
          return "loyalty-bonus.png";
        case "user-league":
          return "badge-user-league.png";
        case "facebook":
          return "facebook.png";
        default:
          return "coin1.png";
      }
    },
    earningStyle: function () {
      switch (this.earning) {
        case "gold":
          return { "max-width": "67%", top: "7%" };
        case "crown":
          return { "max-width": "110%", top: "-2%" };
        case "card":
          return { "max-width": "75%", top: "10%" };
        case "loyalty":
          return { "max-width": "72%", top: "8%" };
        case "user-league":
          return { "max-width": "50%", top: "8%" };
        default: {
          return { "max-width": "50%" };
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.earning-container-daily {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 119px;
  background: url(~@/assets/img/common/earning-shield-bg.png) center/100% 100%
    no-repeat;
  margin-top: 30px;

  &.no-shield {
    background: transparent;
  }

  .earning-icon {
    top: 20%;
  }

  .icon-text {
    font-family: Ubuntu-Bold;
    font-size: 28.3px;
    color: white;
    padding-top: 20px;
  }

  .earning-btn {
    width: 160%;
    margin-left: -30%;
    height: 32px;
    bottom: 4%;

    &.lg {
      height: 35px;
    }
    img {
      max-width: 35px;
      position: absolute;

      &.disabled {
        filter: grayscale(100%) brightness(50%);
      }

      &.icon-complete {
        width: 37px;
        top: -2px;
      }

      &.icon-lock {
        width: 35px;
        top: -12px;
      }

      &.icon-left {
        left: -10px;
      }

      &.icon-right {
        right: 10px;
        top: 5px;
        height: 22px;
      }
    }
  }

  .earning-amount {
    font-size: 24px;
    line-height: 32px;
  }
  .green {
    color: #43ff00;
    font-family: Ubuntu-Bold;
  }
  .black {
    color: #5e707c;
    font-family: Ubuntu-Bold;
  }
  .white {
    color: #ffffff !important;
  }
}
</style>
