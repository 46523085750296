const CurrentDate = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        getCurrentDate() {
          let date = new Date();
          let diff = this.$store.getters["user/getTimeDifference"];
          let fullTime = date.getTime() + diff;
          return new Date(fullTime);
        },
      },
    });
  },
};
export default CurrentDate;
