import * as GamesAPI from "@/API/games.js";
import * as jackpotAPI from "@/API/jackpot.js";

const getDefaultState = () => {
  return {
    games: [],
    sortedGames: [],
    lastUpdate: 0, // Last time complete games list has been fetched
    allTradingCards: [],
    lastCardUpdate: 0,
    currentBetSize: null,
    casinoJackpots: [],
    slotJackpots: [],
    lobbyLayout: null,
    userFavoriteGames: [],
    showEventBox: true,
    redirectGame: null,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCasinoJackpots(state, array) {
    state.casinoJackpots = array;
  },
  setSlotJackpots(state, array) {
    state.slotJackpots = array;
  },
  updateJackpots(state, data) {
    data.forEach((jackpot) => {
      let jackpotUpdateIndex;
      if (jackpot.game_id === null) {
        jackpotUpdateIndex = state.casinoJackpots.findIndex(
          (jp) => jp.slug === jackpot.slug
        );
        if (jackpotUpdateIndex < 0) return;
        state.casinoJackpots[jackpotUpdateIndex].amount_current =
          jackpot.amount_current;
      } else {
        jackpotUpdateIndex = state.slotJackpots.findIndex(
          (jp) => jp.slug === jackpot.slug
        );
        if (jackpotUpdateIndex < 0) return;
        state.slotJackpots[jackpotUpdateIndex].amount_current =
          jackpot.amount_current;
        state.slotJackpots[jackpotUpdateIndex].hotness = jackpot.hotness;
      }
    });
  },
  setGames(state, payload) {
    state.games = payload;
  },
  clearGames: (state) => {
    state.games = [];
    state.sortedGames = [];
  },
  setLastUpdate(state, time) {
    state.lastUpdate = time;
  },
  setTradingCards(state, cardsArray) {
    state.allTradingCards = cardsArray;
    state.lastCardUpdate = Date.now();
  },
  setCurrentBetSize(state, payload) {
    state.currentBetSize = payload;
  },
  saveSortedGames(state, gamesArray) {
    state.sortedGames = gamesArray;
  },
  setLobbyLayout: (state, payload) => (state.lobbyLayout = payload),
  setUserFavoriteGames: (state, payload) => (state.userFavoriteGames = payload),
  showEventBox: (state, boolean) => (state.showEventBox = boolean),
  setRedirectGame: (state, payload) => (state.redirectGame = payload),
};

const getters = {
  getGames: (state) => state.games,
  getCurrentBetSize: (state) => state.currentBetSize,
  lobbyUpdateNeeded: (state) => {
    if (
      state.games.length === 0 ||
      state.lastUpdate === 0 ||
      state.casinoJackpots.length === 0 ||
      state.slotJackpots.length === 0 ||
      state.lobbyLayout === null
    ) {
      return true;
    }
    let time = Date.now();
    let timeDiff = time - state.lastUpdate;
    let updateInverval =
      process.env.VUE_APP_UPDATE_INTERVAL_GAMES_LOBBY_MINUTES * 60 * 1000;
    if (timeDiff >= updateInverval) {
      return true;
    } else {
      return false;
    }
  },
  cardsUpdateNeeded: (state) => {
    if (state.allTradingCards.length === 0 || state.lastCardUpdate === 0) {
      return true;
    }
    let time = Date.now();
    let timeDiff = time - state.lastCardUpdate;
    let updateInverval =
      process.env.VUE_APP_UPDATE_INTERVAL_TRADING_CARDS_HOURS * 60 * 60 * 1000;
    if (timeDiff >= updateInverval) {
      return true;
    } else {
      return false;
    }
  },
  getAllLobbyRows: (state) => {
    if (!state.lobbyLayout) return [];
    let models = state.lobbyLayout.models;
    if (!models) return [];
    if (!Array.isArray(models)) {
      models = Object.values(models);
    }
    return models
      .sort((a, b) => a.sort_order - b.sort_order)
      .filter((row) => row.details.games !== undefined);
  },

  getCasinoJackpots: (state) => state.casinoJackpots,
};

const actions = {
  updateGameList({ commit }) {
    return new Promise((resolve, reject) => {
      GamesAPI.fetchGames()
        .then((res) => {
          let time = Date.now();
          commit("clearGames");
          commit("setGames", res.data.data.games);
          commit("setLastUpdate", time);
          resolve(res);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  fetchLobbyLayout({ commit }) {
    return new Promise((resolve, reject) => {
      GamesAPI.getLobbyLayout()
        .then((res) => {
          let lobbyLayout = res.data.data.lobby;
          commit("setLobbyLayout", lobbyLayout);

          GamesAPI.getUserFavoriteGames()
            .then((res) => {
              commit("setUserFavoriteGames", res.data.data.games);
              resolve(res);
            })
            .catch((e) => {
              console.log(e);
              reject(e);
            });
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },

  fetchJackpotData({ commit }) {
    return new Promise((resolve, reject) => {
      jackpotAPI
        .getAllJackpots()
        .then((result) => {
          commit(
            "setCasinoJackpots",
            result.data.data.filter((jackpot) => !jackpot.game_id)
          );
          commit(
            "setSlotJackpots",
            result.data.data.filter((jackpot) => jackpot.game_id)
          );
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  resetGameplayState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
