export default {
  methods: {
    getRewardsFromPackage: function (packageObj) {
      let result = {};
      const packageItems = packageObj.package.items;

      for (let i = 0; i < packageItems.length; i++) {
        switch (packageItems[i].item.type) {
          case "gold":
            result.gold = packageItems[i].item.amount;
            break;
          case "crown":
            result.crown = packageItems[i].item.amount;
            break;
          case "loyalty":
            result.loyalty = packageItems[i].item.amount;
            break;
          case "elixir":
            result.elixir = packageItems[i].item.amount;
            break;
          case "expbooster":
            result.expbooster = packageItems[i].item.amount;
            break;
          case "card":
            result.card = packageItems[i].item.amount;
            break;
          case null:
            result.card = packageItems[i].item.amount;
            break;
        }
      }
      return result;
    },

    getMoreContentLabel(packageObj) {
      const fixedPackages = [
        { id: 2, value: "+20%" }, //Goldbeutel
        { id: 3, value: "+40%" }, //GoldSack
        { id: 4, value: "+50%" }, //Goldkiste
        { id: 5, value: "+60%" }, //Goldtrue
        { id: 6, value: "+100%" }, //Goldschatz
        { id: 8, value: "+20%" },
        { id: 9, value: "+40%" },
        { id: 10, value: "+60%" },
        { id: 11, value: "+100%" },
        { id: 254, value: "+100%" },
      ];

      return fixedPackages.find((item) => item.id === packageObj.id);
    },

    getOldPrice(packageObj) {
      const fixedPackages = [
        { id: 1, value: null },
        { id: 2, value: "100.000.000" },
        { id: 3, value: "200.000.000" },
        { id: 4, value: "400.000.000" },
        { id: 5, value: "1.000.000.000" },
        { id: 6, value: "2.000.000.000" },
        { id: 7, value: null },
        { id: 8, value: "1.000" },
        { id: 9, value: "2.000" },
        { id: 10, value: "5.000" },
        { id: 11, value: "10.000" },
        { id: 254, value: null },
      ];

      return fixedPackages.find((item) => item.id === packageObj.id);
    },
  },
};
