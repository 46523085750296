import { render, staticRenderFns } from "./facebook-connect-button.vue?vue&type=template&id=7083185c&scoped=true"
import script from "./facebook-connect-button.vue?vue&type=script&lang=js"
export * from "./facebook-connect-button.vue?vue&type=script&lang=js"
import style0 from "./facebook-connect-button.vue?vue&type=style&index=0&id=7083185c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7083185c",
  null
  
)

export default component.exports