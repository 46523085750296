export default {
  computed: {
    lobbyOffer() {
      return this.$store.getters["shop/getLobbyOffer"];
    },
    specialOffer() {
      return this.$store.getters["shop/getSpecialOffer"];
    },
    currentOffer() {
      if (this.isSpecialOffer) {
        return this.specialOffer.offer;
      } else {
        return this.lobbyOffer.offer;
      }
    },
    price() {
      return this.currentOffer.packages.map((pack) => pack.package.price);
    },
    modalId() {
      if (this.isSpecialOffer) {
        return "modal-special-offer";
      } else {
        return (
          "modal-offer-" +
          this.currentOffer.type +
          "-" +
          this.currentOffer.packages.length
        );
      }
    },

    isWithReference() {
      if (!this.lobbyOffer.configuration) {
        return false;
      }

      const conf = JSON.parse(this.lobbyOffer.configuration);
      return !!conf.old_prices;
    },

    oldPrices() {
      if (!this.lobbyOffer || !this.lobbyOffer.configuration) {
        return;
      }
      const conf = JSON.parse(this.lobbyOffer.configuration);
      return conf.old_prices;
    },

    moreContent() {
      if (!this.lobbyOffer || !this.lobbyOffer.configuration) {
        return;
      }
      const conf = JSON.parse(this.lobbyOffer.configuration);
      return conf.more_content;
    },

    //parse all rewards from packages
    rewards() {
      let result = [];

      for (let j = 0; j < this.currentOffer.packages.length; j++) {
        let rewardObj = {
          gold: 0,
          crown: 0,
          loyalty: 0,
          elixir: 0,
          card: 0,
          expbooster: 0,
        };

        let offerPackage = this.currentOffer.packages[j];
        const items = offerPackage.package.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].item.type === "gold") {
            rewardObj.gold += items[i].item.amount;
            continue;
          }

          if (items[i].item.type === "crown") {
            rewardObj.crown += items[i].item.amount;
            continue;
          }

          if (items[i].item.type === "loyalty") {
            rewardObj.loyalty += items[i].item.amount;
            continue;
          }

          if (items[i].item.type === "elixir") {
            rewardObj.elixir += items[i].item.amount;
            continue;
          }

          if (items[i].item.type === "expbooster") {
            rewardObj.expbooster += items[i].item.amount;
            continue;
          }

          if (items[i].item.asset === "App\\Models\\Asset\\Card") {
            rewardObj.card += items[i].item.amount;
            // result.cardObj.push()
            continue;
          }
        }

        result.push(rewardObj);
      }

      return result;
    },
  },
  methods: {
    clearCurrentOffer() {
      this.$store.commit("shop/setLobbyOffer", null);
      this.$store.commit("shop/setOfferId", "");
    },
    onHidden() {
      this.clearCurrentOffer();
      this.firebaseClickItemOffer("offer_close_button");
      if (this.isPipelineOffer) {
        this.$store.commit("popups/setNextPipelineQueueStep");
      }
    },

    buyItem(packageIndex, itemCategory, itemVariant, callback) {
      this.playSoundTab();
      this.firebaseClickItemOffer(
        "offer_buy_button_" + this.currentOffer.packages[packageIndex].id + "#"
      );
      let packageItem = this.currentOffer.packages[packageIndex];
      packageItem.rewards = this.rewards[packageIndex];
      packageItem.category = itemCategory;
      packageItem.variant = itemVariant;

      if (callback) {
        packageItem.callback = callback;
      }

      this.$store.commit("shop/setShopChosenPackage", packageItem);
      // remove special offer from store so badge in lobby will disappear
      if (this.isSpecialOffer) {
        this.$store.commit("shop/setSpecialOffer", null);
      }

      this.$bvModal.show("modal-payment");
    },
  },
};
