import * as paymentAPI from "@/API/payment.js";

export default {
  methods: {
    showOfferTreasury: function () {
      this.fetchOfferByType("treasury");
    },
    showOfferLevelUp: function () {
      this.fetchOfferByType("levelup");
    },
    showOfferStarter: function () {
      this.fetchOfferByType("starter", true);
    },
    showOfferBailout: function () {
      this.fetchOfferByType("bailout");
    },
    showOfferCrownEmpty: function () {
      this.fetchOfferByType("crown_empty");
    },
    showOfferDaily: function () {
      this.fetchOfferByType("daily");
    },
    showOfferEvent: function () {
      this.fetchOfferByType("event");
    },
    showOfferRandom: function () {
      this.fetchOfferRandom();
    },

    activateModalOffer(offer) {
      if (offer.status === "STOP") {
        return;
      }

      //random offer
      this.$store.commit("shop/setLobbyOffer", offer);

      //set offer id
      let offerType = offer.type.toLowerCase();
      const offerPackageAmount = offer.offer.packages.length;

      //check for special halloween offer
      if (offer.comment && offer.comment.indexOf("Halloween") !== -1) {
        offerType = "halloween";
      }
      //check for special winter offer
      if (offer.comment && offer.comment.indexOf("Winter naht") !== -1) {
        offerType = "winter";
      } else if (
        offer.comment &&
        offer.comment.indexOf("Winter ist da") !== -1
      ) {
        offerType = "winterhere";
      } else if (
        offer.comment &&
        offer.comment.indexOf("Frühlingsangebot") !== -1
      ) {
        offerType = "spring";
      } else if (
        offer.comment &&
        offer.comment.indexOf("Sommerangebot") !== -1
      ) {
        offerType = "summer";
      }

      let modalId = `modal-offer-${offerType}-${offerPackageAmount}`;

      //check for special slug
      if (offer.configuration) {
        const conf = JSON.parse(offer.configuration);

        if (conf.custom_slug) {
          modalId = `modal-offer-${conf.custom_slug}`;
        }
      }

      //set offer in store
      this.$store.commit("shop/setOfferId", modalId);

      //check if offer modal exist
      const components = this.$options.components;
      const componentName = `ModalOffer${this.capitalize(
        offerType
      )}${this.capitalize(offerPackageAmount)}`;
      if (!components[componentName]) {
        console.log("invalid random offer");
        this.$store.commit("popups/setNextPipelineQueueStep");
        return;
      }
      this.firebaseViewOffer(offerType.toUpperCase());
    },
    activateModalSpecialOffer(offer) {
      if (offer.status === "STOP") {
        return;
      }

      this.$store.commit("shop/setSpecialOffer", offer);
    },

    capitalize(s) {
      return s.toString()[0].toUpperCase() + s.toString().slice(1);
    },

    fetchOfferByType: async function (type, fallback = false) {
      await paymentAPI
        .fetchPaymentOffersByOfferType(type)
        .then((res) => {
          //sometimes no offer is available
          if (!res || res.length <= 0) {
            return;
          }

          if (res[type]) {
            this.activateModalOffer(res[type]);
          } else {
            if (fallback) {
              this.fetchOfferRandom();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //header button
    fetchOfferRandom: async function () {
      await paymentAPI
        .fetchPaymentOffersRandom()
        .then((res) => {
          //sometimes no offer is available
          if (!res || res.length <= 0) {
            return;
          }

          this.activateModalOffer(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //lobby
    fetchOfferLobby: async function () {
      //empty store data
      this.$store.commit("shop/setLobbyOffer", null);
      this.$store.commit("shop/setOfferId", null);

      await paymentAPI
        .fetchPaymentOffersLobby()
        .then((res) => {
          //sometimes no offer is available
          if (!res || res.length === 0) {
            this.$store.commit("popups/setNextPipelineQueueStep");
          }
          // only special offer
          else if (res.temporals && !res.random) {
            this.activateModalSpecialOffer(res.temporals[0]);
            if (!this.$store.state.shop.autoShowSpecialOffer) {
              this.$store.commit("popups/setNextPipelineQueueStep");
            }
          }
          // only random offer
          else if (!res.temporals && res.random) {
            this.activateModalOffer(res.random);
          }
          // special and random offer
          else {
            this.activateModalSpecialOffer(res.temporals[0]);
            if (!this.$store.state.shop.autoShowSpecialOffer) {
              this.activateModalOffer(res.random);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
