<template>
  <div class="skeleton-item" name="game-preview-skeleton"></div>
</template>
<script>
export default {
  name: "GamePreviewSkeleton",
};
</script>
<style scoped lang="scss">
.skeleton-item {
  position: relative;
  height: 100%;
  background: url("~@/assets/img/game/skeleton_placeholder.gif") center/100%
    100% no-repeat;
  width: 100%;
  height: 100%;
  padding: 1.5px;
  min-height: 168px;
  max-height: 238px;
}
</style>
