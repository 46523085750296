const featureUnlockedChecker = {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        userLevel() {
          if (!this.$store.getters["auth/isLoggedIn"]) {
            return 0;
          }
          return this.$store.getters["user/currentUser"].level;
        },
        popupsUnlocked() {
          return this.userLevel >= this.getUnlockLevel("popups.unlock_level");
        },
        lobbyJackpotsUnlocked() {
          return (
            this.userLevel >= this.getUnlockLevel("lobby_jackpot.unlock_level")
          );
        },
        slotJackpotsUnlocked() {
          return (
            this.userLevel >= this.getUnlockLevel("slot_jackpot.unlock_level")
          );
        },
        footerUnlocked() {
          return this.userLevel >= this.getUnlockLevel("footer.unlock_level");
        },
        headerDetailsUnlocked() {
          return this.userLevel >= this.getUnlockLevel("header.unlock_level");
        },
        eventsUnlocked() {
          return this.userLevel >= this.getUnlockLevel("events.unlock_level");
        },
        pushMessagesUnlocked() {
          return (
            this.userLevel >=
            this.getUnlockLevel("push_notification.unlock_level")
          );
        },
      },
      methods: {
        getUnlockLevel(string) {
          let config = this.$store.getters["getGeneralConfig"];
          if (!config || this.$store.getters["auth/isLoggedIn"]) {
            return 0;
          } else {
            let unlockLevel = config.settings[string];
            if (!unlockLevel) {
              return 0;
            } else return unlockLevel;
          }
        },
      },
    });
  },
};
export default featureUnlockedChecker;
