<script>
import morph_json from "./json/kleinekrone-animation-rewardshow-cards-sprites-MorphCountFX";
import morph_blue_json from "./json/bonusslot-winning-number-morph";
import flare_json from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-flaresymbol";
import smoke_json0 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-0";
import smoke_json1 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-1";
import smoke_json2 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-2";
import smoke_json3 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-3";
import smoke_json4 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-4";
import smoke_json5 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-5";
import smoke_json6 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-6";
import smoke_json7 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-7";
import smoke_json8 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-8";
import smoke_json9 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-9";
import smoke_json10 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-10";
import smoke_json11 from "./json/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-11";
import smoke2_json0 from "./json/adelsliga-animation-abstieg-smoke-0";
import smoke2_json1 from "./json/adelsliga-animation-abstieg-smoke-1";
import smoke2_json2 from "./json/adelsliga-animation-abstieg-smoke-2";
import smoke2_json3 from "./json/adelsliga-animation-abstieg-smoke-3";
import smoke2_json4 from "./json/adelsliga-animation-abstieg-smoke-4";
import smoke2_json5 from "./json/adelsliga-animation-abstieg-smoke-5";
import smoke2_json6 from "./json/adelsliga-animation-abstieg-smoke-6";
import smoke_explode from "./json/koenigsweg-keylockexplode-smokesmall";
import confetti from "./json/meinekrone-confetti-bottomup-spritesheet";
import AnimationBaseFunctions from "./animation-base-functions";
import AnimationNumberIncrease from "@/components/animation/animation-number-increase";
import gsap from "gsap";

export default {
  name: "AnimationEffects",
  mixins: [AnimationBaseFunctions, AnimationNumberIncrease],
  methods: {
    AnimationEffectsDoAnimation: function (
      classname,
      x,
      y,
      json_arr,
      parent,
      endless,
      preload,
      fps = 24
    ) {
      let steps = json_arr.length - 1;
      let body = document.body;

      let element = document.createElement("gsap-effect");
      element.classList.add(classname);

      let parentElement = body;
      if (parent) {
        parentElement = parent;
      }
      parentElement.appendChild(element);

      element.frame = 0;

      gsap.set(element, {
        x: x,
        y: y,
      });
      if (preload) {
        gsap.set(element, {
          opacity: 0,
        });
      }

      let duration = steps / fps;
      if (preload) {
        duration = 0.5;
      }

      let animation = gsap.to(element, duration, {
        frame: steps,
        ease: `steps(${steps})`,
        onComplete: function () {
          if (endless) {
            this.play(0);
            return;
          }

          element.parentElement.removeChild(element);
        },
        modifiers: {
          frame: (i, target) => {
            let sprite = json_arr[Math.round(i)].frame;
            //set background images
            gsap.set(target, {
              xPercent: -50,
              yPercent: -50,
              background:
                'url("' +
                require("@/assets/img/animation/effects/" +
                  json_arr[Math.round(i)].image) +
                '")',
            });

            //set posiion and with of sprite
            target.style.backgroundPosition =
              -sprite.x + "px " + -sprite.y + "px";
            target.style.width = sprite.w + "px";
            target.style.height = sprite.h + "px";

            return i;
          },
        },
      });

      this.$store.commit("animations/addUnsubscribeEvents", () => {
        animation.kill();
      });

      return element;
    },

    AnimationEffectsAnimateMorph: function (x, y, parent) {
      let json_arr = this.createMergeJsonArr([morph_json]);
      return this.AnimationEffectsDoAnimation("morph", x, y, json_arr, parent);
    },

    AnimationEffectsAnimateMorphBlue: function (x, y, parent) {
      let json_arr = this.createMergeJsonArr([morph_blue_json]);
      return this.AnimationEffectsDoAnimation(
        "morph-blue",
        x,
        y,
        json_arr,
        parent
      );
    },

    AnimationEffectsAnimateFlare: function (x, y, parent) {
      let json_arr = this.createMergeJsonArr([flare_json]);
      return this.AnimationEffectsDoAnimation(
        "flare",
        x,
        y,
        json_arr,
        parent,
        true
      );
    },
    AnimationEffectsAnimateSmoke: function (x, y, preload, parent) {
      let json_arr = this.createMergeJsonArr([
        smoke_json0,
        smoke_json1,
        smoke_json2,
        smoke_json3,
        smoke_json4,
        smoke_json5,
        smoke_json6,
        smoke_json7,
        smoke_json8,
        smoke_json9,
        smoke_json10,
        smoke_json11,
      ]);
      this.AnimationEffectsDoAnimation(
        "smoke",
        x,
        y,
        json_arr,
        parent,
        false,
        true
      );
      this.AnimationEffectsDoAnimation(
        "smoke",
        x,
        y,
        json_arr,
        parent,
        false,
        false
      );
    },

    AnimationEffectsSunray: function (targetElement) {
      let html =
        '    <div class="animation-sun">\n' +
        '      <div class="ray-box">\n' +
        '        <div class="ray ray1"></div>\n' +
        '        <div class="ray ray2"></div>\n' +
        '        <div class="ray ray3"></div>\n' +
        '        <div class="ray ray4"></div>\n' +
        '        <div class="ray ray5"></div>\n' +
        '        <div class="ray ray6"></div>\n' +
        '        <div class="ray ray7"></div>\n' +
        '        <div class="ray ray8"></div>\n' +
        '        <div class="ray ray9"></div>\n' +
        '        <div class="ray ray10"></div>\n' +
        "      </div>\n" +
        "    </div>";

      const animationSun = document.createElement("div");
      animationSun.innerHTML = html;
      animationSun.style.position = "absolute";
      animationSun.style.top = targetElement.clientHeight / 2 + "px";
      animationSun.style.left = targetElement.clientWidth / 2 + "px";

      targetElement.appendChild(animationSun);
      setTimeout(() => {
        targetElement.removeChild(animationSun);
      }, 6000);
    },

    AnimationEffectsAnimateSmoke2: function (x, y, preload, parent) {
      let json_arr = this.createMergeJsonArr([
        smoke2_json0,
        smoke2_json1,
        smoke2_json2,
        smoke2_json3,
        smoke2_json4,
        smoke2_json5,
        smoke2_json6,
      ]);
      this.AnimationEffectsDoAnimation(
        "smoke2",
        x,
        y,
        json_arr,
        parent,
        false,
        true
      );
      this.AnimationEffectsDoAnimation(
        "smoke2",
        x,
        y,
        json_arr,
        parent,
        false,
        false
      );
    },

    AnimationEffectsAnimateSmokeExplode: function (x, y, preload, parent) {
      let json_arr = this.createMergeJsonArr([smoke_explode]);
      return this.AnimationEffectsDoAnimation(
        "smoke-explode",
        x,
        y,
        json_arr,
        parent,
        false,
        preload
      );
    },
    AnimationEffectsConfetti: function (x, y, preload, parent) {
      let json_arr = this.createMergeJsonArr([confetti]);
      return this.AnimationEffectsDoAnimation(
        "confetti",
        x,
        y,
        json_arr,
        parent,
        false,
        preload,
        12
      );
    },
    AnimationEffectsAnimateDust: function (parent) {
      let dust_container = document.createElement("div");
      dust_container.classList.add("dust-container");
      parent.appendChild(dust_container);

      const max_dust = 70;
      for (let i = max_dust; i--; ) {
        let element = document.createElement("div");
        gsap.set(element, {
          css: { className: "+=dust" },
        });
        dust_container.appendChild(element);
        this.doAnimateDust(element, parent);
      }
    },

    doAnimateDust: function (elm, parent) {
      const w = parent.offsetWidth;
      const h = parent.offsetHeight;

      let animation = gsap.to(elm, this.getRandom(1, 4), {
        x: w + 100,
        ease: "none",
        onComplete: function () {
          this.restart();
        },
        delay: -15,
      });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        animation.kill();
      });

      let animation2 = gsap.fromTo(
        elm,
        this.getRandom(1, 8),
        { x: 0, y: this.getRandom(0, h) },
        {
          y: "+=100",
          onComplete: function () {
            this.restart();
          },
          yoyo: true,
          ease: "sine.inOut",
        }
      );
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        animation2.kill();
      });

      let animation3 = gsap.to(elm, this.getRandom(2, 8), {
        onComplete: function () {
          this.restart();
        },
        yoyo: true,
        ease: "sine.inOut",
        delay: -5,
      });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        animation3.kill();
      });
    },

    AnimationEffectsExplosionParticle: function (x, y, parent) {
      let particle_container = document.createElement("div");
      particle_container.classList.add("particle-container");
      parent.appendChild(particle_container);

      const max_particle = 200;
      for (let i = max_particle; i--; ) {
        let element = document.createElement("div");
        gsap.set(element, {
          css: { className: "+=blue-particle" },
        });
        particle_container.appendChild(element);
        this.doExplosionParticle(element, x, y);
      }
    },

    doExplosionParticle: function (elm, x, y) {
      let animation = gsap.fromTo(
        elm,
        this.getRandom(0.5, 2),
        { x: x, y: y },
        {
          x: "-=" + 1000 * this.getRandom(-1, 1),
          y: "-=" + 1000 * this.getRandom(-1, 1),
          // y: "-=" + 1000 * (Math.round(Math.random()) * 2 - 1),
          ease: "none",
          onComplete: function () {
            this.restart();
          },
          delay: -15,
        }
      );
      this.unsubscribeEventsKingWin.push(() => {
        animation.kill();
      });

      let animation2 = gsap.to(elm, this.getRandom(2, 8), {
        onComplete: function () {
          this.restart();
        },
        yoyo: true,
        ease: "sine.inOut",
        delay: -5,
      });
      this.unsubscribeEventsKingWin.push(() => {
        animation2.kill();
      });
    },

    getRandom: function (min, max) {
      return min + Math.random() * (max - min);
    },

    AnimationRewardExplosion: function (x, y, parent, rewardType = "gold") {
      let particle_container = document.createElement("div");
      particle_container.classList.add("reward-explosion-container");
      parent.appendChild(particle_container);

      const max_particle = 50;
      for (let i = max_particle; i--; ) {
        let element = document.createElement("div");
        element.classList.add("reward-particle");
        element.classList.add(rewardType);
        particle_container.appendChild(element);
        this.RewardExplosion(element);
      }
    },

    changeWalletNumber(type, oldVal, newVal, callback) {
      let element;

      switch (type.toLowerCase()) {
        case "gold":
          element = document.getElementsByName("header-item-gold")[0];
          break;
        case "crown":
          element = document.getElementsByName("header-item-crown")[0];
          break;
        case "elixir":
          element = document.getElementsByName("header-item-elixir")[0];
          break;
        default:
          return;
      }

      if (oldVal === newVal) {
        return;
      }

      let y = -5;

      const timeline = gsap.timeline();
      timeline.fromTo(
        element,
        0.3,
        {
          y: y,
        },
        {
          y: 0,
          onStart: () => {
            AnimationNumberIncrease.methods.AnimationNumberIncreaseAnimate(
              element,
              element.textContent,
              newVal,
              0.5,
              "",
              null,
              false,
              callback
            );
          },
        }
      );
    },
  },
};
</script>

<style lang="scss">
gsap-effect {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.glow-light {
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 30px 15px white, 0 0 50px 30px white, 0 0 70px 45px white;
  position: absolute;
}

.dust-container {
  z-index: 1000;
  position: absolute;

  .dust {
    width: 1px;
    height: 1px;
    position: absolute;
    background-color: rgb(136, 136, 136);
    box-shadow: 1px 1px 1px 1px rgba(136, 136, 136, 0.5);
    border-radius: 20px;
    z-index: 2;
  }
}

.particle-container {
  position: absolute;
  z-index: 1;

  .blue-particle {
    width: 2px;
    height: 3px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 1px 1px #fff, 0 0 1px 1px #7dd6d6;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
  }
}

.reward-explosion-container {
  position: absolute;
  z-index: 1;

  .reward-particle {
    position: absolute;
    z-index: 1;

    &.gold {
      background: url(~@/assets/img/slot/coinspin-zoomFX.gif) center/100% 100%
        no-repeat;
      width: 66px;
      height: 48px;
    }
    &.crown {
      background: url(~@/assets/img/slot/crown-zoomFX.gif) center/100% 100%
        no-repeat;
      width: 66px;
      height: 48px;
    }
  }
}

.animation-sun {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: white;
  opacity: 0.9;

  .ray-box {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    -webkit-animation: ray_anim 5s linear;
    animation: ray_anim 5s linear;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    .ray {
      background: -webkit-linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: 80% 80% 0 0;
      position: absolute;
      opacity: 0.1;
      height: 400px;
      width: 10px;
      top: -200px;
      left: -5px;
    }

    .ray1 {
      -webkit-transform: rotate(0deg);
    }
    .ray2 {
      -webkit-transform: rotate(36deg);
    }
    .ray3 {
      -webkit-transform: rotate(72deg);
    }
    .ray4 {
      -webkit-transform: rotate(108deg);
    }
    .ray5 {
      -webkit-transform: rotate(144deg);
    }
    .ray6 {
      -webkit-transform: rotate(180deg);
    }
    .ray7 {
      -webkit-transform: rotate(216deg);
    }
    .ray8 {
      -webkit-transform: rotate(252deg);
    }
    .ray9 {
      -webkit-transform: rotate(288deg);
    }
    .ray10 {
      -webkit-transform: rotate(324deg);
    }
  }
  @-webkit-keyframes ray_anim {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      opacity: 1;
    }
    99% {
      -webkit-transform: rotate(1000deg);
      transform: rotate(1000deg);
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
