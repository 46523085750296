<template>
  <div>
    <div class="modal-pagetitle text-center text-white text-uppercase">
      Impressum
    </div>
    <div class="modal-prompt">
      <div
        id="new-imprint-container"
        class="bg-window overflow-y-scroll px-3 mt-3"
      >
        <ImprintTextDe></ImprintTextDe>
      </div>
      <button
        class="button-empty text-decoration-underline"
        @click="$emit('back')"
      >
        zurück
      </button>
    </div>
  </div>
</template>
<script>
import ImprintTextDe from "@/components/legal/imprint-text_de.vue";
export default {
  name: "ModalToSPageToSText",
  components: { ImprintTextDe },
};
</script>
