const ShortenString = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        shortenString(string, maxNumber = 18) {
          if (string.length > maxNumber) {
            return string.slice(0, maxNumber) + "...";
          } else return string;
        },
      },
    });
  },
};

export default ShortenString;
