<template>
  <div
    v-if="rewardData"
    class="base-reward align-items-center"
    :class="[
      displayInline ? 'd-inline-flex' : 'd-flex flex-column',
      isKingspass ? 'kingspass-reward' : 'normal-reward',
      `base-${rewardType}`,
    ]"
  >
    <div
      class="reward-icon bg-img"
      :class="`${rewardType}-reward`"
      :style="{ 'background-image': 'url(' + assetUrl + ')' }"
    ></div>
    <div class="reward-amount d-inline-flex">
      <div class="amount-number">
        {{ formattedRewardAmount }}
      </div>
      <div v-if="rewardSuffix" class="amount-suffix">
        {{ rewardSuffix }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseReward",
  props: {
    displayInline: {
      type: Boolean,
      default: false,
    },
    shortenGoldAmount: {
      type: Boolean,
      default: false,
    },
    rewardData: {
      type: Object,
      required: true,
    },
    customReward: {
      type: Boolean,
      default: false,
    },
    isKingspass: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rewardType() {
      if (
        !this.customReward &&
        !this.rewardData.asset.payload &&
        this.rewardData.asset.type &&
        this.rewardData.asset.type.endsWith("Card")
      ) {
        return "card";
      }
      let type;
      if (this.customReward) {
        type = this.rewardData.type;
      } else {
        type = this.rewardData.asset.payload.type;
      }

      if (!type) {
        return "gold";
      } else {
        return type;
      }
    },
    rewardSuffix() {
      if (this.rewardType === "gold") {
        return "";
      } else if (this.rewardType === "expbooster") {
        return "m";
      } else {
        return "x";
      }
    },
    formattedRewardAmount() {
      if (this.customReward) {
        return this.rewardData.amount;
      }
      let amount = this.rewardData.amount;
      if (this.rewardType === "gold") {
        if (this.shortenGoldAmount) {
          return this.formatNumber(amount, true);
        } else {
          return this.formatNumber(amount);
        }
      } else if (this.rewardType === "expbooster") {
        return amount / 60;
      } else {
        return amount;
      }
    },
    assetUrl() {
      if (this.customReward) {
        return this.rewardData.src;
      }
      let urlData = this.rewardData.asset.image_urls;
      if (!urlData) {
        return require("@/assets/img/common/coin1.png");
      } else {
        return urlData.image_url;
      }
    },
  },
};
</script>
<style lang="scss">
.base-reward {
  .reward-icon {
    width: 48px;
    height: 48px;
  }
  .reward-amount {
    font-family: kanit-semibold;
    font-size: 20px;
    text-shadow: 3px 3px 0 black, 2px 2px 0 black;
    .amount-number,
    .amount-suffix {
      font-family: inherit;
      text-transform: none;
    }
  }
}
</style>
