import { fetchAllBattles } from "@/API/champions.js";
const getDefaultState = () => {
  return {
    currentBattle: null,
    currentMatchday: null,
    currentOpponentId: null,
    completeBattleAmount: 0,
    userBattles: [],
    newHistoryEntries: [],
    modalLastShown: null,
    pulsatingMenuItem: false,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCurrentBattle(state, payload) {
    state.currentBattle = payload;
  },
  setCurrentMatchday(state, payload) {
    state.currentMatchday = payload;
  },
  setCurrentOpponentId(state, payload) {
    state.currentOpponentId = payload;
  },
  setUserBattles: (state, battles) => (state.userBattles = battles),
  setModalLastShown: (state) => (state.modalLastShown = new Date()),

  clearHistoryEntries: (state) => (state.newHistoryEntries = []),
  setCompleteBattleAmount(state, payload) {
    state.completeBattleAmount = payload;
  },
  addHistoryEntry: (state, id) => state.newHistoryEntries.push(id),
  deactivatePulsatingMenuItem(state) {
    state.pulsatingMenuItem = false;
  },
};

const getters = {
  getCurrentBattle: (state) => state.currentBattle,
  getCurrentMatchday: (state) => state.currentMatchday,
  getCurrentOpponentId: (state) => state.currentOpponentId,
  getCompleteBattleAmount: (state) => state.completeBattleAmount,
  getModalLastShown: (state) => state.modalLastShown,
  getPulsatingMenuItem: (state) => state.pulsatingMenuItem,
};

const actions = {
  handleNewBattleEnd: ({ dispatch, commit }, battle) => {
    dispatch("getUserBattles");
    commit("addHistoryEntry", battle.id);
  },
  getUserBattles({ commit }) {
    return new Promise((resolve, reject) => {
      fetchAllBattles()
        .then((res) => {
          commit("setUserBattles", res.user_battles);

          resolve(res.user_battles);
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  },
  resetChampionsState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
