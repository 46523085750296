const ErrorMessage = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        displayErrorMessage() {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("generic_error_message"),
            alertHeading: this.$t("alerts.title.error"),
            type: "danger",
            variant: "danger",
            dismissMessage: "ok-generic-error",
          });
        },
      },
    });
  },
};
export default ErrorMessage;
