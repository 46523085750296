import store from "@/store/store.js";
export const optionsFunction = () => {
  const options = {
    baseURL: "https://" + process.env.VUE_APP_DEVSERVER,
    headers: {
      Authorization: store.getters["auth/userToken"],
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Version: process.env.VUE_APP_VERSION,
      Platform: "web",
    },
  };
  return options;
};
