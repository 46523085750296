import { optionsFunction } from "./_config";
import axios from "axios";

export const fetchNews = () => axios.get(`/api/news`, optionsFunction());
export const fetchUserNews = () =>
  axios.get(`/api/news/user`, optionsFunction());

export const fetchNewsById = (id) =>
  axios.get(`/api/news/${id}`, optionsFunction());

export const markUserNewsAsRead = (id) =>
  axios.post(`/api/news/user/${id}`, {}, optionsFunction());
export const deleteUserNews = (id) =>
  axios.delete(`/api/news/user/${id}`, optionsFunction());
