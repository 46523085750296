const getDefaultState = () => {
  return {
    currentPlayingSounds: [],
    music: true,
    soundEffect: true,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateMusicPreference(state, boolean) {
    state.music = boolean;
  },
  updateSoundEffectPreference(state, boolean) {
    state.soundEffect = boolean;
  },
  setCurrentPlayingSounds(state, payload) {
    state.currentPlayingSounds[payload.src] = payload.sound;
  },
  removeCurrentPlayingSounds(state, payload) {
    delete state.currentPlayingSounds[payload];
  },
  resetCurrentPlayingSounds(state) {
    state.currentPlayingSounds = [];
  },
};

const getters = {
  getMusicPreference: (state) => state.music,
  getSoundEffectPreference: (state) => state.soundEffect,
  getCurrentPlayingSounds: (state) => state.currentPlayingSounds,
};
const actions = {
  resetShopState({ commit }) {
    commit("resetState");
  },
  updateMusic({ commit }, boolean) {
    commit("updateMusicPreference", boolean);
  },
  updateSoundEffect({ commit }, boolean) {
    commit("updateSoundEffectPreference", boolean);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
