<script>
export default {
  name: "AnimationBaseFunctions",
  computed: {
    queue: function () {
      return this.$store.getters["popups/getAnimationQueue"];
    },
    queueAnimationIsRunning: function () {
      return this.$store.getters["popups/getQueueAnimationIsRunning"];
    },
    pageIsLoading: function () {
      return this.$store.getters.getIsPageLoading;
    },
    unsubArr() {
      return this.$store.getters["animations/getUnsubscribeEvents"];
    },
  },
  methods: {
    //combine sprite sheet json files to one file
    createMergeJsonArr: function (json_array) {
      let result = [];
      let counter = 0;
      for (let i = 0; i < json_array.length; i++) {
        for (let j = 0; j < json_array[i].frames.length; j++) {
          result[counter] = {};
          result[counter].image = json_array[i].meta.image;
          result[counter].frame = json_array[i].frames[j].frame;
          counter++;
        }
      }

      return result;
    },

    //create dark background
    createBackgroundElement: function () {
      let background_darken = document.createElement("div");
      background_darken.classList.add("background-darken");
      document.body.appendChild(background_darken);
      return background_darken;
    },

    //remove dark background
    removeBackgroundElement: function () {
      const background_dark =
        document.body.getElementsByClassName("background-darken")[0];
      if (background_dark) {
        document.body.removeChild(background_dark);
      }
    },

    //build and return animation container. required for all big animations
    createAnimationContainer: function () {
      let animationContainer = document.createElement("div");
      animationContainer.classList.add("animation-container");
      animationContainer.classList.add("animation-mode-unlock");
      document.body.parentNode.insertBefore(
        animationContainer,
        document.body.nextSibling
      );
      return animationContainer;
    },

    // if returns false, animation must not start
    runningAllowed: function () {
      return !document.hidden;
    },

    //remove animation containers and their content
    removeElements: function (nameContainer = "animation-container") {
      let element = document.getElementsByClassName(nameContainer),
        index;
      for (index = element.length - 1; index >= 0; index--) {
        element[index].parentNode.removeChild(element[index]);
      }
    },

    //full skip animation
    skipAnimationFull: function (isInPipeline = true) {
      this.removeElements();
      this.removeBackgroundElement();

      this.stopAllSoundsExceptBackground();
      this.removeQueueFull();

      if (isInPipeline) {
        this.$store.commit("popups/setNextPipelineQueueStep");
      }
    },

    //skip one step
    skipCurrentAnimation: function (timeline, index) {
      //do not skip immediately
      if (timeline._time < 0.7) {
        return false;
      }
      timeline.seek("end" + index, true);
      this.stopAllSoundsExceptBackground();
      return true;
    },

    //add animation to queue
    addToQueue: function (timeline, name) {
      let payload = {};
      payload.name = name;
      payload.timeline = timeline;
      this.$store.commit("popups/setAnimationQueue", payload);
    },

    //remove animation from queue
    removeFromQueue: function (name) {
      let arr = this.queue;
      delete arr[name];
      this.$store.commit("popups/updateAnimationQueues", arr);
      this.$store.commit("popups/setQueueAnimationIsRunning", false);
    },

    //remove all animations from queue
    removeQueueFull: function () {
      let arr = [];
      this.$store.commit("popups/updateAnimationQueues", arr);
      this.$store.commit("popups/setQueueAnimationIsRunning", false);
    },

    killCommonAnimations() {
      if (!this.unsubArr.length) {
        return;
      }

      for (let i = 0; i < this.unsubArr.length; i++) {
        this.unsubArr[i]();
      }
      this.$store.commit("animations/resetUnsubscribeEvents");
    },

    //play next animation from queue
    playNext: function () {
      if (this.queueAnimationIsRunning) {
        return;
      }

      let arr = this.queue;
      let index = Object.keys(arr)[0];
      if (index) {
        this.$store.commit("popups/setQueueAnimationIsRunning", true);
        arr[index].play();
      } else {
        this.killCommonAnimations();
      }
    },

    //calculate position of animation
    calcGridPosition: function (reward_count, index, element) {
      let container = document.getElementsByClassName("animation-container")[0];

      let x = container.offsetWidth / 2;
      let y = container.offsetHeight / 2;
      let scale_val = 1;
      if (reward_count === 2) {
        if (index === 0) {
          x = container.offsetWidth / 4;
        }
        if (index === 1) {
          x = (container.offsetWidth / 4) * 3;
        }
      }
      if (reward_count === 3 || reward_count === 4) {
        scale_val = 0.8;
        if (index === 0) {
          x = container.offsetWidth / 4;
          y = container.offsetHeight / 2 - element.offsetHeight / 2;
        }
        if (index === 1) {
          x = container.offsetWidth / 4;
          y = container.offsetHeight / 2 + element.offsetHeight / 3;
        }
        if (index === 2) {
          x = (container.offsetWidth / 4) * 3;
          y = container.offsetHeight / 2 - element.offsetHeight / 2;
        }
        if (index === 3) {
          x = (container.offsetWidth / 4) * 3;
          y = container.offsetHeight / 2 + element.offsetHeight / 3;
        }
      }

      return { x: x, y: y, scale_val: scale_val };
    },
  },
};
</script>

<style lang="scss"></style>
