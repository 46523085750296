import { render, staticRenderFns } from "./seo-row.vue?vue&type=template&id=abd522e8"
import script from "./seo-row.vue?vue&type=script&lang=js"
export * from "./seo-row.vue?vue&type=script&lang=js"
import style0 from "./seo-row.vue?vue&type=style&index=0&id=abd522e8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports