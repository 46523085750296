<template>
  <div>
    <modal-welcome />
  </div>
</template>

<script>
import ModalWelcome from "@/components/lobby/modal-welcome";
export default {
  name: "PipeWelcome",
  components: { ModalWelcome },

  data() {
    return {};
  },

  computed: {},
  watch: {},
  mounted() {
    console.log("--------- Pipeline Step Welcome ----------");
    if (!this.$store.getters["user/isFreshUser"]) {
      this.$store.commit("popups/setNextPipelineQueueStep");
      return;
    }
    if (!this.$store.getters["user/showWelcomePopup"]) {
      this.$store.commit("popups/setNextPipelineQueueStep");
      return;
    }

    this.$bvModal.show("modal-welcome");
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
