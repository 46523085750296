<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-summer-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div id="modal-summer-bg" class="dead-center w-100 h-100 bg-img"></div>
      <div class="headline"></div>
      <div class="text1">
        {{ $t("shop.offers.summer.text1") }}
      </div>

      <div class="text2">
        {{ $t("shop.offers.summer.text2") }}
      </div>

      <div
        v-if="rewards[0]"
        class="offer-reward-container position-absolute container"
      >
        <div class="h-100">
          <div
            class="w-100 d-flex flex-column align-items-center p-0"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div class="three-coins bg-img"></div>
            <div class="amount-gold yellow-text text-center w-100">
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </div>

          <div v-if="rewards[0].card > 0" class="package-bonus-icons">
            <div class="yellow-text bold">
              {{ formatNumber(rewards[0].card) }} x
            </div>

            <div class="icon h-100">
              <img
                draggable="false"
                src="@/assets/img/common/cardstack-3.png"
                class="h-100"
              />
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0" class="package-bonus-icons">
            <div class="yellow-text bold">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>

            <img
              draggable="false"
              src="@/assets/img/nav-menu/nav-icon-loyalty.png"
              class="icon h-100"
            />
          </div>
          <div v-if="rewards[0].crown > 0" class="package-bonus-icons p-0">
            <span class="yellow-text bold">
              {{ formatNumber(rewards[0].crown) }} x
            </span>

            <img
              draggable="false"
              src="@/assets/img/common/crown6.png"
              class="icon h-100"
            />
          </div>
        </div>
      </div>

      <div
        class="submit-button position-absolute bg-img px-3"
        @click="buyItem(0, 'Offer', 'Event', closeThisModal)"
      >
        <div
          class="d-inline-flex align-items-center justify-content-around w-100 position-relative"
        >
          {{ $t("treasury.key.buy_button") }} {{ formatPrice(price[0]) }} €

          <div v-if="oldPrices && oldPrices[0]" class="offer-old-price right">
            {{ formatPrice(oldPrices[0]) }} €
            <div class="offer-red-line to-center-abs"></div>
          </div>
          <div
            v-if="moreContent && moreContent[0]"
            class="big-badge-more-percent smaller"
          >
            <span class="big">{{ moreContent[0] }}</span>
            {{ $t("shop.offers.more_contents2") }}
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";

export default {
  name: "ModalOfferSummer1",
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-summer-1");
  },

  methods: {
    closeThisModal() {
      this.$bvModal.hide("modal-offer-summer-1");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1195px;
$modal-height: 700px;

#modal-offer-summer-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-color: transparent;
  }

  .close-button {
    top: 0px;
    right: 0px;
    width: 168px;
    height: 168px;
    background-image: url(~@/assets/img/shop/offers/btn-close-default.png);
  }
}
</style>

<style scoped lang="scss">
#modal-offer-summer-1 {
  #modal-summer-bg {
    background-image: url(~@/assets/img/shop/offers/summermegadeal-overlay-main.png);
  }

  .headline {
    width: 660px;
    height: 381px;
    position: absolute;
    top: 0px;
    left: -20px;
    background-image: url(~@/assets/img/shop/offers/smd-head-en.png);
    z-index: 10;
  }

  .text1 {
    font-family: Ubuntu-Bold;
    font-size: 32px;
    color: white;
    text-shadow: 0 0 14px rgba(0, 0, 0, 0.5), 0 0 14px rgba(0, 0, 0, 0.5),
      0 0 8px rgba(0, 0, 0, 0.5), 0 0 8px rgba(0, 0, 0, 0.5),
      0 0 4px rgba(0, 0, 0, 0.5), 0 0 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    position: absolute;
    top: 350px;
    left: 70px;
    width: 500px;
    z-index: 10;
    line-height: 1.2;
  }

  .text2 {
    font-family: Ubuntu-Bold;
    font-size: 24px;
    color: white;
    text-shadow: 0 0 14px rgba(0, 0, 0, 0.5), 0 0 14px rgba(0, 0, 0, 0.5),
      0 0 8px rgba(0, 0, 0, 0.5), 0 0 8px rgba(0, 0, 0, 0.5),
      0 0 4px rgba(0, 0, 0, 0.5), 0 0 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    position: absolute;
    top: 440px;
    left: 70px;
    width: 500px;
    z-index: 10;
    line-height: 1.2;
  }

  .offer-reward-container {
    top: 150px;
    right: 200px;
    height: 257px;
    line-height: 1;
    width: 314px;
  }

  .three-coins {
    background-image: url(~@/assets/img/common/icn-reward-coins.png);
    width: 185px;
    height: 116px;
  }
  .amount-gold {
    font-size: 56px;
    font-family: Ubuntu-Bold;
    position: relative;
    display: inline-flex;
    text-align: center;
    padding-right: 70px;
  }

  .package-bonus-icons {
    display: inline-flex;
    align-items: center;
    height: 56px;
    font-size: 16px;
    .yellow-text {
      font-size: 32px;
    }
  }

  .submit-button {
    width: 425px;
    height: 90px;
    top: 435px;
    right: 145px;
    bottom: 0;
    font-size: 26px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    line-height: 1.2;
    text-shadow: 0 0 8px #630030, 0 0 8px #630030;
    background-image: url(~@/assets/img/shop/offers/smd-button-default.png);
    padding-top: 22px;

    &:hover {
      background-image: url(~@/assets/img/shop/offers/smd-button-hover.png);
    }

    .offer-old-price {
      top: -48px;
      right: -65px;
    }

    .big-badge-more-percent {
      top: -340px;
      right: -60px;
    }
  }
}
</style>
