<template>
  <b-modal
    id="modal-welcome"
    :hide-footer="true"
    modal-class="custom-modal2"
    @shown="onOpen"
    @hidden="onClose"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default" slot-scope="{ close }">
      <div class="headline to-center-abs"></div>

      <div class="main-text text1">
        <div class="text">
          {{ $t("welcome_popup.text1") }}
        </div>
        <div class="text-icon icon-gold"></div>
      </div>

      <div class="main-text text2">
        <div class="text-icon icon-crown"></div>
        <div class="text">
          {{ $t("welcome_popup.text2") }}
        </div>
      </div>

      <div class="main-text text3">
        <div class="text">
          {{ $t("welcome_popup.text3") }}
        </div>
        <div class="text-icon icon-potion"></div>
      </div>

      <div class="main-text text4">
        <div class="text-icon icon-card"></div>
        <div class="text">
          {{ $t("welcome_popup.text4") }}
        </div>
      </div>

      <div class="submit-button to-center-abs clickable" @click="close">
        {{ $t("welcome_popup.button") }}
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ModalWelcome",
  data() {
    return {};
  },

  mounted() {},

  methods: {
    onOpen() {
      this.firebaseClickItemTreasury("welcome_popup_shown");
    },
    onClose() {
      this.playBackgroundMusic();
      this.$store.dispatch("user/increaseWelcomeCounter");
      this.$store.commit("popups/setNextPipelineQueueStep");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1280px;
$modal-height: 750px;

#modal-welcome {
  .modal-dialog {
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;

    @media (max-height: 700px) {
      transform: scale(0.8);
      top: -80px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: url(~@/assets/img/welcome/kleinekrone-modal-generic-welcome-copy.png)
      no-repeat;
    background-size: 100% 100%;
  }

  .close-button {
    position: absolute;
    right: 9px;
    top: 23px;
    width: 168px;
    height: 168px;
    max-width: none;
    background-image: url(~@/assets/img/welcome/btn-close-default.png);
  }
}
</style>

<style lang="scss" scoped>
#modal-welcome {
  .headline {
    width: 586px;
    height: 263px;
    background: url(~@/assets/img/welcome/logo-de-copy.png) center/100%
      no-repeat;
    top: 0px;
  }

  .main-text {
    position: absolute;
    text-align: left;
    height: 48px;

    &.text1 {
      top: 268px;
      left: 370px;
      width: 412px;
    }

    &.text2 {
      top: 355px;
      left: 255px;
      width: 674px;
    }

    &.text3 {
      top: 442px;
      left: 210px;
      width: 476px;
    }

    &.text4 {
      top: 529px;
      left: 130px;
      width: 626px;
    }

    .text {
      font-family: Ubuntu-Bold;
      font-size: 21px;
      line-height: 24px;
      color: white;
      text-transform: uppercase;
      text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
        0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
    }

    .text-icon {
      width: 112px;
      height: 112px;
      position: absolute;

      &.icon-gold {
        background: url(~@/assets/img/welcome/coin.png) no-repeat;
        top: -31px;
        right: -110px;
      }
      &.icon-crown {
        background: url(~@/assets/img/welcome/crown.png) no-repeat;
        top: -35px;
        left: -111px;
      }
      &.icon-potion {
        background: url(~@/assets/img/welcome/elixier.png) no-repeat;
        top: -37px;
        right: -100px;
      }
      &.icon-card {
        background: url(~@/assets/img/welcome/cards.png) no-repeat;
        top: -35px;
        left: -110px;
      }
    }
  }

  .submit-button {
    top: 620px;
    background: url(~@/assets/img/welcome/btn-bg.png) no-repeat;
    width: 330px;
    height: 74px;
    color: white;
    font-size: 31px;
    text-transform: uppercase;
    padding-top: 10px;
  }
}
</style>
