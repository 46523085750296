const getDefaultState = () => {
  return {
    treasuryUpdateData: null,
    pulseMenuIcon: false,
  };
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setTreasuryUpdateData(state, payload) {
    state.treasuryUpdateData = payload;
  },
  setPulseMenuIcon(state, payload) {
    state.pulseMenuIcon = payload;
  },
};

const getters = {
  getTreasuryUpdateData: (state) => state.treasuryUpdateData,
  getPulseMenuIcon: (state) => state.pulseMenuIcon,
};
const actions = {
  resetTreasuryState({ commit }) {
    commit("resetState");
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
